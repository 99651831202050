export interface IBusinessDetails {
  id?: string;
  name?: string | null;
  abnBusinessName?: string | null;
  siteName?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressLines3?: string | null;
  businessLogo?: Uint8Array | null;
}

export const defaultValue: Readonly<IBusinessDetails> = {};

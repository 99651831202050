import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  isNumber,
  translate,
  ValidatedField,
  ValidatedForm,
} from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
  converTimeToInt,
} from 'app/shared/util/date-utils';

import { RateType } from 'app/shared/model/enumerations/rate-type.model';
import { WeekDay } from 'app/shared/model/enumerations/week-day.model';
import { IRateSchedule } from 'app/shared/model/rate-schedule.model';
import { IRate } from 'app/shared/model/rate.model';
import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
  getEntities,
} from '../../entities/rate-schedule/rate-schedule.reducer';
import { createEntity as createRateCardEntity } from '../../entities/rate-card/rate-card.reducer';
import { createEntity as createRateEntity } from '../../entities/rate/rate.reducer';
import { toast } from 'react-toastify';
import './rate.css';

export default function RateScheduleCreate() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const rateScheduleEntity = useAppSelector(state => state.rateSchedule.entity);

  const [localRateCard, setLocalRateCard] = useState(
    isNew
      ? {
          entryFrom: 0,
          entryTo: 0,
          exitFrom: 0,
          exitTo: 0,
          dayFrom: 'SUNDAY',
          dayTo: 'SUNDAY',
          rates: [
            {
              minimumDuration: 0,
              maximumDuration: 0,
              rate: 0,
            },
          ],
        }
      : rateScheduleEntity.rateCards || {}
  );

  const rateScheduleList = useAppSelector(state => state.rateSchedule.entities);
  const [rates, setRates] = useState(localRateCard.rates);
  const [name, setName] = useState(rateScheduleEntity.name);
  const [rateType, setRateType] = useState(rateScheduleEntity.rateType);
  const [startInstant, setStartInstant] = useState(
    rateScheduleEntity.startInstant
  );
  const [endInstant, setEndInstant] = useState(rateScheduleEntity.endInstant);

  const loading = useAppSelector(state => state.rateSchedule.loading);
  const updating = useAppSelector(state => state.rateSchedule.updating);
  const updateSuccess = useAppSelector(
    state => state.rateSchedule.updateSuccess
  );
  const rateTypeValues = Object.keys(RateType);
  const weekDayValues = Object.keys(WeekDay);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `startInstant,asc`,
      })
    );
  };

  const handleClose = () => {
    navigate('/pages/ratesSchedules');
  };

  useEffect(() => {
    getAllEntities();
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const defaultValues = () => {
    if (isNew) {
      return {
        name: name,
        rateType: rateType,
        locationId: '420ce734-4892-46e1-97d6-46246bbb30eb',
        startInstant: startInstant,
        endInstant: endInstant,
        created: displayDefaultDateTime(),
        rateCards: [
          {
            ...localRateCard,
            rates: rates,
          },
        ],
      };
    } else {
      return {
        ...rateScheduleEntity,
      };
    }
  };
  const getRateCardData = values => ({
    entryFrom: values.entryFrom,
    entryTo: values.entryTo,
    exitFrom: values.exitFrom,
    exitTo: values.exitTo,
    dayFrom: values.dayFrom,
    dayTo: values.dayTo,
    rates: values.rates || [],
  });

  const processEntities = async (values: any) => {
    try {
      const updatedValues1 = {
        ...values,
        rateCards: [
          {
            ...values.rateCards[0],
            rates: values.rateCards[0].rates,
            rateSchedule: null,
          },
        ],
      };
      const rateSchedulePromise = await dispatch(createEntity(updatedValues1));
    } catch (error) {
      console.error(error);
    }
  };

  function doRangesOverlap(
    range1Start: Date,
    range1End: Date,
    range2Start: Date,
    range2End: Date
  ) {
    return range1Start <= range2End && range2Start <= range1End;
  }

  const saveEntity = values => {
    values.startInstant = convertDateTimeToServer(values.startInstant);
    values.endInstant = convertDateTimeToServer(values.endInstant);

    if (values.endInstant <= values.startInstant) {
      toast.error('Expiry must be greater than start date');
      return;
    }

    // const overlaps = rateScheduleList.some(schedule => {
    //     const existingScheduleStart = convertDateTimeToServer(schedule.startInstant);
    //     const existingScheduleEnd = convertDateTimeToServer(schedule.endInstant);

    //     return doRangesOverlap(
    //         values.startInstant, values.endInstant,
    //         existingScheduleStart, existingScheduleEnd
    //     );
    // });

    // if (overlaps) {
    //     toast.error('Overlapping Dates')
    //     return;
    // } else {

    values.created = convertDateTimeToServer(new Date().getTime());
    values.locationId = '123';
    values.rateCards = [getRateCardData(values.rateCards[0])];
    values.rateCards[0].entryFrom = converTimeToInt(
      values.rateCards[0].entryFrom
    );
    values.rateCards[0].entryTo = converTimeToInt(values.rateCards[0].entryTo);
    values.rateCards[0].exitFrom = converTimeToInt(
      values.rateCards[0].exitFrom
    );
    values.rateCards[0].exitTo = converTimeToInt(values.rateCards[0].exitTo);

    const entity = {
      ...rateScheduleEntity,
      ...values,
    };

    if (isNew) {
      processEntities(values);
    } else {
      dispatch(updateEntity(entity));
    }

    // }
  };

  const handleRateChange = (index: number, field: string, value: string) => {
    const updatedRates = [...rates];
    updatedRates[index][field] = value;

    setRates(updatedRates);
  };

  const handleRateCardChange = (field: string, value: string) => {
    const updatedRateCard = { ...localRateCard };
    updatedRateCard[field] = value;
    setLocalRateCard(updatedRateCard);
  };

  const ratesRows = () => {
    return rates.map((rate, index) => {
      const isLastRate = index === rates.length - 1;

      return (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '20px',
            alignItems: 'center',
            justifyItems: 'center',
          }}
        >
          <ValidatedField
            label="Minimum Duration"
            id={`rateCards[0].rates[${index}].minimumDuration`}
            name={`rateCards[0].rates[${index}].minimumDuration`}
            data-cy="minimumDuration"
            type="number"
            min="0"
            defaultValue={
              rates && rates.length > 0 ? rates[index]?.minimumDuration : 0
            }
            onChange={e =>
              handleRateChange(index, 'minimumDuration', e.target.value)
            }
            validate={{
              required: {
                value: true,
                message: translate('entity.validation.required'),
              },
              validate: v =>
                isNumber(v) || translate('entity.validation.number'),
            }}
          />
          <ValidatedField
            label="Maximum Duration"
            id={`rateCards[0].rates[${index}].maximumDuration`}
            name={`rateCards[0].rates[${index}].maximumDuration`}
            data-cy="maximumDuration"
            type="number"
            min="0"
            onChange={e =>
              handleRateChange(index, 'maximumDuration', e.target.value)
            }
            defaultValue={
              rates && rates.length > 0 ? rates[index]?.maximumDuration : 0
            }
            validate={{
              required: {
                value: true,
                message: translate('entity.validation.required'),
              },
              validate: v =>
                isNumber(v) || translate('entity.validation.number'),
            }}
          />
          <ValidatedField
            label="Rate"
            id={`rateCards[0].rates[${index}].rate`}
            name={`rateCards[0].rates[${index}].rate`}
            data-cy="rate"
            type="number"
            min="0"
            defaultValue={rates && rates.length > 0 ? rates[index]?.rate : 0}
            onChange={e => handleRateChange(index, 'rate', e.target.value)}
            validate={{
              required: {
                value: true,
                message: translate('entity.validation.required'),
              },
              validate: v =>
                isNumber(v) || translate('entity.validation.number'),
            }}
          />
          <div style={{ display: 'flex', gap: '10px' }}>
            {isLastRate && index !== 0 ? (
              <>
                <Button
                  onClick={() => addRate()}
                  replace
                  color="info"
                  style={{ height: '100%', marginTop: '15px' }}
                >
                  <FontAwesomeIcon icon="add" />
                </Button>
                <Button
                  onClick={() => removeRate(index)}
                  replace
                  color="danger"
                  style={{ height: '100%', marginTop: '15px' }}
                >
                  <FontAwesomeIcon icon="minus" />
                </Button>
              </>
            ) : (
              <>
                {isLastRate && (
                  <Button
                    onClick={() => addRate()}
                    replace
                    color="info"
                    style={{ height: '100%', marginTop: '15px' }}
                  >
                    <FontAwesomeIcon icon="add" />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      );
    });
  };
  const addRate = () => {
    const minduration = rates[rates.length-1].maximumDuration ? Number(rates[rates.length-1].maximumDuration) +1  : 0;
    setRates(prevRates => [...prevRates, {minimumDuration:minduration}]);
  };

  const removeRate = index => {
    setRates(prevRates => prevRates.filter((_, i) => i !== index));
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="ticketlessLiteApp.rateSchedule.home.createOrEditLabel"
            data-cy="RateScheduleCreateUpdateHeading"
          >
            Create A Rate Schedule
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              <ValidatedField
                label="Rate Schedule Name"
                id="rate-schedule-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate('entity.validation.required'),
                  },
                }}
              />
              <ValidatedField
                label="Rate Type"
                id="rate-schedule-rateType"
                name="rateType"
                data-cy="rateType"
                type="select"
              >
                {rateTypeValues.map(rateType => (
                  <option value={rateType} key={rateType}>
                    {rateType}
                  </option>
                ))}
              </ValidatedField>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: '10px',
                }}
              >
                <ValidatedField
                  label="Entry From"
                  id="rateCards[0].entryFrom"
                  name="rateCards[0].entryFrom"
                  data-cy="rateCards[0].entryFrom"
                  type="time"
                  onChange={e =>
                    handleRateCardChange('entryFrom', e.target.value)
                  }
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                    validate: v =>
                      isNumber(v) || translate('entity.validation.number'),
                  }}
                />
                <ValidatedField
                  label="Entry To"
                  id="rate-card-entryTo"
                  name="rateCards[0].entryTo"
                  data-cy="entryTo"
                  type="time"
                  onChange={e =>
                    handleRateCardChange('entryTo', e.target.value)
                  }
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                    validate: v =>
                      isNumber(v) || translate('entity.validation.number'),
                  }}
                />
                <ValidatedField
                  label="Exit From"
                  id="rateCards[0].exitFrom"
                  name="rateCards[0].exitFrom"
                  data-cy="rateCards[0].exitFrom"
                  type="time"
                  onChange={e =>
                    handleRateCardChange('exitFrom', e.target.value)
                  }
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                    validate: v =>
                      isNumber(v) || translate('entity.validation.number'),
                  }}
                />
                <ValidatedField
                  label="Exit To"
                  id="rate-card-exitTo"
                  name="rateCards[0].exitTo"
                  data-cy="exitTo"
                  type="time"
                  onChange={e => handleRateCardChange('exitTo', e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                    validate: v =>
                      isNumber(v) || translate('entity.validation.number'),
                  }}
                />

                <ValidatedField
                  label="Day From"
                  id="rate-card-dayFrom"
                  name="rateCards[0].dayFrom"
                  data-cy="rateCards[0].dayFrom"
                  type="select"
                  onChange={e =>
                    handleRateCardChange('dayFrom', e.target.value)
                  }
                >
                  {weekDayValues.map(weekDay => (
                    <option value={weekDay} key={weekDay}>
                      {weekDay}
                    </option>
                  ))}
                </ValidatedField>
                <ValidatedField
                  label="Day To"
                  id="rate-card-dayTo"
                  name="rateCards[0].dayTo"
                  data-cy="rateCards[0].dayTo"
                  type="select"
                  onChange={e => handleRateCardChange('dayTo', e.target.value)}
                >
                  {weekDayValues.map(weekDay => (
                    <option value={weekDay} key={weekDay}>
                      {weekDay}
                    </option>
                  ))}
                </ValidatedField>

                <ValidatedField
                  label="Valid From"
                  id="rate-schedule-startInstant"
                  name="startInstant"
                  data-cy="startInstant"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
                  onChange={e => setStartInstant(e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                  }}
                />
                <ValidatedField
                  label="Valid To"
                  id="rate-schedule-endInstant"
                  name="endInstant"
                  data-cy="endInstant"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
                  onChange={e => setEndInstant(e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                  }}
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                <h4>Rates</h4>
                {ratesRows()}
              </div>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/pages/ratesSchedules"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
}

import React, { ReactNode } from 'react';

interface ButtonsContainerProps {
  children: ReactNode;
}

export default function ButtonsContainer({ children }: ButtonsContainerProps) {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        height: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
}

import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RateSchedule from './rate-schedule';
import RateScheduleDetail from './rate-schedule-detail';
import RateScheduleUpdate from './rate-schedule-update';
import RateScheduleDeleteDialog from './rate-schedule-delete-dialog';

const RateScheduleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RateSchedule />} />
    <Route path="new" element={<RateScheduleUpdate />} />
    <Route path=":id">
      <Route index element={<RateScheduleDetail />} />
      <Route path="edit" element={<RateScheduleUpdate />} />
      <Route path="delete" element={<RateScheduleDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RateScheduleRoutes;

import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const GateControlModel = ({ show, onClose, onSubmit, data }) => {
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');

  const handleReasonFieldChange = event => {
    const newValue = event.target.value;
    setReason(newValue);
    setError('');
  };

  const handleSubmit = () => {
    if (!reason) {
      setError('Reason is required');
      return;
    }
    

    onSubmit({ ...data, reason: reason });
    setReason('');
    onClose();
  };

  const handleClose = () => {
    setReason('');
    onClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <Form.Group controlId="formFeeChanged">
          <Form.Label>Reason</Form.Label>
          <Form.Control
            type="text"
            value={reason}
            onChange={handleReasonFieldChange}
            isInvalid={!!error}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GateControlModel;

import { SidebarItemsType, SidebarMenuType } from '../../types/sidebar';

import { AUTHORITIES } from 'app/config/constants';
import AccountsPage from 'app/modules/customer-account/customer-account';
import Home from 'app/modules/home/home';
import Parking from 'app/modules/parking/parking';
import { TrafficAnalysis } from 'app/modules/traffic-analysis';
import Transactions from 'app/modules/transactions/transactions';
import {
  BarChart,
  Clock,
  CornerUpRight,
  DollarSign,
  FileText,
  Gift,
  Info,
  Layout,
  PieChart,
  Users,
  Watch,
  X,
} from 'react-feather';

import BuisnessDetailsPage from 'app/modules/business-details/business-details';
import Financials from 'app/modules/financials';
import RateSchedules from 'app/modules/rate-schedules/rate-schedules';
import ValidationsPage from 'app/modules/validation/validation';
import OccupancyReport from 'app/modules/occupancy-report/occupancy-report';
import StayLengthPage from 'app/modules/stay-length/stay-length';
import Exceptions from 'app/modules/exceptions/exceptions';

const allPagesSection = [
  {
    href: '',
    icon: Layout,
    title: 'Dashboard',
    Component: Home,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.DASHBOARD],
  },
  {
    href: '/pages/accounts',
    icon: Users,
    title: 'Accounts',
    Component: AccountsPage,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.ACCOUNT],
  },
  {
    href: '/pages/validation',
    icon: Gift,
    title: 'Validation',
    Component: ValidationsPage,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.VALIDATION],
  },
  {
    href: '/pages/financials',
    icon: DollarSign,
    title: 'Financials',
    Component: Financials,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.FINANCIALS],
  },
  {
    href: '/pages/trafficAnalysis',
    icon: PieChart,
    title: 'Traffic Analysis',
    Component: TrafficAnalysis,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.TRAFFIC],
  },
  {
    href: '/pages/transactions',
    icon: FileText,
    title: 'Payment History',
    Component: Transactions,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.PAYMENT],
  },
  {
    href: '/pages/exceptions',
    icon: X,
    title: 'Exceptions',
    Component: Exceptions,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.EXCEPTIONS],
  },
  {
    href: '/pages/parking',
    icon: CornerUpRight,
    title: 'Parking Events',
    Component: Parking,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.PARKING],
  },
  {
    href: '/pages/ratesSchedules',
    icon: Clock,
    title: 'Rate Schedules',
    Component: RateSchedules,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.RATES],
  },
  {
    href: '/pages/occupancyReport',
    icon: BarChart,
    title: 'Occupancy Report',
    Component: OccupancyReport,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.OCCUPANCY_REPORT],
  },
  {
    href: '/pages/stayLength',
    icon: Watch,
    title: 'Length of Stay',
    Component: StayLengthPage,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.LENGTH_OF_STAY],
  },
  {
    href: '/pages/businessDetails',
    icon: Info,
    title: 'Business Details',
    Component: BuisnessDetailsPage,
    authorities: [AUTHORITIES.ADMIN, AUTHORITIES.BUSINESS_DETAILS],
  },
] as SidebarItemsType[];
const sidebarItemsForAccount = (authorities: string[], config): SidebarMenuType[] => {
  const pagesSection = allPagesSection.filter(item => config.PAGES.includes(item.title));
  const pages = pagesSection.filter(item =>
    item.authorities.some(authority => authorities.includes(authority))
  );
  console.log('pages:', pages);

  return [
    {
      title: 'Pages',
      href: 'pages',
      pages: pages,
    },
  ];
};

export default sidebarItemsForAccount;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LineChart from 'app/components/LineChart';
import ButtonsContainer from 'app/components/buttonsContainer';
import { Calendar } from 'app/components/calendar/calendar';
import TitleContainer from 'app/components/titleContainer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import usePalette from 'app/hooks/usePalette';
import DownloadCSV from 'app/components/DownloadCSV';
import Loader from 'app/components/Loader';
import { ILocation } from 'app/shared/model/location.model';
import { toDateOnly } from 'app/shared/util/date-utils';
import _ from 'lodash';

const generateTimeLabels = () => {
  const labels = [];
  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      labels.push(`${formattedHours}:${formattedMinutes}`);
    }
  }
  return labels;
};

const fillMissingTimeSlots = data => {
  const allTimeSlots = generateTimeLabels();
  const result = {};

  Object.keys(data).forEach(paymentType => {
    const paymentTypeData = data[paymentType];

    let lastKnownValue = { minValue: 0, actualValue: 0, maxValue: 0 }; // Initialize with defaults

    const filledData = allTimeSlots.map(slot => {
      const existingEntry = paymentTypeData.find(entry => entry.time === slot);

      if (existingEntry) {
        lastKnownValue = existingEntry; // Update last known value
        return existingEntry;
      } else {
        // If no entry for the current slot, use the last known value but update the time
        return {
          time: slot,
          minValue: lastKnownValue.minValue,
          actualValue: lastKnownValue.actualValue,
          maxValue: lastKnownValue.maxValue,
        };
      }
    });

    result[paymentType] = filledData;
  });

  return result;
};

const GroupedData = data => {
  const groupedData = data.reduce((acc, item) => {
    const key = `${item.time}-${item.paymentType}`;
    if (!acc[key]) {
      acc[key] = {
        count: 0,
        totalMinValue: 0,
        totalActualValue: 0,
        totalMaxValue: 0,
      };
    }
    acc[key].count += 1;
    acc[key].totalMinValue += item.minValue;
    acc[key].totalActualValue += item.actualValue;
    acc[key].totalMaxValue += item.maxValue;
    return acc;
  }, {});

  const sortedKeys = Object.keys(groupedData).sort((a, b) => {
    const timeA =
      Number(a.split('-')[0].split(':')[0]) * 60 +
      Number(a.split('-')[0].split(':')[1]);
    const timeB =
      Number(b.split('-')[0].split(':')[0]) * 60 +
      Number(b.split('-')[0].split(':')[1]);
    return timeA - timeB;
  });

  // Calculating averages
  const averagedData = sortedKeys.map(key => {
    const [time, paymentType] = key.split('-');
    return {
      time,
      paymentType,
      minValue: Math.round(
        groupedData[key].totalMinValue / groupedData[key].count
      ),
      actualValue: Math.round(
        groupedData[key].totalActualValue / groupedData[key].count
      ),
      maxValue: Math.round(
        groupedData[key].totalMaxValue / groupedData[key].count
      ),
    };
  });
  return averagedData;
};

const OccupancyReport = () => {
  const palette = usePalette();

  const [selectedAccountTypes, setSelectedAccountTypes] = useState(['Casual']);
  const [occupancyReport, setOccupancyReport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLocationsLoading, setIsLocationsLoading] = useState(true);

  const [locations, setLocations] = useState([]);
  const [queryParams, setQueryParams] = useState({
    'date.greaterThanOrEqual': null,
    'date.lessThanOrEqual': null,
    'paymentType.in': ['CARD'],
  });

  const handleAccountType = type => {
    let updatedAccountTypes;

    if (
      selectedAccountTypes.length === 1 &&
      selectedAccountTypes.includes(type)
    ) {
      return;
    }

    if (selectedAccountTypes.includes(type)) {
      updatedAccountTypes = selectedAccountTypes.filter(
        selectedType => selectedType !== type
      );
    } else {
      updatedAccountTypes = [...selectedAccountTypes, type];
    }

    setSelectedAccountTypes(updatedAccountTypes);

    const filterValues = updatedAccountTypes
      .map(selectedType => {
        if (selectedType === 'Casual') return 'CARD';
        if (selectedType === 'Account') return 'QR_ACCOUNT';
        if (selectedType === 'Validation') return 'QR_VALIDATION';
        return null;
      })
      .filter(Boolean);

    setQueryParams(prevParams => ({
      ...prevParams,
      'paymentType.in': filterValues,
    }));
  };

  const handleDateFilter = range => {
    const startDate = toDateOnly(range.startDate);
    const endDate = toDateOnly(range.endDate);

    const queryParameters = {
      'date.greaterThanOrEqual': startDate,
      'date.lessThanOrEqual': endDate,
    };
    setQueryParams(prev => ({
      ...prev,
      ...queryParameters,
    }));
  };

  const accountTypeButton = type => (
    <Button
      key={type}
      variant={
        selectedAccountTypes.includes(type) ? 'primary' : 'outline-primary'
      }
      onClick={() => handleAccountType(type)}
      style={{ cursor: 'pointer' }}
    >
      {type}
    </Button>
  );

  const totalOccupancy = (locations: ILocation[]) => {
    if (locations && locations[0]) return locations[0].totalOccupancy;
    return 0;
  };

  const calculatePercentage = value => {
    return (value / totalOccupancy(locations)) * 100;
  };

  const groupedByPaymentType = occupancyReport.reduce((acc, item) => {
    const { paymentType, time, minValue, actualValue, maxValue } = item;

    if (!acc[paymentType]) {
      acc[paymentType] = [];
    }

    acc[paymentType].push({
      time,
      minValue,
      actualValue,
      maxValue,
    });

    return acc;
  }, {});

  const filledData = fillMissingTimeSlots(groupedByPaymentType);

  function getBorderColor(paymentType) {
    switch (paymentType) {
      case 'CARD':
        return palette.primary;
      case 'QR_ACCOUNT':
        return 'orange';
      default:
        return 'green';
    }
  }

  const occupancyReportLineChartData = {
    labels: generateTimeLabels(),
    datasets: Object.keys(filledData).map(paymentType => {
      return {
        label: paymentType,
        fill: true,
        backgroundColor: 'transparent',
        borderColor: getBorderColor(paymentType),
        data: filledData[paymentType].map(d =>
          calculatePercentage(d.actualValue)
        ),
      };
    }),
  };

  useEffect(() => {
    fetchData();
  }, [queryParams]);

  useEffect(() => {
    setIsLocationsLoading(true);
    axios.get(`api/locations`).then(r => {
      setLocations(r.data);
      setIsLocationsLoading(false);
    }).catch(error => {
      setIsLocationsLoading(false);
      console.error('Error fetching locations data:', error);
    });;
  }, []);

  const fetchData = () => {

    let modifiedQueryParams = { ...queryParams };

    // Check if the date.greaterThanOrEqual is set and modify it
    if (modifiedQueryParams['date.greaterThanOrEqual']) {
      let startDate = new Date(modifiedQueryParams['date.greaterThanOrEqual']);
      startDate.setDate(startDate.getDate() - 1); // Fetch data from one day earlier
      modifiedQueryParams['date.greaterThanOrEqual'] = toDateOnly(startDate);
    }

    
    const requestUrl = `api/occupancy-report`;
    setIsLoading(true);
    axios
      .get(requestUrl, { params: modifiedQueryParams })
      .then(response => {
        const actualStartDate = queryParams['date.greaterThanOrEqual']; // The original start date
        const extraDayDate = toDateOnly(new Date(actualStartDate).setDate(new Date(actualStartDate).getDate() - 1));
        const processedData = appendInitialOccupancy(response.data, actualStartDate, extraDayDate);
    
        setOccupancyReport(GroupedData(processedData));
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  };
  const appendInitialOccupancy = (data, actualStartDate, extraDayDate) => {
    const lastDataPerGroup = getLastDataPerGroup(data, extraDayDate);
  
    // Append last data of extra day at the beginning of actual start date
    _.forEach(lastDataPerGroup, (item: any) => {
        if (item && typeof item === 'object') {
            let newItem = { ...(item as object), date: actualStartDate, time: '00:00' };
            data.unshift(newItem);
        }
    });
  
    // Filter out data of the extra day
    return _.filter(data, item => item.date >= actualStartDate);
  };
  
  const getLastDataPerGroup = (data, extraDayDate) => {
    // Filter out only the extra day's data
    let extraDayData = _.filter(data, item => item.date === extraDayDate);
  
    // Group extra day data by paymentType
    const groupedByPaymentType = _.groupBy(extraDayData, 'paymentType');
    let lastDataPerGroup = {};
  
    _.forEach(groupedByPaymentType, (group, key) => {
      // Find the last entry in each group for the extra day
      lastDataPerGroup[key] = _.maxBy(group, d => d.time);
    });
  
    return lastDataPerGroup;
  };
  
  const handleRefreshClick = () => {
    fetchData();
  };

  const Refresh = () => {
    return (
      <Button
        variant="primary"
        onClick={() => handleRefreshClick()}
        className="me-1 mb-1 btn btn-info"
      >
        <FontAwesomeIcon
          icon="sync"
          style={{ marginRight: '5px', width: '12px', height: '12px' }}
        />
        Refresh
      </Button>
    );
  };

  return (
    <React.Fragment>
      <div>
        <TitleContainer>
          <div>
            <h3>Occupancy Report</h3>
          </div>
          <ButtonsContainer>
            <ButtonGroup className="me-1" style={{ height: '31px' }}>
              {['Casual', 'Account', 'Validation'].map(type =>
                accountTypeButton(type)
              )}
            </ButtonGroup>
            <Refresh />
            <DownloadCSV
              filedata={occupancyReport}
              disabled={selectedAccountTypes.length !== 1}
            />
            <div style={{ marginTop: '0px' }}>
              <Calendar
                applyDateFilter={range => handleDateFilter(range)}
                range={0}
              />
            </div>
          </ButtonsContainer>
        </TitleContainer>
      </div>

      {(isLoading || isLocationsLoading)? (
        <Loader />
      ) : (
        <LineChart data={occupancyReportLineChartData} />
      )}
    </React.Fragment>
  );
};

export default OccupancyReport;

export enum PaymentType {
  CARD = 'CARD',

  QR_VALIDATION = 'QR_VALIDATION',

  QR_ACCOUNT = 'QR_ACCOUNT',

  QR_STG_VALIDATION = 'QR_STG_VALIDATION',

  NA = 'NA',
}

import { useEffect } from 'react';
import useLocalStorage from './useLocalStorage';

function useSettingsState(key: any, initialValue: string) {
  const [value, setValue] = useLocalStorage(key, initialValue);

  useEffect(() => {
    // Set data attribute on body element
    document.body.dataset[key] = value;

    // Replace stylesheet if dark theme gets toggled
    if (key === 'theme') {
      const theme = value === 'dark' ? 'dark' : 'light';

      const stylesheet = document.querySelector('.js-stylesheet');
      if (stylesheet) {
        stylesheet.setAttribute(
          'href',
          `/src/main/webapp/content/assets/scss/${theme}.scss`
        );
      }
    }
  }, [value, key]);

  return [value, setValue];
}

export default useSettingsState;

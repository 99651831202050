import axios from 'axios';
import {
  createAsyncThunk,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
} from 'app/shared/reducers/reducer.utils';
import {
  IParkingSession,
  defaultValue,
} from 'app/shared/model/parking-session.model';

const initialState: EntityState<IParkingSession> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/parking-sessions';

// Actions
// export const fetchOccupancyParkingSessions = createAsyncThunk(
//   'parkingSession/fetch_current_occupancy',
//   async () => {
//     const requestUrl = 'api/parking-sessions-count?cacheBuster=${new Date().getTime()}';
//     return axios.get<number>(requestUrl);
//   }
// );

export const getAll = ({ query, page, size, sort }: IQueryParams) => {
  let queryString = `page=${page}&size=${size}`;

  if (sort) {
    queryString += `&sort=${sort}`;
  }

  if (query) {
    queryString += `&${query}`;
  }

  const requestUrl = `${apiUrl}?${queryString}&cacheBuster=${new Date().getTime()}`;

  return axios.get<IParkingSession[]>(requestUrl);
};


export const getEntities = createAsyncThunk(
  'parkingSession/fetch_entity_list',
  async ({ query, page, size, sort }: IQueryParams) => {
    return getParkingSessions_(query, page, size, sort);
  }
);



export const getEntity = createAsyncThunk(
  'parkingSession/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IParkingSession>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'parkingSession/create_entity',
  async (entity: IParkingSession, thunkAPI) => {
    const result = await axios.post<IParkingSession>(
      apiUrl,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'parkingSession/update_entity',
  async (entity: IParkingSession, thunkAPI) => {
    const result = await axios.put<IParkingSession>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'parkingSession/partial_update_entity',
  async (entity: IParkingSession, thunkAPI) => {
    const result = await axios.patch<IParkingSession>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'parkingSession/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IParkingSession>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ParkingSessionSlice = createEntitySlice({
  name: 'parkingSession',
  initialState,
  extraReducers(builder) {
    builder
      // .addCase(fetchOccupancyParkingSessions.fulfilled, (state, action) => { 
      //   state.loading = false;
      //   state.occupancyCount = action.payload.data;
      // })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = ParkingSessionSlice.actions;

// Reducer
export default ParkingSessionSlice.reducer;
export function getParkingSessions_(query: string, page: number, size: number, sort: string) {
  return getAll({ query, page, size, sort });
}


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=time]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}

input[type=datetime-local]::-webkit-calendar-picker-indicator {
  filter: invert(100%);
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/rate-schedules/rate.css"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;;AACA;EACE,oBAAA;AAEF","sourcesContent":["input[type='time']::-webkit-calendar-picker-indicator {\n  filter: invert(100%);\n}\ninput[type='datetime-local']::-webkit-calendar-picker-indicator {\n  filter: invert(100%);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

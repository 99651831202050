import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './rate-card.reducer';

export const RateCardDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const rateCardEntity = useAppSelector(state => state.rateCard.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="rateCardDetailsHeading">
          <Translate contentKey="ticketlessLiteApp.rateCard.detail.title">
            RateCard
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="ticketlessLiteApp.rateCard.id">
                Id
              </Translate>
            </span>
          </dt>
          <dd>{rateCardEntity.id}</dd>
          <dt>
            <span id="entryFrom">
              <Translate contentKey="ticketlessLiteApp.rateCard.entryFrom">
                Entry From
              </Translate>
            </span>
          </dt>
          <dd>{rateCardEntity.entryFrom}</dd>
          <dt>
            <span id="entryTo">
              <Translate contentKey="ticketlessLiteApp.rateCard.entryTo">
                Entry To
              </Translate>
            </span>
          </dt>
          <dd>{rateCardEntity.entryTo}</dd>
          <dt>
            <span id="dayFrom">
              <Translate contentKey="ticketlessLiteApp.rateCard.dayFrom">
                Day From
              </Translate>
            </span>
          </dt>
          <dd>{rateCardEntity.dayFrom}</dd>
          <dt>
            <span id="dayTo">
              <Translate contentKey="ticketlessLiteApp.rateCard.dayTo">
                Day To
              </Translate>
            </span>
          </dt>
          <dd>{rateCardEntity.dayTo}</dd>
          <dt>
            <Translate contentKey="ticketlessLiteApp.rateCard.rateSchedule">
              Rate Schedule
            </Translate>
          </dt>
          <dd>
            {rateCardEntity.rateSchedule ? rateCardEntity.rateSchedule.id : ''}
          </dd>
        </dl>
        <Button
          tag={Link}
          to="/rate-card"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/rate-card/${rateCardEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RateCardDetail;

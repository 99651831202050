import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import {
  isNumber,
  Translate,
  translate,
  ValidatedField,
  ValidatedForm,
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IRateSchedule } from 'app/shared/model/rate-schedule.model';
import { RateType } from 'app/shared/model/enumerations/rate-type.model';
import {
  getEntity,
  updateEntity,
  createEntity,
  reset,
} from './rate-schedule.reducer';

export const RateScheduleUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const rateScheduleEntity = useAppSelector(state => state.rateSchedule.entity);
  const loading = useAppSelector(state => state.rateSchedule.loading);
  const updating = useAppSelector(state => state.rateSchedule.updating);
  const updateSuccess = useAppSelector(
    state => state.rateSchedule.updateSuccess
  );
  const rateTypeValues = Object.keys(RateType);

  const handleClose = () => {
    navigate('/rate-schedule');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.startInstant = convertDateTimeToServer(values.startInstant);
    values.endInstant = convertDateTimeToServer(values.endInstant);
    values.created = convertDateTimeToServer(values.created);

    const entity = {
      ...rateScheduleEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          startInstant: displayDefaultDateTime(),
          endInstant: displayDefaultDateTime(),
          created: displayDefaultDateTime(),
        }
      : {
          rateType: 'CASUAL',
          ...rateScheduleEntity,
          startInstant: convertDateTimeFromServer(
            rateScheduleEntity.startInstant
          ),
          endInstant: convertDateTimeFromServer(rateScheduleEntity.endInstant),
          created: convertDateTimeFromServer(rateScheduleEntity.created),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="ticketlessLiteApp.rateSchedule.home.createOrEditLabel"
            data-cy="RateScheduleCreateUpdateHeading"
          >
            <Translate contentKey="ticketlessLiteApp.rateSchedule.home.createOrEditLabel">
              Create or edit a RateSchedule
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="rate-schedule-id"
                  label={translate('ticketlessLiteApp.rateSchedule.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label="Rate Type"
                id="rate-schedule-rateType"
                name="rateType"
                data-cy="rateType"
                type="select"
              >
                {rateTypeValues.map(rateType => (
                  <option value={rateType} key={rateType}>
                    {rateType}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label="Location Id"
                id="rate-schedule-locationId"
                name="locationId"
                data-cy="locationId"
                type="text"
                validate={{
                  required: {
                    value: true,
                    message: translate('entity.validation.required'),
                  },
                }}
              />
              <ValidatedField
                label="Start Instant"
                id="rate-schedule-startInstant"
                name="startInstant"
                data-cy="startInstant"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: {
                    value: true,
                    message: translate('entity.validation.required'),
                  },
                }}
              />
              <ValidatedField
                label="End Instant"
                id="rate-schedule-endInstant"
                name="endInstant"
                data-cy="endInstant"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: {
                    value: true,
                    message: translate('entity.validation.required'),
                  },
                }}
              />
              <ValidatedField
                label="Created"
                id="rate-schedule-created"
                name="created"
                data-cy="created"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: {
                    value: true,
                    message: translate('entity.validation.required'),
                  },
                }}
              />
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/rate-schedule"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RateScheduleUpdate;

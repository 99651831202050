export enum WeekDay {
  SUNDAY = 'SUNDAY',

  MONDAY = 'MONDAY',

  TUESDAY = 'TUESDAY',

  WEDNESDAY = 'WEDNESDAY',

  THURSDAY = 'THURSDAY',

  FRIDAY = 'FRIDAY',

  SATURDAY = 'SATURDAY',
}

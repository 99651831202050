import { formatDate } from 'app/shared/util/date-utils';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './style.css';

export const LaneStatusModel = ({
  show,
  onClose,
  createEvent,
  event,
  laneStatus,
  onLoopReset,
  onCPUReset,
  closeOpenEvent,
}) => {
  const handleClose = () => {
    onClose();
  };
  const createButtonLabel = () => {
    if (event.openParkingSession.exitTime !== null) {
      return 'Create session';
    }
    return 'Close open session';
  }

  const closeOpenSessionButton = openSession => {  
    if (openSession) {
      return (
        <Button
          variant="success"
          onClick={closeOpenEvent}
          style={{ width: '160px' }}
        >
          {createButtonLabel()}
        </Button>
      );
    }
    return <></>;
  };
  const ColorComponent = ({ item, status }) => {
    return (
      <div className="d-flex flex-row justify-content-flex-start align-items-center ">
        <div style={{ minWidth: '100px' }}>{item}</div>
        <div className="d-flex gap-2">
          {status === 'ERROR' && (
            <img src="content/assets/img/cloud-off-white.svg" width="20px" />
          )}
          {status === 'OK' && (
            <img src="content/assets/img/activity-white.svg" width="20px" />
          )}
          {status === 'WARN' && (
            <img
              src="content/assets/img/alert-triangle-white.svg"
              width="20px"
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal show={show} centered className="popup-container">
      <div style={{ padding: '0px 15px' }}>
        <Modal.Header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: 'white',
          }}
        >
          <p style={{ margin: '0px' }}>{laneStatus?.laneId}</p>

          <img
            src="content/assets/img/closecircle.svg"
            width="15px"
            onClick={() => onClose()}
            style={{ cursor: 'pointer' }}
          />
        </Modal.Header>
        <Modal.Body style={{ color: 'white', paddingBottom: '30px' }}>
          <div className="main-container">
            <div className="d-flex flex-column justify-center details-container each">
              <div>
                <p>Details: {event.accountId}</p>
                <p>Event Details : {formatDate(event.time)}</p>
              </div>
              <div>
                {openSessionLabel(event.openParkingSession)}
                <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                  {closeOpenSessionButton(event.openParkingSession)}
                </div>
              </div>
            </div>
            <div className="d-flex flex-column gap-2 justify-content-between align-items-center each">
              <img
                src="content/assets/img/favicon.png"
                width="75px"
                style={{ paddingTop: '20px' }}
              />
              <div className="d-flex flex-column justify-content-center align-items-center">
                <p style={{ margin: '0px' }}>{laneStatus?.mainTitle}</p>
                <p style={{ margin: '0px' }}>{laneStatus.screenStatus}</p>
                <p style={{ margin: '0px', color: 'yellow' }}>
                  {laneStatus.screenStatusInfo}
                </p>
              </div>

              <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Button
                    onClick={onLoopReset}
                    variant="danger"
                    style={{ width: '150px' }}
                  >
                    Reset loop
                  </Button>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Button
                    onClick={onCPUReset}
                    variant="danger"
                    style={{ width: '150px' }}
                  >
                    Reset CPU
                  </Button>
                </div>
              </div>
            </div>
            <div className="hardware-status each">
              <div className="d-flex flex-column align-items-center justify-content-center gap-2">
                {laneStatus &&
                  laneStatus.hardwareStatuses &&
                  Object.entries(laneStatus.hardwareStatuses).map(
                    ([item, color]) => (
                      <ColorComponent key={item} item={item} status={color} />
                    )
                  )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
const openSessionLabel = openSession => {
  if (openSession) {
    if (openSession.exitTime !== null) {
      return (<p>
        {'No active session found for this user.'}
      </p>)
    }
    return (
      <p>
        {'This user has a open session with entry at ' +
          formatDate(openSession.entryTime) +
          '.'}
      </p>
    );
  }
  return <></>;
};

export default LaneStatusModel;

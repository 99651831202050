import React, { useEffect, useState } from 'react';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { Button, Col, Row } from 'reactstrap';
import './CardValidation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { IRateSchedule } from 'app/shared/model/rate-schedule.model';
import axios from 'axios';
import StyledAGGrid from 'app/components/styledaggrid';
import { FilterType, createColumnDef } from 'app/entities/ColumnDefs';
import ValidationTable from './validation-table';
import { IParkingSession } from 'app/shared/model/parking-session.model';
import { getCurrentTimeInISOFormat } from 'app/shared/util/date-utils';
import {uniqBy} from 'lodash';
import { useConfigContext } from 'app/contexts/ConfigContext';

export interface ICardValidation {
  hidePopup: () => void;
}

function CardValidation(props: ICardValidation) {
  const config = useConfigContext();
  const [isLoading, setIsLoading] = useState(true);
  const [rateSchedules, setRateSchedules] = useState<IRateSchedule[]>([]);
  const [lastFourDigits, setLastFourDigits] = useState<string | undefined>(undefined);
  const [cardType, setCardType] = useState<string | undefined>(undefined);
  const [vehicleNumber, setVehicleNumber] = useState<string | undefined>(undefined);
  const [last4Digits, setLast4Digits] = useState<string | undefined>(undefined);

  const [searchedVehicles, setSearchedVehicles] = useState<IParkingSession[]>([]);
  const [selectedVehicleSessionId, setSelectedVehicleSessionId] = useState<any | undefined>(undefined);
  const [showTable, setShowTable] = useState(false);



  const applyValidation =  (sessionId:string) => async (selectedRate:IRateSchedule) => {
    try {
      console.log('selectedRate:', sessionId, selectedRate);
      const rateScheduleId = selectedRate.id;
  
      const response = await axios.post('/api/apply-validations', {
        sessionId,
        rateScheduleId,
      });
  
      if (response.status === 200) {
        const rate = response.data;
        console.log('Validation applied:', rate)
        toast.success(`Validation ${rate.rateScheduleName} applied`);
        setShowTable(false);
        props.hidePopup();
      } else {
        toast.error('Failed to apply validation');
      }
    } catch (error) {
      console.error('Error applying validation:', error);
      toast.error( error.response.data.message || 'Failed to apply validation');
    }
  };
  
  const fetchRateSchedules = async () => {
    try {
      const response = await axios.get('/api/valid-now-rate-schedules');
      setRateSchedules(response.data);
    } catch (error) {
      toast.error('Failed to fetch rate schedules');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchRateSchedules()]);
      setIsLoading(false);
    };

    fetchData();
  }, []);
 

  const tableColumns = [
    config.VALIDATION_RESULTS_SHOW_LPR && { header: 'LPR', accessor: 'plate' },
    { header: 'Entry Time', accessor: 'entryTime' },
    { header: 'Account Details', accessor: 'accountId' },
    config.VALIDATION_RESULTS_SHOW_CARD_TYPE && { header: 'Card Type', accessor: 'paymentMethodId' },
    {
      header: 'Action',
      accessor: 'action',
      isDropdown: true,
      dropdownOptions: rateSchedules,
      dropdownLabel: 'Apply Validation',
      onDropdownClick: applyValidation,
    },
  ];

  const searchVehicle = async () => {
    if (vehicleNumber) {
      try {
        const response1 = await axios.get(`/api/parking-sessions?exitTime.greaterThan=${getCurrentTimeInISOFormat()}&plate.contains=${vehicleNumber}`);
        const response2 = await axios.get(`/api/parking-sessions?exitTime.specified=false&plate.contains=${vehicleNumber}`);
        
        const sessions = uniqBy([...response1.data, ...response2.data], 'sessionId');

        setSearchedVehicles(sessions);
        setShowTable(true);
      } catch (error) {
        toast.error('Failed to fetch vehicle sessions');
        setSearchedVehicles([]);
        setShowTable(true);
      }
    } else if (last4Digits) {
      try {
        const response1 = await axios.get(`/api/parking-sessions?exitTime.greaterThan=${getCurrentTimeInISOFormat()}&accountId.contains=${last4Digits}`);
        const response2 = await axios.get(`/api/parking-sessions?exitTime.specified=false&accountId.contains=${last4Digits}`);
        
        const sessions = uniqBy([...response1.data, ...response2.data], 'sessionId');
  
        setSearchedVehicles(sessions);
        setShowTable(true);
      } catch (error) {
        toast.error('Failed to fetch vehicle sessions');
        setSearchedVehicles([]);
        setShowTable(true);
      }
    } else {
      toast.error('Please enter criteria to search vehicle');
    }
  }
  
  

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="10">
          <div className="search-btn-container">
            <ValidatedField
              label="Last 4 Digits"
              id="card-validation-last4-digits"
              name="last4Digits"
              data-cy="last4Digits"
              type="number"
              maxLength={4}
              minLength={4}
              className="card-validation-label search-btn"
              onChange={e => setLast4Digits(e.target.value)}
            />
            <Button
              id="search-btn"
              data-cy="entityCreateCancelButton"
              onClick={searchVehicle}
              className="mb-3"
              replace
              color="info"
            >
              <span className="d-none d-md-inline">Search</span>
            </Button>
          </div>
          <div className="search-btn-container">
            <ValidatedField
              label="LPR"
              id="card-validation-lnp"
              name="lnp"
              data-cy="lnp"
              type="text"
              className="card-validation-label search-btn"
              onChange={e => setVehicleNumber(e.target.value)}
            />
            <Button
              id="search-btn"
              data-cy="entityCreateCancelButton"
              onClick={searchVehicle}
              className="mb-3"
              replace
              color="info"
            >
              <span className="d-none d-md-inline">Search</span>
            </Button>
          </div>
          {showTable && (
            searchedVehicles.length === 0 ? (
              <div className="searchvehicle-table">
                <label className="table-label">No session found</label>
              </div>
            ) : (
              <div className="searchvehicle-table">
                <label className="table-label">{`${searchedVehicles.length} session(s) found`}</label>
                <ValidationTable
                  tableData={searchedVehicles}
                  columns={tableColumns.filter(c=>c !== undefined && c !== null)}
                  rowKeyAccessor="sessionId"
                />
              </div>
            )
          )}
        </Col>
      </Row>
    </div>
  );
}

export default CardValidation;

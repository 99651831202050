import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './rate-schedule.reducer';

export const RateSchedule = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(
    overrideSortStateWithQueryParams(
      getSortState(location, 'id'),
      location.search
    )
  );

  const rateScheduleList = useAppSelector(state => state.rateSchedule.entities);
  const loading = useAppSelector(state => state.rateSchedule.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="rate-schedule-heading" data-cy="RateScheduleHeading">
        <Translate contentKey="ticketlessLiteApp.rateSchedule.home.title">
          Rate Schedules
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="ticketlessLiteApp.rateSchedule.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
          <Link
            to="/rate-schedule/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="ticketlessLiteApp.rateSchedule.home.createLabel">
              Create new Rate Schedule
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {rateScheduleList && rateScheduleList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="ticketlessLiteApp.rateSchedule.id">
                    Id
                  </Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('rateType')}>
                  <Translate contentKey="ticketlessLiteApp.rateSchedule.rateType">
                    Rate Type
                  </Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('rateType')} />
                </th>
                <th className="hand" onClick={sort('locationId')}>
                  <Translate contentKey="ticketlessLiteApp.rateSchedule.locationId">
                    Location Id
                  </Translate>{' '}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName('locationId')}
                  />
                </th>
                <th className="hand" onClick={sort('startInstant')}>
                  <Translate contentKey="ticketlessLiteApp.rateSchedule.startInstant">
                    Start Instant
                  </Translate>{' '}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName('startInstant')}
                  />
                </th>
                <th className="hand" onClick={sort('endInstant')}>
                  <Translate contentKey="ticketlessLiteApp.rateSchedule.endInstant">
                    End Instant
                  </Translate>{' '}
                  <FontAwesomeIcon
                    icon={getSortIconByFieldName('endInstant')}
                  />
                </th>
                <th className="hand" onClick={sort('created')}>
                  <Translate contentKey="ticketlessLiteApp.rateSchedule.created">
                    Created
                  </Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('created')} />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {rateScheduleList.map((rateSchedule, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/rate-schedule/${rateSchedule.id}`}
                      color="link"
                      size="sm"
                    >
                      {rateSchedule.id}
                    </Button>
                  </td>
                  <td>{rateSchedule.rateType}</td>
                  <td>{rateSchedule.locationId}</td>
                  <td>
                    {rateSchedule.startInstant ? (
                      <TextFormat
                        type="date"
                        value={rateSchedule.startInstant}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td>
                    {rateSchedule.endInstant ? (
                      <TextFormat
                        type="date"
                        value={rateSchedule.endInstant}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td>
                    {rateSchedule.created ? (
                      <TextFormat
                        type="date"
                        value={rateSchedule.created}
                        format={APP_DATE_FORMAT}
                      />
                    ) : null}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/rate-schedule/${rateSchedule.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/rate-schedule/${rateSchedule.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">
                            Edit
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/rate-schedule/${rateSchedule.id}/delete`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">
                            Delete
                          </Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="ticketlessLiteApp.rateSchedule.home.notFound">
                No Rate Schedules found
              </Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default RateSchedule;

import React, { useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonsContainer from 'app/components/buttonsContainer';
import { Calendar } from 'app/components/calendar/calendar';
import StyledAGGrid from 'app/components/styledaggrid';
import { StyledGridDataSource } from 'app/components/styledaggrid/StyledGridDataSource';
import TitleContainer from 'app/components/titleContainer';
import {
  createColumnDef,
  createDownloadColumnMapping,
  FilterType,
} from 'app/entities/ColumnDefs';
import { formatDate } from 'app/shared/util/date-utils';
import { twoDecimalFormatter } from 'app/shared/util/numbers';
import { Button, ButtonGroup } from 'react-bootstrap';
import {  getColumnDefsForEnvironment } from 'app/config/constants';
import { useConfigContext } from 'app/contexts/ConfigContext';

export const Exceptions = () => {
  const config = useConfigContext();
  const [selectedEventTypes, setSelectedEventTypes] = useState(['Fee Changed', 'Exit MBR', 'Enter MBR']);

  const allColumnDefs = [
    createColumnDef('time', 'Date & Time', FilterType.DATE_EXTERNAL, params =>
      formatDate(params.value)
    ),
    createColumnDef('eventTypeDisplay', 'Event Type', FilterType.NONE, params =>{
      if (params.value === 'FEE_CHANGED') {
        return 'Fee Changed';
      }
      if (params.value === 'EXIT_MBR') {
        return 'Exit MBR';
      }
      if (params.value === 'ENTER_MBR') {
        return 'Enter MBR';
      }
      return params.value;
    
    }),

    createColumnDef('location', 'Location', FilterType.EQUALS),
    createColumnDef('laneId', 'Lane Id', FilterType.EQUALS),
    createColumnDef('accountId', 'Details', FilterType.EQUALS),
    createColumnDef('reason', 'Reason', FilterType.NONE),
    createColumnDef('email', 'Performed by', FilterType.EQUALS),
    { field: 'eventType', hide: true },
    createColumnDef(
      'originalFee',
      'Original Fee ($)',
      FilterType.EQUALS,
      twoDecimalFormatter
    ),
    createColumnDef(
      'amount',
      'Amount ($)',
      FilterType.EQUALS,
      twoDecimalFormatter
    ),
    createColumnDef(
      'plate',
      'LPR',
      FilterType.NONE,
      twoDecimalFormatter
    ),
  ];


  const columnDefs = getColumnDefsForEnvironment(allColumnDefs, config.EXCEPTIONS_COLUMNS);
  const dataSource = useMemo(() => {
    return new StyledGridDataSource(
      'id,desc',
      'api/exceptionEvents',
      false,
      columnDefs
    );
  }, []);
  const handleDownload = () => {
    dataSource.downloadAll(createDownloadColumnMapping(columnDefs));
  };
  const handleDateFilter = range => {
    dataSource.handleDateFilter('time', range);
  };

  const handleEventType = type => {
    setSelectedEventTypes(type);
    let filterValue;
    const t = [type];
    if (t[0] === 'Fee Changed') {
      filterValue = 'FEE_CHANGED';
    }
    if (t[0] === 'Exit MBR') {
      filterValue = 'EXIT_MBR';
    }
    if (t[0] === 'Enter MBR') {
      filterValue = 'ENTER_MBR';
    }

    dataSource.handleExternalFilter('eventType', t, filterValue);
  };


  const handleAccountType = type => {
    let updatedEventTypes;

    if (
      selectedEventTypes.length === 1 &&
      selectedEventTypes.includes(type)
    ) {
      return;
    }

    if (selectedEventTypes.includes(type)) {
      updatedEventTypes = selectedEventTypes.filter(
        selectedType => selectedType !== type
      );
    } else {
      updatedEventTypes = [...selectedEventTypes, type];
    }

    setSelectedEventTypes(updatedEventTypes);

    const filterValues = updatedEventTypes
      .map(selectedType => {
        if (selectedType === 'Fee Changed') return 'FEE_CHANGED';
        if (selectedType === 'Exit MBR') return 'EXIT_MBR';
        if (selectedType === 'Enter MBR') return 'ENTER_MBR';
        return null;
      })
      .filter(Boolean);
    dataSource.handleExternalInFilter('eventType', updatedEventTypes, filterValues);
  };
  
  function downloadButton(): React.ReactNode {
    return (
      <Button
        variant="success"
        className="me-1 mb-1 btn btn-success"
        onClick={handleDownload}
        style={{ margin: '0px 0px 2px 0px' }}
      >
        <FontAwesomeIcon
          icon="download"
          style={{ marginRight: '5px', width: '10px', height: '10px' }}
        />
        Download
      </Button>
    );
  }
  
  const accountTypeButton = type => (
    <Button
      key={type}
      variant={
        selectedEventTypes.includes(type) ? 'primary' : 'outline-primary'
      }
      onClick={() => handleAccountType(type)}
      style={{ cursor: 'pointer' }}
    >
      {type}
    </Button>
  );

  return (
    <React.Fragment>
      <div>
        <TitleContainer>
          <div>
            <h3>Exceptions</h3>
          </div>

          <ButtonsContainer>
            <ButtonGroup className="me-1 mb-1">
              {['Fee Changed', 'Exit MBR', 'Enter MBR'].map(type =>
                accountTypeButton(type)
              )}
            </ButtonGroup>
            <Button
              variant="primary"
              onClick={() => dataSource.refresh()}
              className="me-1 mb-1 btn btn-info"
            >
              <FontAwesomeIcon
                icon="sync"
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Refresh
            </Button>
            {downloadButton()}
            <div style={{ marginTop: '0px' }} className="mb-1">
              <Calendar applyDateFilter={range => handleDateFilter(range)} />
            </div>
          </ButtonsContainer>
        </TitleContainer>
        <StyledAGGrid
          columnDefs={columnDefs}
          fullWidth={true}
          noflex={true}
          paginated={true}
          paginationPageSizeNumber={10}
          dataSource={dataSource}
          rowModelType={'infinite'}
        />
      </div>
    </React.Fragment>
  );
};

export default Exceptions;

import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RateCard from './rate-card';
import RateCardDetail from './rate-card-detail';
import RateCardUpdate from './rate-card-update';
import RateCardDeleteDialog from './rate-card-delete-dialog';

const RateCardRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RateCard />} />
    <Route path="new" element={<RateCardUpdate />} />
    <Route path=":id">
      <Route index element={<RateCardDetail />} />
      <Route path="edit" element={<RateCardUpdate />} />
      <Route path="delete" element={<RateCardDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RateCardRoutes;

import { GridApi, IDatasource, IGetRowsParams } from 'ag-grid-community';
import {
  convertToInstantFormat,
  toAgGridDate,
  toAgGridDateOnly,
} from 'app/shared/util/date-utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';

interface FilterValue {
  type: string;
  filter?: string;
  dateFrom?: string;
  dateTo?: string;
}
export interface CRUD<T> {
  refresh?(): void;
  create?(entity): void;
  update?(entity): void;
  delete?(id: number | string): void;
  getOne?(id: number | string): Promise<T>;
}
export interface FilterModel {
  [key: string]: FilterValue;
}

export class StyledGridDataSource<T> implements IDatasource, CRUD<T> {
  private sortModel: string;
  private apiUrl: string;
  private gridApi: GridApi;
  private dateOnly: boolean;
  private columnDefs: any[];

  constructor(
    sortModel: string,
    apiUrl: string,
    dateOnly = false,
    columnDefs: any[]
  ) {
    this.sortModel = sortModel;
    this.apiUrl = apiUrl;
    this.dateOnly = dateOnly;
    this.columnDefs = columnDefs;
  }

  rowCount?: number;

  setGridApi(gridApi): void {
    this.gridApi = gridApi;
  }
  getRows(params: IGetRowsParams): void {
    console.log('Filter Model: ', this.gridApi.getFilterModel());
    
    const query = getFilter(this.gridApi.getFilterModel(), this.dateOnly);
    const sort = this.sortModel;
    const page = Math.floor(params.startRow / (params.endRow - params.startRow));
    const size = params.endRow - params.startRow;
  
    console.log('Getting rows ', params);
    console.log('query ', query);
  
    let queryString = `page=${page}&size=${size}`;
  
    if (sort) {
      queryString += `&sort=${sort}`;
    }
  
    if (query) {
      queryString += `&${query}`;
    }
  
    // Determine if the base URL already contains query parameters
    const separator = this.apiUrl.includes('?') ? '&' : '?';
    const requestUrl = `${this.apiUrl}${separator}${queryString}&cacheBuster=${new Date().getTime()}`;
  
    console.log('Final Request URL: ', requestUrl);
  
    this.gridApi.showLoadingOverlay();
  
    axios.get<T[]>(requestUrl).then(r => {
      console.log('response', r);
      this.gridApi.hideOverlay();
  
      params.successCallback(r.data, Number(r.headers['x-total-count']));
    });
  }
  
  refresh?(): void {
    console.log('Refreshing ', this.gridApi);
    this.gridApi?.purgeInfiniteCache();
  }

  getOne?(id): Promise<T> {
    const requestUrl = `${this.apiUrl}/${id}`;
    return axios.get<T>(requestUrl).then(r => r.data);
  }
  delete?(id): void {
    axios.delete<T>(`${this.apiUrl}/${id}`).then(r => this.refresh());
  }
  create?(entity): void {
    axios.post<T>(this.apiUrl, cleanEntity(entity)).then(r => this.refresh());
  }
  update?(entity): void {
    axios
      .put<T>(`${this.apiUrl}/${entity.id}`, cleanEntity(entity))
      .then(r => this.refresh());
  }
  handleDateFilter(timeFilterField, range) {
    console.log('range', range);
    if (this.gridApi) {
      const currentFilterModel = this.gridApi.getFilterModel();
      console.log('currentFilterModel', currentFilterModel);

      currentFilterModel[timeFilterField] = {
        dateFrom: toAgGridDate(range.startDate, false),
        dateTo: toAgGridDate(range.endDate, true),
        type: 'inRange',
      };
      console.log('currentFilterModel new', currentFilterModel);

      this.gridApi.setFilterModel(currentFilterModel);
      console.log('currentFilterModel new new', this.gridApi.getFilterModel());

      this.gridApi.onFilterChanged();
    } else {
      console.error('Grid API not available');
    }
  }
  handleExternalFilter(fieldName, type, filterValue) {
    if (this.gridApi) {
      const currentFilterModel = { ...this.gridApi.getFilterModel() };
      console.log("currentFilterModel", currentFilterModel)
      console.log("fieldName",  fieldName)
      console.log("type",  type)
      console.log("filterValue",  filterValue)

      if (type.length === 1) {
        currentFilterModel[fieldName] = {
          filter: filterValue,
          type: 'equals',
        };
      } else {
        delete currentFilterModel[fieldName];
      }
      this.gridApi.setFilterModel(currentFilterModel);
      this.gridApi.onFilterChanged();
    } else {
      console.error('Grid API not available');
    }
  }
  
  handleExternalInFilter(fieldName, type, filterValue) {
    if (this.gridApi) {
      const currentFilterModel = { ...this.gridApi.getFilterModel() };
      console.log("currentFilterModel", currentFilterModel)
      console.log("fieldName",  fieldName)
      console.log("type",  type)
      console.log("filterValue",  filterValue)

      if (filterValue && filterValue.length > 0) {
        currentFilterModel[fieldName] = {
          filter: filterValue.join(','),  // Join the filter values
          type: 'in',
        };
      } else {
        delete currentFilterModel[fieldName];
      }
      console.log("currentFilterModel2", currentFilterModel);
      this.gridApi.setFilterModel(currentFilterModel);
      console.log("FilterModel", this.gridApi.getFilterModel());

      this.gridApi.onFilterChanged();
    } else {
      console.error('Grid API not available');
    }
  }
  handleDateOnlyFilter(timeFilterField, range) {
    console.log('range', range);
    if (this.gridApi) {
      const currentFilterModel = this.gridApi.getFilterModel();
      console.log('currentFilterModel', currentFilterModel);

      currentFilterModel[timeFilterField] = {
        dateFrom: toAgGridDateOnly(range.startDate, false),
        dateTo: toAgGridDateOnly(range.endDate, true),
        type: 'inRange',
      };
      console.log('currentFilterModel new', currentFilterModel);

      this.gridApi.setFilterModel(currentFilterModel);
      console.log('currentFilterModel new new', this.gridApi.getFilterModel());

      this.gridApi.onFilterChanged();
    } else {
      console.error('Grid API not available');
    }
  }

  downloadAll(columnMapping: Record<string, string>): Promise<void> {
    const allData: T[] = [];
    let currentPage = 0;
    const pageSize = 100; // adjust as per your need
    const fetchNextPage = (): Promise<any> => {
      return this.fetchPage(currentPage, pageSize).then(data => {
        allData.push(...data);
        if (data.length === pageSize) {
          currentPage++;
          return fetchNextPage();
        }
      });
    };

    return fetchNextPage()
      .then(() => {
        const transformedData = this.transformData(allData, columnMapping);
        const csv = this.convertToCSV(transformedData);
        this.triggerDownload(csv, 'data.csv');
      })
      .catch(error => {
        console.error('Error while downloading data:', error);
      });
  }

  private fetchPage(page: number, size: number): Promise<T[]> {
    const query = getFilter(this.gridApi.getFilterModel(), this.dateOnly);
    const sort = this.sortModel;
  
    let queryString = `page=${page}&size=${size}`;
  
    if (sort) {
      queryString += `&sort=${sort}`;
    }
  
    if (query) {
      queryString += `&${query}`;
    }
  
    const separator = this.apiUrl.includes('?') ? '&' : '?';
    const requestUrl = `${this.apiUrl}${separator}${queryString}&cacheBuster=${new Date().getTime()}`;
  
    return axios.get<T[]>(requestUrl).then(r => r.data);
  }
  

  transformData<T>(
    data: T[],
    columnMapping: Record<string, string>
  ): Record<string, any>[] {
    return data.map(row => {
      const flattenedColumnDefs = this.columnDefs.flatMap(colDef =>
        colDef.children ? colDef.children : colDef
      );

      const formattedRow = {};
      flattenedColumnDefs.forEach(colDef => {
        const value = row[colDef.field];
        formattedRow[colDef.field] = colDef.valueFormatter
          ? colDef.valueFormatter({ value })
          : value;
      });

      const newRow = {};
      for (const column in columnMapping) {
        const mappedField = columnMapping[column];
        newRow[column] = formattedRow[mappedField];
      }

      return newRow;
    });
  }

  convertToCSV(data: Record<string, any>[]): string {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map(row => Object.values(row).join(','));
    return [header, ...rows].join('\n');
  }

  triggerDownload(data: string, filename: string): void {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  destroy?(): void {
    console.log('destroy');
  }
}

export const getFilter = (filterModel: FilterModel, dateOnly = false) => {
  console.log('filterModel in tx', filterModel);

  const queryParams = Object.entries(filterModel).flatMap(([key, value]) => {
    if (value.dateFrom && !value.dateTo) {
      return [];
    }
    if (value.type === 'contains') {
      return `${key}.in=${value.filter}`;
    }
    if (value.type === 'inRange') {
      return [
        `${key}.greaterThanOrEqual=${convertToInstantFormat(
          value.dateFrom,
          dateOnly
        )}`,
        `${key}.lessThanOrEqual=${convertToInstantFormat(
          value.dateTo,
          dateOnly
        )}`, // Removed extra space before '='
      ];
    }
    if (value.dateFrom)
      return `${key}.${value.type}=${convertToInstantFormat(
        value.dateFrom,
        dateOnly
      )}`;
    return `${key}.${value.type}=${value.filter}`;
  });
  console.log("queryParams", queryParams)
  return queryParams.join('&');
};

import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Button } from 'react-bootstrap';
import { Calendar as CalendarIcon } from 'react-feather';
import { addDays } from 'date-fns';

interface ICalendar {
  applyDateFilter?: (input: any) => void;
  range?: number;
}

const SetCalendarRange = (startDate, endDate) => {
  const formattedStartDate = startDate.toLocaleDateString('en-GB');
  const formattedEndDate = endDate.toLocaleDateString('en-GB');
  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const Calendar = ({ applyDateFilter, range = 7 }: ICalendar) => {
  const [dateRange, setDateRange] = useState({
    startDate: addDays(new Date(), -range),
    endDate: new Date(),
    key: 'selection',
  });
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [selectedRange, setSelectedRange] = useState(
    SetCalendarRange(dateRange.startDate, dateRange.endDate)
  );
  const buttonRef = useRef(null);

  const handleSelect = ranges => {
    const newStartDate = ranges.selection.startDate;
    let newEndDate = ranges.selection.endDate;
    console.log('ranges', ranges);
    // setDateRange(ranges.selection);
    setDateRange({
      startDate: newStartDate,
      endDate: newEndDate,
      key: 'selection',
    });
    // setSelectedRange(
    //   SetCalendarRange(ranges.selection.startDate, ranges.selection.endDate)
    // );
    setSelectedRange(SetCalendarRange(newStartDate, newEndDate));
    // applyDateFilter(ranges.selection);
    applyDateFilter({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  useEffect(() => {
    const handleClick = event => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setDisplayCalendar(false);
      }
    };
    applyDateFilter(dateRange);
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-end">
      <Button
        variant="light"
        onClick={() => setDisplayCalendar(!displayCalendar)}
        className="position-relative"
        ref={buttonRef}
      >
        <div className="d-flex align-items-center">
          <span>
            <CalendarIcon style={{ width: '15px', height: '15px' }} />
          </span>
          <span className="ms-2">{selectedRange}</span>
        </div>
        <div
          className="position-absolute"
          onClick={e => e.stopPropagation()}
          style={{ zIndex: 99, right: '0px' }}
        >
          {displayCalendar && (
            <DateRangePicker
              onChange={handleSelect}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              ranges={[dateRange]}
              value={dateRange}
            />
          )}
        </div>
      </Button>
    </div>
  );
};

import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './rate-schedule.reducer';

export const RateScheduleDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const rateScheduleEntity = useAppSelector(state => state.rateSchedule.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="rateScheduleDetailsHeading">
          <Translate contentKey="ticketlessLiteApp.rateSchedule.detail.title">
            RateSchedule
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="ticketlessLiteApp.rateSchedule.id">
                Id
              </Translate>
            </span>
          </dt>
          <dd>{rateScheduleEntity.id}</dd>
          <dt>
            <span id="rateType">
              <Translate contentKey="ticketlessLiteApp.rateSchedule.rateType">
                Rate Type
              </Translate>
            </span>
          </dt>
          <dd>{rateScheduleEntity.rateType}</dd>
          <dt>
            <span id="locationId">
              <Translate contentKey="ticketlessLiteApp.rateSchedule.locationId">
                Location Id
              </Translate>
            </span>
          </dt>
          <dd>{rateScheduleEntity.locationId}</dd>
          <dt>
            <span id="startInstant">
              <Translate contentKey="ticketlessLiteApp.rateSchedule.startInstant">
                Start Instant
              </Translate>
            </span>
          </dt>
          <dd>
            {rateScheduleEntity.startInstant ? (
              <TextFormat
                value={rateScheduleEntity.startInstant}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="endInstant">
              <Translate contentKey="ticketlessLiteApp.rateSchedule.endInstant">
                End Instant
              </Translate>
            </span>
          </dt>
          <dd>
            {rateScheduleEntity.endInstant ? (
              <TextFormat
                value={rateScheduleEntity.endInstant}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="created">
              <Translate contentKey="ticketlessLiteApp.rateSchedule.created">
                Created
              </Translate>
            </span>
          </dt>
          <dd>
            {rateScheduleEntity.created ? (
              <TextFormat
                value={rateScheduleEntity.created}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
        </dl>
        <Button
          tag={Link}
          to="/rate-schedule"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/rate-schedule/${rateScheduleEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RateScheduleDetail;

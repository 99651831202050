import EntitiesRoutes from 'app/entities/routes';
import React from 'react';
import Loadable from 'react-loadable';
import { Route } from 'react-router-dom';

import { AUTHORITIES } from 'app/config/constants';
import Home from 'app/modules/home/home';
import Login from 'app/modules/login/login';
import Logout from 'app/modules/login/logout';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import dashboardItems from './components/sidebar/dashboardItems';
import { useAppSelector } from './config/store';
import Account from './modules/account';
import Activate from './modules/account/activate/activate';
import PasswordResetFinish from './modules/account/password-reset/finish/password-reset-finish';
import PasswordResetInit from './modules/account/password-reset/init/password-reset-init';
import { SidebarMenuType } from './types/sidebar';
import RateScheduleUpdate from './modules/rate-schedules/rate-schedules-update';
import RateScheduleCreate from './modules/rate-schedules/rate-schedules-create';
import { useConfigContext } from './contexts/ConfigContext';

const loading = <div>loading ...</div>;

const Admin = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "administration" */ 'app/modules/administration'
    ),
  loading: () => loading,
});

const AppRoutes = () => {
  const account = useAppSelector(state => state.authentication.account);
  const config = useConfigContext();
  const dashboardItemsForUser = dashboardItems(
    account.authorities ? account.authorities : [],
    config
  );
  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route
          path="/"
          element={
            <PrivateRoute
              hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.DASHBOARD]}
            >
              <ErrorBoundaryRoutes>
                <Route path="" element={<Home />} />
              </ErrorBoundaryRoutes>
            </PrivateRoute>
          }
        />
        {pageRoutes(dashboardItemsForUser)}
        <Route
          path="pages/ratesSchedules/new"
          element={<RateScheduleCreate />}
        />
        <Route
          path="pages/ratesSchedules/update/:id"
          element={<RateScheduleUpdate />}
        />

        <Route path="account">
          <Route
            path="*"
            element={
              <PrivateRoute hasAnyAuthorities={[]}>
                <Account />
              </PrivateRoute>
            }
          />
          <Route path="activate" element={<Activate />} />
          <Route path="reset">
            <Route path="request" element={<PasswordResetInit />} />
            <Route path="finish" element={<PasswordResetFinish />} />
          </Route>
        </Route>
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

const pageRoutes = (sidebarMenu: SidebarMenuType[]) => {
  // TODO Remove this replace
  return sidebarMenu.map(menu => (
    <Route path={menu.href}>
      {menu.pages.map(page =>
        pageRoute(
          page.href.replace('/pages/', ''),
          <page.Component />,
          page.authorities
        )
      )}
    </Route>
  ));
};
const pageRoute = (p: string, node: React.ReactNode, authorities: string[]) => {
  console.log('pages', p);
  return (
    <Route
      path={p}
      element={
        <PrivateRoute hasAnyAuthorities={authorities}>
          <ErrorBoundaryRoutes>
            <Route path="" element={node} />
          </ErrorBoundaryRoutes>
        </PrivateRoute>
      }
    />
  );
};

export default AppRoutes;

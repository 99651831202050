import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './transaction.reducer';

export const TransactionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const transactionEntity = useAppSelector(state => state.transaction.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="transactionDetailsHeading">
          <Translate contentKey="ticketlessLiteApp.transaction.detail.title">
            Transaction
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="ticketlessLiteApp.transaction.id">
                Id
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.id}</dd>
          <dt>
            <span id="sessionId">
              <Translate contentKey="ticketlessLiteApp.transaction.sessionId">
                Session Id
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.sessionId}</dd>
          <dt>
            <span id="paymentType">
              <Translate contentKey="ticketlessLiteApp.transaction.paymentType">
                Payment Type
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.paymentType}</dd>
          <dt>
            <span id="paymentMethodId">
              <Translate contentKey="ticketlessLiteApp.transaction.paymentMethodId">
                Payment Method Id
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.paymentMethodId}</dd>
          <dt>
            <span id="durationInMinutes">
              <Translate contentKey="ticketlessLiteApp.transaction.durationInMinutes">
                Duration In Minutes
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.durationInMinutes}</dd>
          <dt>
            <span id="gatewaySessionId">
              <Translate contentKey="ticketlessLiteApp.transaction.gatewaySessionId">
                Gateway Session Id
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.gatewaySessionId}</dd>
          <dt>
            <span id="time">
              <Translate contentKey="ticketlessLiteApp.transaction.time">
                Time
              </Translate>
            </span>
          </dt>
          <dd>
            {transactionEntity.time ? (
              <TextFormat
                value={transactionEntity.time}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="amount">
              <Translate contentKey="ticketlessLiteApp.transaction.amount">
                Amount
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.amount}</dd>
          <dt>
            <span id="amountCurrency">
              <Translate contentKey="ticketlessLiteApp.transaction.amountCurrency">
                Amount Currency
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.amountCurrency}</dd>
          <dt>
            <span id="cardType">
              <Translate contentKey="ticketlessLiteApp.transaction.cardType">
                Card Type
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.cardType}</dd>
          <dt>
            <span id="cardToken">
              <Translate contentKey="ticketlessLiteApp.transaction.cardToken">
                Card Token
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.cardToken}</dd>
          <dt>
            <span id="location">
              <Translate contentKey="ticketlessLiteApp.transaction.location">
                Location
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.location}</dd>
          <dt>
            <span id="laneId">
              <Translate contentKey="ticketlessLiteApp.transaction.laneId">
                Lane Id
              </Translate>
            </span>
          </dt>
          <dd>{transactionEntity.laneId}</dd>
        </dl>
        <Button
          tag={Link}
          to="/transaction"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/transaction/${transactionEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TransactionDetail;

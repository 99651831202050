import React from 'react';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';
import { Card, Dropdown } from 'react-bootstrap';

import { MoreHorizontal } from 'react-feather';

import usePalette from '../hooks/usePalette';
export interface BarChartData {
  label: string;
  value: number;
}

export interface BarChartProps {
  data: BarChartData[];
}
const BarChart = ({ data }: BarChartProps) => {
  const palette = usePalette();
  const dayNames = data.map(d => format(new Date(d.label), 'EEEE'));
  const data1 = {
    labels: dayNames,
    datasets: [
      {
        label: 'Total revenue',
        backgroundColor: palette.primary,
        borderColor: palette.primary,
        hoverBackgroundColor: palette.primary,
        hoverBorderColor: palette.primary,
        data: data.map(d => d.value),
        barPercentage: 0.325,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 20,
        },
        stacked: true,
      },
      x: {
        grid: {
          color: 'transparent',
        },
        stacked: true,
      },
    },
  };

  return (
    <Card className="flex-fill" style={{ width: '45%', minWidth: '200px' }}>
      <Card.Header>
        <Card.Title className="mb-0">Weekly Revenue</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="chart chart-lg">
            <Bar data={data1} options={options} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BarChart;

import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './rate.reducer';

export const RateDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const rateEntity = useAppSelector(state => state.rate.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="rateDetailsHeading">
          <Translate contentKey="ticketlessLiteApp.rate.detail.title">
            Rate
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="ticketlessLiteApp.rate.id">Id</Translate>
            </span>
          </dt>
          <dd>{rateEntity.id}</dd>
          <dt>
            <span id="minimumDuration">
              <Translate contentKey="ticketlessLiteApp.rate.minimumDuration">
                Minimum Duration
              </Translate>
            </span>
          </dt>
          <dd>{rateEntity.minimumDuration}</dd>
          <dt>
            <span id="maximumDuration">
              <Translate contentKey="ticketlessLiteApp.rate.maximumDuration">
                Maximum Duration
              </Translate>
            </span>
          </dt>
          <dd>{rateEntity.maximumDuration}</dd>
          <dt>
            <span id="rate">
              <Translate contentKey="ticketlessLiteApp.rate.rate">
                Rate
              </Translate>
            </span>
          </dt>
          <dd>{rateEntity.rate}</dd>
          <dt>
            <Translate contentKey="ticketlessLiteApp.rate.rateCard">
              Rate Card
            </Translate>
          </dt>
          <dd>{rateEntity.rateCard ? rateEntity.rateCard.id : ''}</dd>
        </dl>
        <Button
          tag={Link}
          to="/rate"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/rate/${rateEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RateDetail;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { isEmail } from 'react-jhipster';
import { jsPDF } from 'jspdf';
import ActionButtons from 'app/components/actionButtons';
import {
  deleteEntity,
  getQRCodeImageEntity,
  sendEmailEntity,
} from '../../entities/validation/validation.reducer';

import StatusBadge from 'app/components/statusBadge';
import StyledAGGrid from 'app/components/styledaggrid';
import { StyledGridDataSource } from 'app/components/styledaggrid/StyledGridDataSource';
import StyledModal from 'app/components/StyledModal';
import {
  createColumnDef,
  createDownloadColumnMapping,
  FilterType,
} from 'app/entities/ColumnDefs';
import { formatDate } from 'app/shared/util/date-utils';
import html2canvas from 'html2canvas';
import ValidationCreateUpdate from './validation-edit';
import ButtonsContainer from 'app/components/buttonsContainer';
import TitleContainer from 'app/components/titleContainer';
import { Calendar } from 'app/components/calendar/calendar';
import { twoDecimalFormatter } from 'app/shared/util/numbers';
import CustomerAccountEditUpdate from '../customer-account/customer-account-edit';
import CardValidation from './validation-card';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { getColumnDefsForEnvironment } from 'app/config/constants';
import { getEnv } from '../administration/administration.reducer';
import { set } from 'lodash';
import { useDispatch } from 'react-redux';
import { CornerUpRight } from 'react-feather';
import { da } from 'date-fns/locale';
import { getCurrentStatus } from './validation-status';
import { useConfigContext } from 'app/contexts/ConfigContext';

export const ValidationsPage = () => {
  const config = useConfigContext();

  const dispatch = useAppDispatch();

  const loading = useAppSelector(state => state.validation.loading);
  const selectedQRImage = useAppSelector(state => state?.validation?.links);

  const [loadModal, setLoadModal] = useState(false);
  const [qrModal, setQRModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCardValidationModal, setShowCardValidationModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  

  const columnDefs = [
    {
      field: 'Status',
      headerName: 'Status',
      cellRenderer: StatusBadge,
      sortable: false,
      filter: false,
      cellRendererParams: params => {
        const startTime = params?.data?.startTime;
        
        const endTime = params?.data?.endTime;
        console.log('startTime', startTime);

        if (!startTime || !endTime) {
          return { type: 'default', text: 'Invalid Time' };
        }

        return getCurrentStatus(startTime, endTime);
      },
    },
    // createColumnDef('email', 'Email', FilterType.EQUALS),
    createColumnDef('multipleEntries', 'Usage Type', FilterType.NONE, p => p.value? 'M' : 'S'),
    createColumnDef('batchId', 'Batch ID', FilterType.NONE),

    createColumnDef('description', 'Description', FilterType.EQUALS),
    createColumnDef('rateSchedule.name', 'Type', FilterType.NONE),
    createColumnDef('count', 'Issued',  FilterType.NONE),
    // {
    //   field: 'Used',
    //   headerName: 'Used',
    //   cellRenderer: StatusBadge,
    //   sortable: false,
    //   filter: false,
    //   cellRendererParams: params => ({
    //     type: params.data?.isUsed ? 'danger' : 'success',
    //     text: params.data?.isUsed ? 'Yes' : 'No',
    //   }),
    // },
    createColumnDef('usedIndexes', 'Used',  FilterType.NONE, p=>{
      console.log('p', p?.value?.length)
      return p?.value?.length;
    }),

    
    // createColumnDef('startTime', 'Type', FilterType.NONE),

    createColumnDef(
      'startTime',
      'Start Time',
      FilterType.DATE_EXTERNAL,
      params => formatDate(params.value)
    ),
    createColumnDef('endTime', 'End Time', FilterType.NONE, params =>
      formatDate(params.value)
    ),
    
    createColumnDef('amount', 'Amount', FilterType.NONE, twoDecimalFormatter),
    createColumnDef('lnp', 'LPR', FilterType.NONE),
    createColumnDef('accountId', 'Account Id', FilterType.NONE),
    createColumnDef('startTime', 'Start Time', FilterType.NONE, params => formatDate(params.value)),
    createColumnDef('endTime', 'End Time', FilterType.NONE, params => formatDate(params.value)),
    createColumnDef('givenBy', 'Given By', FilterType.NONE),
    {
      field: 'Actions',
      headerName: 'Actions',
      filter: false,
      sortable: false,
      minWidth: 400,
      cellRenderer: ActionButtons,

      // {
      //   title: 'View QR',
      //   style: 'btn-success',
      //   icon: 'eye',
      //   onClick: params => viewQRCodeFn(params?.data?.id, params?.data?.startTime, params?.data?.endTime)
      // },


      cellRendererParams: params => {
        const usedCount = params?.data?.usedIndexes?.length || 0;

        return {
        titles: ['Download QR', 'Edit', 'Delete', `Events (${usedCount})`],
        styles: ['btn-success', 'btn-info', 'btn-danger', 'btn-primary'],
        icons: ['download', 'pencil-alt', 'trash', CornerUpRight],
        onClicks: [
          downloadQRCode(params?.data?.id), editMethod(params?.data?.id), deleteMethod(params?.data?.id), eventsMethod(params?.data?.id)
        ]}
      },
    },
  ];


  const finalCols = getColumnDefsForEnvironment(columnDefs, config.VALIDATION_COLUMNS);

  const dataSource = useMemo(() => {
    return new StyledGridDataSource(
      'startTime,desc',
      'api/validations',
      false,
      finalCols
    );
  }, []);

  const viewQRCodeFn =
    (id: string) => () => {
      setSelectedRowId(id);
      setQRModal(true);

      // dispatch(getQRCodeImageEntity(id));
      return;
  };

  const deleteMethod = (idParam: String) => () => {
    setLoadModal(true);
    setSelectedRowId(idParam);
  };
  const eventsMethod = (idParam: String) => () => {
    const newUrl = `pages/parking?validationId.equals=${idParam}`;

    window.open(newUrl, '_blank');

    // setLoadModal(true);
    // setSelectedRowId(idParam);
  };
  const editMethod = (idParam: String) => () => {
    setShowUpdateModal(true);
    setSelectedRowId(idParam);
  };

  const sendEmailMethod = (idParam: string, email: string) => () => {
    if (!isEmail(email)) {
      alert('Invalid Email');
      return;
    }
    dispatch(sendEmailEntity(idParam));
  };

  const addRowData = () => {
    setShowCreateModal(true);
  };

  const cardValidationPopUp = () => {
    setShowCardValidationModal(true);
  };

  const handleClose = () => {
    setLoadModal(false);
    setShowCreateModal(false);
    setShowCardValidationModal(false);
    setShowUpdateModal(false);
    setSelectedRowId(null);
    setQRModal(false);
    console.log('close');
    dataSource.refresh();
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(selectedRowId));
    setLoadModal(false);
    setShowCreateModal(false);
    setShowUpdateModal(false);
    setSelectedRowId(null);
    dataSource.refresh();
  };
  function downloadPDFWithImages(selectedQRImage) {
    const pdf = new jsPDF();
    selectedQRImage.forEach((imageBase64, index) => {

      pdf.addImage(imageBase64.image, 'PNG', 10, 10, 180, 180);
      pdf.setFontSize(12);
      
      const titleY = 200; 
      const descriptionY = titleY + 10;
      
      pdf.text(imageBase64.title, 10, titleY);
      // pdf.text(imageBase64.description, 10, descriptionY);
      
      if (index < selectedQRImage.length - 1) {
        pdf.addPage();
      }
      
    });

    pdf.save("QR_Codes.pdf");
  }

  // const downloadQRCode = () => {
  //   dispatch(getQRCodeImageEntity(selectedRowId));
  //   console.log('selectedQRImage' + selectedQRImage)
  //   downloadPDFWithImages(selectedQRImage);
  // };

  const downloadQRCode =  (id: string) => async () => {
    try {
      const response = await dispatch(getQRCodeImageEntity(id)).unwrap();
      downloadPDFWithImages(response.data);
    } catch (error) {
      console.error('Error fetching QR code image:', error);
    }
  };
  
  const handleDateFilter = range => {
    dataSource.handleDateFilter('startTime', range);
  };

  const handleDownload = () => {

    dataSource.downloadAll(createDownloadColumnMapping(finalCols));
  };

  const deleteTitleText = 'Confirm delete operation';
  const deleteDetailedText =
    'Are you sure you want to delete this validation account?';
  console.log('selectedQRImage', selectedQRImage)
  return (
    <React.Fragment>
      <div>
        <TitleContainer>
          <div>
            <h3>Validations</h3>
          </div>
          <ButtonsContainer>
            <Button
              variant="primary"
              onClick={() => dataSource.refresh()}
              className="me-1 btn btn-info"
              disabled={loading}
            >
              <FontAwesomeIcon
                icon="sync"
                spin={loading}
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Refresh
            </Button>
            <Button
              variant="primary"
              onClick={cardValidationPopUp}
              className="me-1 btn btn-primary"
              disabled={loading}
            >
              <FontAwesomeIcon
                icon={faTag}
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Card Validation
            </Button>
            <Button
              variant="primary"
              onClick={addRowData}
              className="me-1 btn btn-primary"
              disabled={loading}
            >

              + Add
            </Button>
            <Button
              variant="primary"
              onClick={() => handleDownload()}
              className="me-1 btn btn-success"
              disabled={loading}
            >
              <FontAwesomeIcon
                icon="download"
                spin={loading}
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Download
            </Button>
            <div style={{ marginTop: '0px' }}>
              <Calendar applyDateFilter={range => handleDateFilter(range)} />
            </div>
          </ButtonsContainer>
        </TitleContainer>
        <StyledAGGrid
          // rowData={validationList}
          // columnDefs={finalCols}
          columnDefs={finalCols}
          fullWidth={true}
          // noflex={false}
          paginated={true}
          paginationPageSizeNumber={10}
          // download={true}
          dataSource={dataSource}
          // csvFileName={'Validations.csv'}
          rowModelType={'infinite'}
        />
        <StyledModal
          showModal={loadModal}
          confirmMethod={confirmDelete}
          cancelMethod={handleClose}
          titleText={deleteTitleText}
          detailedText={deleteDetailedText}
          confirmButtonText="Delete Record"
          confirmButtonStyle="btn-danger"
        />
        <StyledModal
          showModal={qrModal}
          cancelMethod={handleClose}
          confirmMethod={downloadQRCode}
          titleText="QR Code"
          detailedText={
            <img
              id="qr-code-container"
              src={`data:image/png;base64,${selectedQRImage}`}
              style={{ width: '250px', height: '250px' }}
            />
          }
          confirmButtonText="Download QR Code"
          confirmButtonStyle="btn-success"
        />
        <StyledModal
          showModal={showCreateModal}
          cancelMethod={handleClose}
          titleText="Create new validation account"
          detailedText={
            <ValidationCreateUpdate
              hidePopup={() => handleClose()}
            />
          }
          confirmMethod={handleClose}
          noButtons={true}
        />
        <StyledModal
          showModal={showCardValidationModal}
          cancelMethod={handleClose}
          titleText="Apply validation"
          detailedText={
            <CardValidation
              hidePopup={() => setShowCardValidationModal(false)}
            />
          }
          confirmMethod={handleClose}
          noButtons={true}
          additionalClass='validation-style'
        />

        <StyledModal
          showModal={showUpdateModal}
          cancelMethod={handleClose}
          titleText="Update validation account"
          detailedText={
            <ValidationCreateUpdate
              id={selectedRowId}
              hidePopup={() => setShowUpdateModal(false)}
            />
          }
          confirmMethod={handleClose}
          noButtons={true}
        />
      </div>
    </React.Fragment>
  );
};


export default ValidationsPage;

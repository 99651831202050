import { convertToInstantFormat, formatDate, toISOString } from 'app/shared/util/date-utils';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CreateEventModal = ({ show, onClose, onSubmit, event, showOpenSessionData, disableTimeInput }) => {
  const [timeError, setTimeError] = useState('');

  console.log('first', event);
  const getSessionId = () => {
    if (showOpenSessionData) {
      return event.openParkingSession.sessionId;
    }
    return event.sessionId;
  }

  const getPaymentType = () => {
    if (showOpenSessionData) {
      return event.openParkingSession.paymentType;
    }
    return event.paymentType;
  }
  const getAccountId = () => {
    console.log("openParkingSession", showOpenSessionData, event.openParkingSession)

    if (showOpenSessionData) {
      return event.openParkingSession.accountId;
    }
    return event.accountId;
  }
  const getFee = () => {
    if (showOpenSessionData) {
      return event.openParkingSession.fee || 0;
    }
    return event.fee || 0;
  }
  const getPaymentMethodId = () => {
    if (showOpenSessionData) {
      return event.openParkingSession.paymentMethodId;
    }
    return event.paymentMethodId;
  }
  const getTime = () => {
    if (showOpenSessionData) {
      if (event.openParkingSession.entryTime) {
        return formatDate(event.openParkingSession.entryTime);
      }
      return formatDate(event.openParkingSession.exitTime);
    }
    return formatDate(new Date().toISOString());
  }
  const getEventType = () => {
    if (showOpenSessionData) {
      if (event.openParkingSession.entryTime) {
        return 'EXIT';
      }
      return 'ENTER';
    }
    return event.paymentMethodId;
  }
  const [createEventData, setCreateEventData] = useState({
    ...event,
    sessionId: getSessionId(),
    eventType: getEventType(),
    time:getTime(),
    paymentType: getPaymentType(),
    accountId: getAccountId(),
    paymentMethodId: getPaymentMethodId(),
    fee : getFee(),
    id: null,
  });
  console.log('createEventData', createEventData?.time);
  const formatDateForInput = (d) => {
    console.log('d', d)
    if (!d) {
      const localDate = new Date();
      const offset = localDate.getTimezoneOffset() * 60000;
      const adjustedDate = new Date(localDate.getTime() - offset);
      return adjustedDate.toISOString().slice(0, 16);
    }
    // handleChange time 2024-03-23T15:51
    const [day, month, yearHour] = d.split('/');
    const [year, time] = yearHour.split(' ');
    // Construct a date string in ISO format but without converting to UTC
    const formattedDateStr = `${year}-${month}-${day}T${time}`;
    // Use the string to create a date, assuming it's in local time
    const dateObj = new Date(formattedDateStr + 'Z');
    // Format it for datetime-local, keep it in local time
    return dateObj.toISOString().slice(0, 16);
  };
  const convertToDDMMYYYYHHMM = (isoString) => {
    const [datePart, timePart] = isoString.split('T');
    const [year, month, day] = datePart.split('-');
    return `${day}/${month}/${year} ${timePart}`;
  };
  const handleChange = event => {
    const { id, value } = event.target;
    console.log("handleChange time", value, id, createEventData?.time)
    
    if (id === 'time') {
      const selectedDateTime = new Date(value);
  
      if (selectedDateTime > new Date()) {
        setTimeError("Time cannot be in the future.");
        return;
      } else {
        setTimeError('');
      }
    }
    let formattedValue = id === 'time' ? convertToDDMMYYYYHHMM(value) : value;
    console.log("formattedValue", formattedValue)

    setCreateEventData(prevData => ({
      ...prevData,
      [id]: formattedValue,
    }));
  };

  const areRequiredFieldsNotEmpty = data => {
    const keysToCheck = [
      'sessionId',
      'eventType',
      'paymentType',
      'paymentMethodId',
      'fee',
      'accountId',
    ];

    for (const key of keysToCheck) {
      if (data[key] === '') {
        return false;
      }
    }
    return true;
  };
  const handleSubmit = () => {
    console.log('first', createEventData);
    const areAllFieldsFilled = areRequiredFieldsNotEmpty(createEventData);
    if (areAllFieldsFilled) {
      onSubmit({
        ...createEventData,
        time: toISOString({time:createEventData.time}),
      });
      setCreateEventData({});
      onClose();
    }
  };

  const handleClose = () => {
    setCreateEventData({});
    onClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          color: 'white',
        }}
      >
        <p style={{ margin: '0px' }}>Create Event</p>
      </Modal.Header>
      <Modal.Body
        style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
      >
        <Form.Group controlId="formSessionId">
          <Form.Label>Session id</Form.Label>
          <Form.Control
            type="text"
            onChange={handleChange}
            id="sessionId"
            value={createEventData?.sessionId}
            disabled={true}
          />
        </Form.Group>
        <Form.Group controlId="formEventType">
          <Form.Label>Event type</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={handleChange}
            id="eventType"
            value={createEventData?.eventType}
            disabled={true}
          >
            <option value="ENTER">ENTER</option>
            <option value="EXIT">EXIT</option>
            {/* <option value="FEE_CHANGED">FEE_CHANGED</option>
            <option value="ENTER_MBR">ENTER_MBR</option>
            <option value="EXIT_MBR">EXIT_MBR</option>
            <option value="GATE_OPEN">GATE_OPEN</option>
            <option value="GATE_CLOSED">GATE_CLOSED</option>
            <option value="GATE_LOCKED">GATE_LOCKED</option>
            <option value="GATE_UNLOCKED">GATE_UNLOCKED</option>
            <option value="BACK_AWAY_EXIT">BACK_AWAY_EXIT</option> */}
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="formPaymentType">
          <Form.Label>Payment type</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={handleChange}
            id="paymentType"
            value={createEventData?.paymentType}
            disabled={true}
          >
            <option value="CARD">CARD</option>
            <option value="QR_VALIDATION">QR_VALIDATION</option>
            <option value="QR_ACCOUNT">QR_ACCOUNT</option>
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formPaymentMethodId">
          <Form.Label>Payment method id</Form.Label>
          <Form.Control
            type="text"
            onChange={handleChange}
            id="paymentMethodId"
            value={createEventData?.paymentMethodId}
            disabled={true}
          />
        </Form.Group>
        <Form.Group controlId="formFeeNumber">
          <Form.Label>Fee</Form.Label>
          <Form.Control type="number" disabled={true} onChange={handleChange} id="fee"  value={createEventData?.fee}/>
        </Form.Group>
        <Form.Group controlId="formAccountId">
          <Form.Label>Account id</Form.Label>
          <Form.Control type="text" disabled={true}  onChange={handleChange} id="accountId" value={createEventData?.accountId}/>
        </Form.Group>
        <Form.Group controlId="formTime">
          <Form.Label>Time</Form.Label>
          <Form.Control 
            type="datetime-local" 
            id="time" 
            onChange={(event) => handleChange(event)} 
            value={formatDateForInput(createEventData?.time )}
            isInvalid={!!timeError}
            disabled={disableTimeInput}
          />
          <Form.Control.Feedback type="invalid">
            {timeError}
          </Form.Control.Feedback>
        </Form.Group>
        {/* <Form.Group controlId="formTime">
          <Form.Label>Time</Form.Label>
          <Form.Control 
            type="datetime-local" 
            onChange={(event) => handleChange(event.target.value)} 
            id="time" 
            value={createEventData?.time || ''}
          />
        </Form.Group> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateEventModal;

import { formatDate } from 'app/shared/util/date-utils';
import axios from 'axios';
import { values } from 'lodash';

export interface ColumnDef {
  field: string;
  headerName: string;
  filter: FilterType;
}

export enum FilterType {
  DISABLED = 'DISABLED',
  DATE_EXTERNAL = 'HIDDEN',
  EQUALS = 'EQUALS',
  RANGE = 'RANGE',
  LIST = 'LIST',
  NONE = 'NONE',
}

export const createDownloadColumnMapping = columnDefs => {
  const mapping = {};
  columnDefs.forEach(colDef => {
    if (colDef.headerName !== undefined && colDef.field !== undefined && colDef.field !== 'Actions') {
      mapping[colDef.headerName] = colDef.field;
    }
  });
  return mapping;
};
export interface IColumDef {
  field: string;
  headerName: string;
  filter: FilterType;
  valueFormatter?: (a) => string;
}
export const createColumnDef = (
  fieldName: string,
  headerName: string,
  filterType: FilterType,
  valueFormatter?: (a) => string) => {
  const columnDefs = {
    field: fieldName,
    headerName: headerName,
    sortable: false,
    autoHeight: true,
    // suppressMovable: false,
    lockVisible: false,
    resizable: true,
    // suppressMenu: true,
    ...getFilterType(filterType),
  };
  if (valueFormatter) {
    columnDefs['valueFormatter'] = valueFormatter;
  }

  return columnDefs;
};

export const getFilterType = (filterType: FilterType) => {
  if (filterType === FilterType.DISABLED) {
    return { filter: false };
  }
  if (filterType === FilterType.DATE_EXTERNAL) {
    return {
      filter: 'agDateColumnFilter',
      suppressMenu: true,
      // cellRenderer: params => formatDate(params.value),
      filterParams: {
        filterOptions: ['inRange'],
        defaultOption: 'inRange',
      },
    };
  }
  if (filterType === FilterType.EQUALS) {
    return {
      filterParams: {
        filterOptions: ['equals'],
        defaultOption: 'equals',
        suppressAndOrCondition: true,
      },
    };
  }
  if (filterType === FilterType.LIST) {
    return {
      filter: 'agSetColumnFilter',
      filterParams: {
        filterOptions: ['equals'],
        suppressMiniFilter: true,
        // values: ['FEE_CHANGED', 'MBR_ENTRY', 'MBR_EXIT']
      },
    };
  }
  if (filterType === FilterType.NONE) {
    return {
      filter: false,
    };
  }

  return {
    filterParams: {
      filterOptions: ['equals'],
      defaultOption: 'equals',
      suppressAndOrCondition: true,
    },
  };
};

// ConfigContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context to hold the configuration
const ConfigContext = createContext(null);

// Export a custom hook to access the context
export const useConfigContext = () => useContext(ConfigContext);

// ConfigProvider fetches the config and provides it to the whole app
export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    // Fetch the configuration from the API
    fetch('/api/config')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to load config');
        }
        return response.json();
      })
      .then(data => {
        // Transform the API response into the CONFIG format
        const transformedConfig = {
          APP_TYPE: data.REACT_APP_TYPE || '',
          PAGES: data.REACT_APP_PAGES || [],
          DASHBOARD_SHOW_LANES: data.REACT_APP_DASHBOARD_SHOW_LANES !== false,
          DASHBOARD_SHOW_TRANSACTIONS: data.REACT_APP_DASHBOARD_SHOW_TRANSACTIONS !== false,
          DASHBOARD_TRANSACTIONS_COLUMNS: data.REACT_APP_DASHBOARD_TRANSACTIONS_COLUMNS || [],
          VALIDATION_COLUMNS: data.REACT_APP_VALIDATION_COLUMNS || [],
          VALIDATION_RESULTS_SHOW_LPR: data.REACT_APP_VALIDATION_RESULTS_SHOW_LPR !== false,
          VALIDATION_RESULTS_SHOW_CARD_TYPE: data.REACT_APP_VALIDATION_RESULTS_SHOW_CARD_TYPE !== false,
          CUSTOMER_ACCOUNT_COLUMNS: data.REACT_APP_CUSTOMER_ACCOUNT_COLUMNS || [],
          FINANCIALS_COLUMNS: data.REACT_APP_FINANCIALS_COLUMNS || [],
          PAYMENT_HISTORY_COLUMNS: data.REACT_APP_PAYMENT_HISTORY_COLUMNS || [],
          TRAFFIC_ANALYSIS_COLUMNS: data.REACT_APP_TRAFFIC_ANALYSIS_COLUMNS || {},
          PARKING_EVENTS_COLUMNS: data.REACT_APP_PARKING_EVENTS_COLUMNS || [],
          EXCEPTIONS_COLUMNS: data.REACT_APP_EXCEPTIONS_COLUMNS || []
        };
        setConfig(transformedConfig); // Store the transformed config in the state
      })
      .catch(error => console.error('Error loading config:', error));
  }, []);

  if (!config) {
    return <div>Loading configuration...</div>; // Show a loading state if config is not yet loaded
  }

  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  );
};

export enum EventType {
  ENTER = 'ENTER',
  EXIT = 'EXIT',
  FEE_CHANGED = 'FEE_CHANGED',
  ENTER_MBR = 'ENTER_MBR',
  EXIT_MBR = 'EXIT_MBR',
  GATE_OPEN = 'GATE_OPEN',
  GATE_CLOSED = 'GATE_CLOSED',
  GATE_LOCKED = 'GATE_LOCKED',
  GATE_UNLOCKED = 'GATE_UNLOCKED',
  BACK_AWAY_EXIT = 'BACK_AWAY_EXIT',
  EXIT_VALIDATION = 'EXIT_VALIDATION',
  CURRENT_OCCUPANCY_UPDATED = 'CURRENT_OCCUPANCY_UPDATED',
  USE_VALIDATION = 'USE_VALIDATION',
}

import { ILaneStatus } from "./lane-status.model";

export interface IGate {
  id?: number;
  laneId?: string | null;
  status?: string | null;
  lockStatus?: string | null;
  laneStatus?:ILaneStatus;
}

export const defaultValue: Readonly<IGate> = {};

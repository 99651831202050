import React from 'react';
import { Badge, Col, Card, Row } from 'react-bootstrap';
import { DollarSign, Truck } from 'react-feather';

type StyledCardProps = {
  title?: any;
  data: any;
  progressData?: number;
  progressTitle?: string;
  icon?: any;
};

export default function StyleCard({
  data,
  title,
  progressTitle,
  progressData,
  icon,
}: StyledCardProps) {
  function showProgressSpan(progressTitle: string): React.ReactNode {
    return <span className="text-muted">{progressTitle}</span>;
  }

  function showProgressData(progressData: number): React.ReactNode {
    console.log('progressData', progressData);
    return (
      <Badge bg={progressData >= 0 ? 'success' : 'danger'} className="me-2">
        {progressData}%
      </Badge>
    );
  }
  console.log('Data', data);
  return (
    <div className="d-flex col-xl col-md-6">
      <div className="flex-fill card">
        <div className="py-4 card-body">
          <div className="d-flex align-items-start">
            <div className="flex-grow-1">
              <h3 className="mb-2">{data}</h3>
              <p className="mb-2">{title}</p>
              <div className="mb-0">
                {progressData !== undefined && showProgressData(progressData)}
                {progressTitle && showProgressSpan(progressTitle)}
              </div>
            </div>
            <div className="d-inline-block ms-3">
              <div className="stat">
                {icon == 'Dollar' && (
                  <DollarSign className="align-middle text-success" />
                )}
                {icon == 'Traffic' && (
                  <img
                    src="../../../content/assets/img/carIcon1.png"
                    className="align-middle text-success"
                    alt="carIcon"
                    width='24'
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

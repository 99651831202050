import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import React, { useMemo, useRef } from 'react';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-community/styles/ag-theme-material.css';

import { GridReadyEvent, RowModelType } from 'ag-grid-community';
import './style.scss'; // Style
import { StyledGridDataSource } from './StyledGridDataSource';
import Loader from '../Loader';

interface AGGridProps {
  rowData?: any;
  columnDefs?: any;
  fullWidth?: boolean;
  noflex?: boolean;
  paginated?: boolean;
  paginationPageSizeNumber?: number;
  download?: boolean;
  downloadFn?: any;
  downloadUrl?: string;
  showRefreshButton?: boolean;
  csvFileName?: string;
  calendar?: boolean;
  timeFilterField?: string;
  filter?: boolean;
  sortable?: boolean;
  resizable?: boolean;
  dataSource?: StyledGridDataSource<any>;
  rowModelType?: RowModelType;
  filterMethod?: (input: any) => void;
}

export default function StyledAGGrid({
  rowData,
  columnDefs,
  noflex,
  paginated,
  paginationPageSizeNumber,
  sortable = true,
  resizable = true,
  filter = true,
  dataSource,
  rowModelType = 'clientSide',
}: AGGridProps) {
  const gridRef = useRef<AgGridReact>(null);

  const defaultColDef = useMemo(
    () => ({
      sortable: sortable,
      editable: false,
      cellDataType: false,
      filter: filter,
      resizable: resizable,
      enableBrowserTooltips: true,
      tooltipValueGetter: params => params.value,
      ...(noflex ? {} : { flex: 1 }),
    }),
    []
  );

  const loadingCellRenderer = useMemo(() => {
    return Loader;
  }, []);
  const loadingCellRendererParams = useMemo(() => {
    return {
      loadingMessage: 'One moment please...',
    };
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    console.log('Grid is ready', params);
    const gridApi = params?.api;
    dataSource?.setGridApi(gridApi);
  };

  return (
    <div className="d-flex flex-column align-items-end">
      <div
        className="ag-theme-alpine-dark"
        style={{ width: '100%', border: 'transparent' }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={paginated}
          paginationPageSize={paginationPageSizeNumber}
          domLayout={'autoHeight'}
          datasource={dataSource}
          {...(rowData !== undefined ? { rowData } : {})}
          rowModelType={rowModelType}
          onGridReady={onGridReady}
          loadingOverlayComponent={loadingCellRenderer}
          loadingOverlayComponentParams={loadingCellRendererParams}
        />
      </div>
    </div>
  );
}

import { IRateCard } from 'app/shared/model/rate-card.model';

export interface IRate {
  id?: number;
  minimumDuration?: number;
  maximumDuration?: number;
  rate?: number;
  rateCard?: IRateCard | null;
}

export const defaultValue: Readonly<IRate> = {};

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="6" className="text-start">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a
                href="https://support.ticketlessparking.com/"
                target="_blank"
                className="text-muted"
              >
                Support
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://ticketlessparking.atlassian.net/servicedesk/customer/portal/1/article/502726664"
                target="_blank"
                className="text-muted"
              >
                Help Center
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;

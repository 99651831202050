import React from 'react';

interface IStatusBadgeProps {
  type: string;
  text: string;
}

export default function StatusBadge(props: IStatusBadgeProps) {
  return <span className={`badge bg-${props.type}`}>{props.text}</span>;
}

import {
  createAsyncThunk,
  isFulfilled,
  isPending
} from '@reduxjs/toolkit';
import { ITransaction, defaultValue } from 'app/shared/model/transaction.model';
import {
  EntityState,
  IQueryParams,
  createEntitySlice,
  serializeAxiosError,
} from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import axios from 'axios';

const initialState: EntityState<ITransaction> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/transactions';
export const getAll = ({ query, page, size, sort }: IQueryParams) => {
  let queryString = `page=${page}&size=${size}`;

  if (sort) {
    queryString += `&sort=${sort}`;
  }

  if (query) {
    queryString += `&${query}`;
  }

  const requestUrl = `${apiUrl}?${queryString}&cacheBuster=${new Date().getTime()}`;

  return axios.get<ITransaction[]>(requestUrl);
};

export const getEntities = createAsyncThunk(
  'transaction/fetch_entity_list',
  async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}?${
      sort ? `page=${page}&size=${size}&sort=${sort}&` : ''
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<ITransaction[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'transaction/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ITransaction>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'transaction/create_entity',
  async (entity: ITransaction, thunkAPI) => {
    const result = await axios.post<ITransaction>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'transaction/update_entity',
  async (entity: ITransaction, thunkAPI) => {
    const result = await axios.put<ITransaction>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'transaction/partial_update_entity',
  async (entity: ITransaction, thunkAPI) => {
    const result = await axios.patch<ITransaction>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'transaction/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ITransaction>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const TransactionSlice = createEntitySlice({
  name: 'transaction',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = TransactionSlice.actions;

// Reducer
export default TransactionSlice.reducer;

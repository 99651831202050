import dayjs from 'dayjs';

import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { parse } from 'date-fns';

export const  getCurrentTimeInISOFormat = () => {
  const now = new Date();
  return now.toISOString();
}
export const convertDateTimeFromServer = date =>
  date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null;

export const convertDateTimeToServer = date =>
  date ? dayjs(date).toDate() : null;

export const displayDefaultDateTime = () => {
  return dayjs().format(APP_LOCAL_DATETIME_FORMAT);
};

export const displayDefaultDateTimePlusOneMonth = () => {
  return dayjs()
    .add(1, 'month')
    .format(APP_LOCAL_DATETIME_FORMAT);
};
export const formatDateOnly = isoString => {
  if (!isoString) return '';

  const date = new Date(isoString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${day}/${month}/${year}`;
};
export const formatDate = isoString => {
  if (!isoString) return '';

  const date = new Date(isoString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};
export const toISOString = t => {
  const formattedString = t.time;
  if (!formattedString) return '';

  const [datePart, timePart] = formattedString.split(' ');

  if (!datePart || !timePart) return '';

  const [day, month, year] = datePart.split('/').map(Number);
  const [hours, minutes] = timePart.split(':').map(Number);


  const date = new Date(year, month - 1, day, hours, minutes);

  return date.toISOString();
};

export const convertToInstantFormat = (inputDate, dateOnly = false) => {
  const dateObj = new Date(inputDate);

  if (dateOnly) {
    // Returns only the 'YYYY-MM-DD' part
    return dateObj.toISOString().split('T')[0];
  } else {
    console.log('Original:', dateObj.toISOString());
    // Correct conversion to a string in the format you want
    const utcString = dateObj.toISOString().split('.')[0] + 'Z';
    console.log('Converted:', utcString);
    return utcString;
  }
};

export const toAgGridDate = (inputDate, isEnd) => {
  console.log('inputDate', inputDate);
  console.log('isEnd', isEnd);

  const date = new Date(inputDate);

  let year, month, dayToUse;
  let hours, minutes, seconds;

  if (isEnd) {
    date.setDate(date.getDate() + 1); // Increment the date
    year = date.getFullYear(); // Get the year of the incremented date
    month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month of the incremented date
    dayToUse = String(date.getDate()).padStart(2, '0'); // Get the day of the incremented date
    hours = '00';
    minutes = '00';
    seconds = '00';
  } else {
    year = date.getFullYear();
    month = String(date.getMonth() + 1).padStart(2, '0');
    dayToUse = String(date.getDate()).padStart(2, '0');
    hours = String(date.getHours()).padStart(2, '0');
    minutes = String(date.getMinutes()).padStart(2, '0');
    seconds = String(date.getSeconds()).padStart(2, '0');
  }

  const output = `${year}-${month}-${dayToUse} ${hours}:${minutes}:${seconds}`;
  console.log('inputDate, output, isEnd', inputDate, output, isEnd);

  return output;
};

export const toAgGridDateOnly = (inputDate, isEnd) => {
  console.log('inputDate', inputDate);
  console.log('isEnd', isEnd);

  const date = new Date(inputDate);
  const clonedDate = new Date(date.getTime());

  clonedDate.setDate(clonedDate.getDate() + 1);

  const year = clonedDate.getFullYear();
  const month = String(clonedDate.getMonth() + 1).padStart(2, '0');
  let dayToUse = clonedDate.getDate();

  const f = String(dayToUse).padStart(2, '0');
  const output = `${year}-${month}-${f}`;
  console.log('output', output);

  return output;
};

export const toDateOnly = inputDate => {
  const date = new Date(inputDate);
  const clonedDate = new Date(date.getTime());

  const year = clonedDate.getFullYear();
  const month = String(clonedDate.getMonth() + 1).padStart(2, '0');

  let dayToUse = clonedDate.getDate();

  const f = String(dayToUse).padStart(2, '0');
  const output = `${year}-${month}-${f}`;
  console.log('output', output);

  return output;
};

function addLeadingZeros(number) {
  const numStr = String(number);
  const zerosToAdd = 4 - numStr.length;
  if (zerosToAdd <= 0) {
    return numStr;
  }
  const leadingZeros = '0'.repeat(zerosToAdd);
  return leadingZeros + numStr;
}

export const converTimeToInt = time => {
  if (typeof time === 'string') {
    const timeSplits = time.split(':');
    const newTime = timeSplits[0] + timeSplits[1];
    return parseInt(newTime);
  } else {
    return time;
  }
};
export const converIntToTime = intTime => {
  const time = addLeadingZeros(intTime).toString();
  if (time.length === 3) {
    return time[0] + ':' + time[1] + time[2];
  } else {
    return time[0] + time[1] + ':' + time[2] + time[3];
  }
};

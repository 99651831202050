
export const showZones = (config) => {
    return config.APP_TYPE === 'ONSTREET'
}
export const showLPR = (config) => {
    return config.APP_TYPE === 'ONSTREET'
}
export const showAccountType = (config) => {
    return config.APP_TYPE === 'ONSTREET'
}
export const showCompany = (config) => {
    return config.APP_TYPE !== 'ONSTREET'
}
import React from 'react';
import { Card } from 'react-bootstrap';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSpeedometer from 'react-d3-speedometer';

export interface SpeedometerData {
  label: string;
  value: number;
  color: string;
  className: string;
}

export interface SpeedometerProps {
  data: SpeedometerData[];
}

export default function Speedometer({ data }: SpeedometerProps) {
  const percentages = percentage(data);

  return (
    <ReactSpeedometer
      width={200}
      ringWidth={10}
      maxValue={100}
      height={140}
      value={percentages[0]}
      currentValueText={`${percentages[0]}%`}
      needleColor="white"
      startColor="grey"
      segments={5}
      endColor="blue"
      textColor="#AAA"
      valueTextFontSize="10px"
      labelFontSize="14px"
      needleHeightRatio={0.7}
    />
  );
}
const tableHeader = () => {
  return (
    <tr>
      <th>Empty</th>
      <th className="text-end">Total</th>
      <th className="text-end">%</th>
    </tr>
  );
};
const tableBody = data => {
  const percentages = percentage(data);
  return (
    <tbody>
      {data.map((row, index) => {
        return (
          <tr>
            <td>
              <FontAwesomeIcon icon={faSquare} className={row.className} />{' '}
              {row.label}
            </td>
            <td className="text-end">{row.value}</td>
            <td className="text-end text-success">{percentages[index]}%</td>
          </tr>
        );
      })}
    </tbody>
  );
};

const percentage = data => {
  let sum = data.reduce((a, r) => a + r.value, 0);

  let percentages = data.map(r => Math.floor((100 * r.value) / sum));
  let percentageSum = percentages.reduce((a, b) => a + b, 0);

  let roundingError = 100 - percentageSum;

  for (let i = 0; i < percentages.length && roundingError > 0; i++) {
    percentages[i]++;
    roundingError--;
  }
  return percentages;
};

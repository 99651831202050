import { IRate } from 'app/shared/model/rate.model';
import { IRateSchedule } from 'app/shared/model/rate-schedule.model';
import { WeekDay } from 'app/shared/model/enumerations/week-day.model';

export interface IRateCard {
  id?: number;
  entryFrom?: number;
  entryTo?: number;
  exitFrom?: number;
  exitTo?: number;
  dayFrom?: keyof typeof WeekDay;
  dayTo?: keyof typeof WeekDay;
  rates?: IRate[] | null;
  rateSchedule?: IRateSchedule | null;
}

export const defaultValue: Readonly<IRateCard> = {};

import dayjs from 'dayjs';
import { EventType } from 'app/shared/model/enumerations/event-type.model';
import { PaymentType } from 'app/shared/model/enumerations/payment-type.model';

export interface IEvent {
  id?: number;
  sessionId?: string | null;
  laneId?: string | null;
  eventType?: keyof typeof EventType | null;
  paymentType?: keyof typeof PaymentType | null;
  paymentMethodId?: string | null;
  time?: string | null;
  fee?: number | null;
  feeCurrency?: string | null;
  performedById?: string | null;
  accountId?: string | null;
  ocrConfidence?: number;
  direction?: string | null;
  plateXPos?: number;
  plateYPos?: number;
  plateWidth?: number;
  plateHeight?: number;
  plate?: string | null;
  state?: string | null;
  reason?: string | null;
  currentOccupancy?: number;
}

export const defaultValue: Readonly<IEvent> = {};

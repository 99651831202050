import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
export interface OccupancyState {
    loading: boolean;
    errorMessage: string | null;
    currentOccupancy: number | null;
  }
const initialState: OccupancyState = {
  loading: false,
  errorMessage: null,
  currentOccupancy: null,
};

export const occupancySlice = createSlice({
  name: 'occupancy',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOccupancy: (state, action: PayloadAction<number>) => {
      state.currentOccupancy = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
  },
});
export const fetchOccupancyParkingSessions = createAsyncThunk(
    'occupancy/fetchCurrentOccupancy',
    async (_, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoading(true));
        const response = await axios.get<number>(`api/parking-sessions-count?exitTime.specified=false`);
        dispatch(setOccupancy(response.data));
        dispatch(setLoading(false));
        return response.data;
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(setError(error.message));
        return rejectWithValue(error.message);
      }
    }
  );
export const { setLoading, setOccupancy, setError } = occupancySlice.actions;

export default occupancySlice.reducer;

import React from 'react';
import { Badge } from 'reactstrap';

const CustomSelectRenderer = ({ value }) => {
  const maxToShow = 2;
  const items = value ? value.split(',') : [];
  const itemsToShow = items.slice(0, maxToShow);
  const remainingCount = items.length - maxToShow;

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '5px' }}>
      {itemsToShow.map((item, index) => (
        <Badge key={index} color="info">
          {item}
        </Badge>
      ))}
      {remainingCount > 0 && (
        <Badge color="info">{`+${remainingCount} more`}</Badge>
      )}
    </div>
  );
};

export default CustomSelectRenderer;

export const calculatePercentageChange = (
  currentPeriodCount: number,
  lastPeriodCount: number
) => {
  console.log('currentPeriodCount', currentPeriodCount);
  console.log('lastPeriodCount', lastPeriodCount);
  if (lastPeriodCount === 0) {
    console.log('returning 0');

    return 0.0;
  }

  const change = currentPeriodCount - lastPeriodCount;
  const percentageChange = (change / lastPeriodCount) * 100;
  return parseFloat(percentageChange.toFixed(2));
};

import React from 'react';
import { Line } from 'react-chartjs-2';

import { Card } from 'react-bootstrap';

const LineChart = ({ data }) => {
  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    plugins: {
      legend: {
        display: true,
      },
      filler: {
        propagate: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0,0,0,0.05)',
        },
      },
      y: {
        ticks: {
          stepSize: 20,
          callback: function (value) {
            return value + '%';
          },
        },
        display: true,
        borderDash: [5, 5],
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <Card>
      <Card.Body>
        <div className="chart">
          <Line data={data} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default LineChart;

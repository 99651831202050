import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

interface DropdownSelectorProps<T> {
  options: T[];
  applySelection: (selectedOption: T | undefined) => void;
  getOptionLabel: (option: T) => string;
  buttonText?: string;
  selectFirstOptionByDefault?: boolean;
}

function DropdownSelector<T>({
  options,
  applySelection,
  getOptionLabel,
  buttonText = 'Apply Selection',
  selectFirstOptionByDefault = false,
}: DropdownSelectorProps<T>) {
  const [showOptions, setShowOptions] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<T | undefined>(
    selectFirstOptionByDefault && options.length > 0 ? options[0] : undefined
  );
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option: T) => {
    setSelectedOption(option);
    setShowOptions(false);
  };

  const handleApplySelection = () => {
    applySelection(selectedOption);
  };

  return (
    <div className="apply-container" ref={wrapperRef}>
      <Button
        color="primary"
        disabled={!selectedOption}
        onClick={handleApplySelection}
        className="btn btn-primary apply"
      >
        {selectedOption ? getOptionLabel(selectedOption) : buttonText}
      </Button>
      <div
        className="icon-container"
        onClick={() => setShowOptions(!showOptions)}
        style={{
          backgroundColor: selectedOption ? '#3f80ea' : '#3266bb',
          opacity: selectedOption ? '1' : '0.95',
        }}
      >
        <FontAwesomeIcon
          icon={!showOptions ? faAngleDown : faAngleUp}
          className="arrow-icon"
          style={{
            opacity: selectedOption ? '1' : '0.95',
          }}
        />
      </div>
      {showOptions && (
        <div className="options-container">
          {options.map((option, index) => (
            <div
              key={index}
              className="option-item"
              onClick={() => handleOptionClick(option)}
              style={{
                backgroundColor:
                  selectedOption &&
                  getOptionLabel(selectedOption) === getOptionLabel(option)
                    ? '#006edb'
                    : 'initial',
                color:
                  selectedOption &&
                  getOptionLabel(selectedOption) === getOptionLabel(option)
                    ? 'white'
                    : 'black',
              }}
            >
              {getOptionLabel(option)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default DropdownSelector;

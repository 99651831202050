import transaction from 'app/entities/transaction/transaction.reducer';
import gate from 'app/entities/gate/gate.reducer';
import lane from 'app/entities/lane/lane.reducer';
import event from 'app/entities/event/event.reducer';
import parkingSession from 'app/entities/parking-session/parking-session.reducer';
import location from 'app/entities/location/location.reducer';
import businessDetails from 'app/entities/business-details/business-details.reducer';
import customerAccount from 'app/entities/customer-account/customer-account.reducer';
import rateSchedule from 'app/entities/rate-schedule/rate-schedule.reducer';
import rate from 'app/entities/rate/rate.reducer';
import rateCard from 'app/entities/rate-card/rate-card.reducer';
import validation from 'app/entities/validation/validation.reducer';
import occupancy from 'app/entities/parking-session/occupancy-reducer';
import dailyFinancials from 'app/entities/transaction/daily-finacials.reducer';

const entitiesReducers = {
  transaction,
  gate,
  lane,
  event,
  parkingSession,
  location,
  businessDetails,
  customerAccount,
  validation,
  rate,
  rateCard,
  rateSchedule,
  occupancy,
  dailyFinancials
};

export default entitiesReducers;

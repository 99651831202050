import 'app/config/dayjs';

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import AppRoutes from 'app/routes';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getProfile } from 'app/shared/reducers/application-profile';
import { getSession } from 'app/shared/reducers/authentication';
import '../content/assets/scss/dark.scss';
import Content from './components/Content';
import Main from './components/Main';
import Navbar from './components/navbar/Navbar';
import dashboardItems from './components/sidebar/dashboardItems';
import Sidebar from './components/sidebar/Sidebar';
import Wrapper from './components/Wrapper';
import Footer from './components/Footer';
import ChartJsDefaults from './shared/util/ChartJsDefaults';
import SidebarProvider from './contexts/SidebarProvider';
import LayoutProvider from './contexts/LayoutProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfigProvider } from './contexts/ConfigContext';

const baseHref = document
  .querySelector('base')
  .getAttribute('href')
  .replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
  }, []);

  const account = useAppSelector(state => state.authentication.account);

  return (
    <SidebarProvider>
      <LayoutProvider>
        <BrowserRouter basename={baseHref}>
          <ChartJsDefaults />
          <ConfigProvider>
          <div className="app-container">
            <Wrapper>
              <Sidebar
                // items={dashboardItems(
                //   account.authorities ? account.authorities : []
                // )}
                authorities = {account.authorities ? account.authorities : []}
              />
              <Main>
                <Navbar accountDetails={account} />
                <Content>
                  <ToastContainer
                    position={toast.POSITION.TOP_LEFT}
                    className="toastify-container"
                    toastClassName="toastify-toast"
                  />
                  <AppRoutes />
                </Content>
              </Main>
            </Wrapper>
            <Footer />
          </div>
          </ConfigProvider>
        </BrowserRouter>
      </LayoutProvider>
    </SidebarProvider>
  );
};

export default App;

import dayjs from 'dayjs';
import { IRateSchedule } from './rate-schedule.model';

export interface ICustomerAccount {
  id?: string;
  email?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  amount?: number | null;
  amountCurrency?: string | null;
  isUsed?: boolean | null;
  rateScheduleId?: number|null;
  rateSchedule?:IRateSchedule | null;
  zones?: string | null;
}

export const defaultValue: Readonly<ICustomerAccount> = {
  isUsed: false,
};

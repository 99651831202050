import {
  createColumnDef,
  createDownloadColumnMapping,
  FilterType,
} from 'app/entities/ColumnDefs';
import * as React from 'react';
import { useEffect, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Calendar } from 'app/components/calendar/calendar';
import StyledAGGrid from 'app/components/styledaggrid';
import { StyledGridDataSource } from 'app/components/styledaggrid/StyledGridDataSource';
import StyleCard from 'app/components/styledCard';
import { formatDateOnly } from 'app/shared/util/date-utils';
import { calculatePercentageChange } from 'app/shared/util/math';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import ButtonsContainer from 'app/components/buttonsContainer';
import TitleContainer from 'app/components/titleContainer';
import {
  twoDecimalFormatter,
  twoDecimalFormatterDevideBy100,
} from 'app/shared/util/numbers';
import { getColumnDefsForEnvironment } from 'app/config/constants';
import { useConfigContext } from 'app/contexts/ConfigContext';

export const Financials = () => {
  const config = useConfigContext();
  const columnDefs = [
    createColumnDef('date', 'Date', FilterType.DATE_EXTERNAL, params =>
      formatDateOnly(params.value)
    ),
    createColumnDef('day', 'Day Of Week', FilterType.NONE),
    createColumnDef(
      'qrCodePaid',
      'QR-Code Paid ($)',
      FilterType.NONE,
      twoDecimalFormatter
    ),
    createColumnDef(
      'cardPaid',
      'Tap & Go Paid ($)',
      FilterType.NONE,
      twoDecimalFormatter
    ),
    createColumnDef(
      'totalPaid',
      'Total Paid ($)',
      FilterType.NONE,
      twoDecimalFormatter
    ),
    createColumnDef(
      'qrCodeUnpaid',
      'QR-Code Unpaid ($)',
      FilterType.NONE,
      twoDecimalFormatterDevideBy100
    ),
    createColumnDef(
      'cardUnpaid',
      'Tap & Go Unpaid ($)',
      FilterType.NONE,
      twoDecimalFormatterDevideBy100
    ),
    createColumnDef(
      'totalUnpaid',
      'Total Unpaid ($)',
      FilterType.NONE,
      twoDecimalFormatterDevideBy100
    ),
  ];
  const columnDefForEnvironment = getColumnDefsForEnvironment(columnDefs, config.FINANCIALS_COLUMNS);
  console.log("columnDefForEnvironment", columnDefForEnvironment)
  
  const dataSource = useMemo(() => {
    return new StyledGridDataSource(
      'date,desc',
      'api/dailyFinancials',
      true,
      columnDefForEnvironment
    );
  }, []);

  const handleDateFilter = range => {
    dataSource.handleDateOnlyFilter('date', range);
  };
  const handleDownload = () => {
    dataSource.downloadAll(createDownloadColumnMapping(columnDefForEnvironment));
  };

  function downloadButton(): React.ReactNode {
    return (
      <Button
        variant="success"
        className="me-1 mb-1 btn btn-success"
        onClick={handleDownload}
        style={{ margin: '0px 0px 2px 0px' }}
      >
        <FontAwesomeIcon
          icon="download"
          style={{ marginRight: '5px', width: '10px', height: '10px' }}
        />
        Download
      </Button>
    );
  }
  interface FinancialStatistics {
    totalPaidCurrentMonth: number;
    totalPaidLastMonth: number;
    totalPaidCurrentWeek: number;
    totalPaidLastWeek: number;
    totalPaidCurrentYear: number;
    totalPaidLastYear: number;
    averageTransaction: number;
  }
  const defaultStatistics: FinancialStatistics = {
    totalPaidCurrentMonth: 0,
    totalPaidLastMonth: 0,
    totalPaidCurrentWeek: 0,
    totalPaidLastWeek: 0,
    totalPaidCurrentYear: 0,
    totalPaidLastYear: 0,
    averageTransaction: 0,
  };
  const [financialStatistics, setFinancialStatistics] =
    React.useState<FinancialStatistics>(defaultStatistics);

  useEffect(() => {
    const requestUrl = `api/financialStatistics`;
    axios.get(requestUrl).then(r => {
      setFinancialStatistics(r.data);
    });
  }, []);
  return (
    <React.Fragment>
      <div>
        <TitleContainer>
          <div>
            <h3>Financials</h3>
          </div>
          <ButtonsContainer>
            <Button
              variant="primary"
              onClick={() => dataSource.refresh()}
              className="me-1 mb-1 btn btn-info"
            >
              <FontAwesomeIcon
                icon="sync"
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Refresh
            </Button>
            {downloadButton()}
            <div style={{ marginTop: '0px' }} className="mb-1">
              <Calendar applyDateFilter={range => handleDateFilter(range)} />
            </div>
          </ButtonsContainer>
        </TitleContainer>
        <div>
          <StyledAGGrid
            columnDefs={columnDefForEnvironment}
            fullWidth={true}
            noflex={true}
            paginated={true}
            paginationPageSizeNumber={10}
            dataSource={dataSource}
            rowModelType={'infinite'}
          />
          <div className="row" style={{ marginTop: '30px' }}>
            <StyleCard
              icon="Dollar"
              title={'Week to date'}
              progressTitle={'vs last week'}
              data={financialStatistics.totalPaidCurrentWeek}
              progressData={calculatePercentageChange(
                financialStatistics.totalPaidCurrentWeek,
                financialStatistics.totalPaidLastWeek
              )}
            />

            <StyleCard
              icon="Dollar"
              title={'Month to date'}
              progressTitle={'vs last month'}
              data={financialStatistics.totalPaidCurrentMonth}
              progressData={calculatePercentageChange(
                financialStatistics.totalPaidCurrentMonth,
                financialStatistics.totalPaidLastMonth
              )}
            />

            <StyleCard
              icon="Dollar"
              title={'Year'}
              progressTitle={'vs last year'}
              data={financialStatistics.totalPaidCurrentYear}
              progressData={calculatePercentageChange(
                financialStatistics.totalPaidCurrentYear,
                financialStatistics.totalPaidLastYear
              )}
            />

            <StyleCard
              icon="Dollar"
              title={'Average per day'}
              data={financialStatistics.averageTransaction}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Financials;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledModal from 'app/components/StyledModal';
import ActionButtons from 'app/components/actionButtons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FilterType, createColumnDef } from 'app/entities/ColumnDefs';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import React, { useEffect, useMemo, useState } from 'react';
import { getSortState } from 'react-jhipster';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import StyledAGGrid from 'app/components/styledaggrid';
import { StyledGridDataSource } from 'app/components/styledaggrid/StyledGridDataSource';
import {
  deleteEntity,
  getEntities,
} from '../../entities/rate-schedule/rate-schedule.reducer';
import ButtonsContainer from 'app/components/buttonsContainer';
import TitleContainer from 'app/components/titleContainer';

export const RateSchedule = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [loadDeleteModal, setLoadDeleteModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  // const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(location, 'id'), location.search));

  // const rateScheduleList = useAppSelector(state => state.rateSchedule.entities);

  const columnDefs = [
    createColumnDef('name', 'Name', FilterType.NONE),
    createColumnDef('rateType', 'Rate Type', FilterType.NONE),
    {
      headerName: 'Valid Days',
      sortable: false,
      valueGetter: params => getValidDaysRange(params),
    },
    {
      field: 'Actions',
      minWidth: 200,
      sortable: false,
      filter: false,
      cellRenderer: ActionButtons,
      cellRendererParams: params => ({
        titles: ['Edit', 'Delete'],
        styles: ['btn-info', 'btn-danger'],
        icons: ['pencil-alt', 'trash'],
        onClicks: [
          editMethod(params?.data?.id),
          deleteMethod(params?.data?.id),
        ],
      }),
    },
  ];
  const dataSource = useMemo(() => {
    return new StyledGridDataSource('created,desc', 'api/rate-schedules', false, columnDefs);
  }, []);

  const handleClose = () => {
    setLoadDeleteModal(false);
    setSelectedRowId(null);
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(selectedRowId)).then(() => {
      dataSource.refresh();
    });
    setLoadDeleteModal(false);
    setSelectedRowId(null);
  };

  const deleteMethod = (idParam: String) => () => {
    setLoadDeleteModal(true);
    setSelectedRowId(idParam);
  };

  const editMethod = (idParam: String) => () => {
    window.location.href = `/pages/ratesSchedules/update/${idParam}`;
  };

  const deleteTitleText = 'Confirm delete operation';
  const deleteDetailedText =
    'Are you sure you want to delete this rate schedule?';

  const getValidDaysRange = params => {
    return `${params?.data?.rateCards[0]?.dayFrom} - ${params?.data?.rateCards[0]?.dayTo}`;
  };


  return (
    <div>
      <TitleContainer>
        <div>
          <h3>Rate Schedules</h3>
        </div>
        <ButtonsContainer>
          <Button
            variant="primary"
            onClick={() => dataSource.refresh()}
            className="me-1 mb-1 btn btn-info"
          >
            <FontAwesomeIcon
              icon="sync"
              style={{ marginRight: '5px', width: '12px', height: '12px' }}
            />
            Refresh
          </Button>
          <Button
            variant="primary"
            onClick={() => (window.location.href = '/pages/ratesSchedules/new')}
            className="me-1 mb-1 btn btn-primary"
          >
            <FontAwesomeIcon
              icon="plus"
              style={{ marginRight: '5px', width: '12px', height: '12px' }}
            />
            Add
          </Button>
        </ButtonsContainer>
      </TitleContainer>
      <StyledAGGrid
        columnDefs={columnDefs}
        fullWidth={true}
        // noflex={true}
        paginated={true}
        dataSource={dataSource}
        rowModelType={'infinite'}
      />
      <StyledModal
        showModal={loadDeleteModal}
        confirmMethod={confirmDelete}
        cancelMethod={handleClose}
        titleText={deleteTitleText}
        detailedText={deleteDetailedText}
        confirmButtonText="Delete Schedule"
        confirmButtonStyle="btn-danger"
      />
    </div>
  );
};

export default RateSchedule;

import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './event.reducer';

export const EventDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const eventEntity = useAppSelector(state => state.event.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="eventDetailsHeading">
          <Translate contentKey="ticketlessLiteApp.event.detail.title">
            Event
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="ticketlessLiteApp.event.id">Id</Translate>
            </span>
          </dt>
          <dd>{eventEntity.id}</dd>
          <dt>
            <span id="sessionId">
              <Translate contentKey="ticketlessLiteApp.event.sessionId">
                Session Id
              </Translate>
            </span>
          </dt>
          <dd>{eventEntity.sessionId}</dd>
          <dt>
            <span id="laneId">
              <Translate contentKey="ticketlessLiteApp.event.laneId">
                Lane Id
              </Translate>
            </span>
          </dt>
          <dd>{eventEntity.laneId}</dd>
          <dt>
            <span id="eventType">
              <Translate contentKey="ticketlessLiteApp.event.eventType">
                Event Type
              </Translate>
            </span>
          </dt>
          <dd>{eventEntity.eventType}</dd>
          <dt>
            <span id="paymentType">
              <Translate contentKey="ticketlessLiteApp.event.paymentType">
                Payment Type
              </Translate>
            </span>
          </dt>
          <dd>{eventEntity.paymentType}</dd>
          <dt>
            <span id="paymentMethodId">
              <Translate contentKey="ticketlessLiteApp.event.paymentMethodId">
                Payment Method Id
              </Translate>
            </span>
          </dt>
          <dd>{eventEntity.paymentMethodId}</dd>
          <dt>
            <span id="time">
              <Translate contentKey="ticketlessLiteApp.event.time">
                Time
              </Translate>
            </span>
          </dt>
          <dd>
            {eventEntity.time ? (
              <TextFormat
                value={eventEntity.time}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="fee">
              <Translate contentKey="ticketlessLiteApp.event.fee">
                Fee
              </Translate>
            </span>
          </dt>
          <dd>{eventEntity.fee}</dd>
          <dt>
            <span id="feeCurrency">
              <Translate contentKey="ticketlessLiteApp.event.feeCurrency">
                Fee Currency
              </Translate>
            </span>
          </dt>
          <dd>{eventEntity.feeCurrency}</dd>
          <dt>
            <span id="performedById">
              <Translate contentKey="ticketlessLiteApp.event.performedById">
                Performed By Id
              </Translate>
            </span>
          </dt>
          <dd>{eventEntity.performedById}</dd>
        </dl>
        <Button
          tag={Link}
          to="/event"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/event/${eventEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EventDetail;

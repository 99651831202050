import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonsContainer from 'app/components/buttonsContainer';
import { Calendar } from 'app/components/calendar/calendar';
import StyledAGGrid from 'app/components/styledaggrid';
import { StyledGridDataSource } from 'app/components/styledaggrid/StyledGridDataSource';
import TitleContainer from 'app/components/titleContainer';
import {
  FilterType,
  createColumnDef,
  createDownloadColumnMapping,
} from 'app/entities/ColumnDefs';
import { formatDateOnly } from 'app/shared/util/date-utils';
import React, { useMemo, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

function StayLengthPage() {
  const columnDefs = [
    createColumnDef('date', 'Date', FilterType.DATE_EXTERNAL, params =>
      formatDateOnly(params.value)
    ),
    createColumnDef('fifteenMin', '15min', FilterType.NONE),
    createColumnDef('thirtyMin', '30min', FilterType.NONE),
    createColumnDef('oneHour', '1h', FilterType.NONE),
    createColumnDef('oneAndHalfHour', '1.5h', FilterType.NONE),
    createColumnDef('twoHour', '2h', FilterType.NONE),
    createColumnDef('twoAndHalfHour', '2.5h', FilterType.NONE),
    createColumnDef('threeHour', '3h', FilterType.NONE),
    createColumnDef('fourHour', '4h', FilterType.NONE),
    createColumnDef('fiveHour', '5h', FilterType.NONE),
    createColumnDef('sixHour', '6h', FilterType.NONE),
    createColumnDef('sevenHour', '7h', FilterType.NONE),
    createColumnDef('eightHour', '8h', FilterType.NONE),
    createColumnDef('nineHour', '9h', FilterType.NONE),
    createColumnDef('tenHour', '10h', FilterType.NONE),
    createColumnDef('twentyFourHour', '24h', FilterType.NONE),
    { field: 'paymentType', hide: true },
  ];
  const [selectedAccountType, setSelectedAccountType] = useState('Casual');
  const dataSource = useMemo(() => {
    return new StyledGridDataSource(
      'date,desc',
      'api/stay-length',
      true,
      columnDefs
    );
  }, []);
  const handleDownload = () => {
    dataSource.downloadAll(createDownloadColumnMapping(columnDefs));
  };
  const handleDateFilter = range => {
    dataSource.handleDateOnlyFilter('date', range);
  };
  const handleAccountType = type => {
    setSelectedAccountType(type);
    let filterValue;
    const t = [type]
    if (t[0] === 'Casual') {
      filterValue = 'CARD';
    }
    if (t[0] === 'Account') {
      filterValue = 'QR_ACCOUNT';
    }
    if (t[0] === 'Validation') {
      filterValue = 'QR_VALIDATION';
    }
    dataSource.handleExternalFilter('paymentType', t, filterValue);
  };
  function accountTypeButton(type) {
    return (
      <Button
        key={type}
        variant={selectedAccountType === type ? 'primary' : 'outline-primary'}
        onClick={() => handleAccountType(type)}
        style={{ cursor: selectedAccountType === type ? 'default' : 'pointer' }}
        disabled={selectedAccountType === type}
      >
        {type}
      </Button>
    );
  }
  function downloadButton(): React.ReactNode {
    return (
      <Button
        variant="primary"
        onClick={handleDownload}
        className="me-1 btn btn-success"
      >
        <FontAwesomeIcon
          icon="download"
          style={{ marginRight: '5px', width: '12px', height: '12px' }}
        />
        Download
      </Button>
    );
  }
  function refreshButton(): React.ReactNode {
    return (
      <Button
        variant="primary"
        onClick={() => dataSource.refresh()}
        className="me-1 btn btn-info"
      >
        <FontAwesomeIcon
          icon="sync"
          style={{ marginRight: '5px', width: '12px', height: '12px' }}
        />
        Refresh
      </Button>
    );
  }

  return (
    <React.Fragment>
      <div>
        <TitleContainer>
          <div>
            <h3>Length of stay</h3>
          </div>
          <ButtonsContainer>
            <ButtonGroup className="me-1">
              {['Casual', 'Account', 'Validation'].map(type =>
                accountTypeButton(type)
              )}
            </ButtonGroup>
            {refreshButton()}
            {downloadButton()}
            <div style={{ marginTop: '0px' }}>
              <Calendar applyDateFilter={range => handleDateFilter(range)} />
            </div>
          </ButtonsContainer>
        </TitleContainer>
        <StyledAGGrid
          columnDefs={columnDefs}
          fullWidth={true}
          noflex={true}
          paginated={true}
          paginationPageSizeNumber={10}
          dataSource={dataSource}
          rowModelType={'infinite'}
        />
      </div>
    </React.Fragment>
  );
}

export default StayLengthPage;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  isNumber,
  translate,
  ValidatedField,
  ValidatedForm,
} from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  converIntToTime,
  converTimeToInt,
} from 'app/shared/util/date-utils';

import { RateType } from 'app/shared/model/enumerations/rate-type.model';
import { WeekDay } from 'app/shared/model/enumerations/week-day.model';
import { IRateSchedule } from 'app/shared/model/rate-schedule.model';
import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from '../../entities/rate-schedule/rate-schedule.reducer';
import { updateEntity as updateRateCardEntity } from '../../entities/rate-card/rate-card.reducer';
import {
  updateEntity as updateRateEntity,
  createEntity as createRateEntity,
  deleteEntity as deleteRateEntity,
} from '../../entities/rate/rate.reducer';
import { get, set } from 'lodash';
import { use } from 'i18next';
import { fi } from 'date-fns/locale';
import './rate.css';

export default function RateScheduleUpdate() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const rateScheduleEntity = useAppSelector(state => state.rateSchedule.entity);

  const [localRateCards, setLocalRateCards] = useState([]);
  const [rates, setRates] = useState([]);
  const [name, setName] = useState('');
  const [rateType, setRateType] = useState('');

  const [rateCardValues, setRateCardValues] = useState({
    dayFrom: '',
    dayTo: '',
    entryFrom: '',
    entryTo: '',
    exitFrom: '',
    exitTo: '',
  });

  const [localRateSchedule, setLocalRateSchedule] = useState(rateScheduleEntity);

  const loading = useAppSelector(state => state.rateSchedule.loading);
  const updating = useAppSelector(state => state.rateSchedule.updating);
  const updateSuccess = useAppSelector(state => state.rateSchedule.updateSuccess);

  const rateTypeValues = Object.keys(RateType);
  const weekDayValues = Object.keys(WeekDay);

  const handleClose = () => {
    navigate('/pages/ratesSchedules');
  };

  useEffect(() => {
    dispatch(getEntity(id));
  }, [dispatch, id]);

  useEffect(() => {
    setLocalRateSchedule(rateScheduleEntity);
  }, [rateScheduleEntity]);

  useEffect(() => {
    setLocalRateCards(localRateSchedule.rateCards);
  }, [localRateSchedule]);

  useEffect(() => {
    if (rateScheduleEntity.rateCards && rateScheduleEntity.rateCards.length > 0) {
      setRates(rateScheduleEntity.rateCards[0].rates);
    }
  }, [localRateCards]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const getRateCardData = values => ({
    id: localRateCards[0]?.id || rateScheduleEntity?.rateCards[0]?.entryFrom,
    entryFrom: rateCardValues.entryFrom || rateScheduleEntity?.rateCards[0]?.entryFrom,
    entryTo: rateCardValues.entryTo || rateScheduleEntity?.rateCards[0]?.entryTo,
    exitFrom: rateCardValues.exitFrom || rateScheduleEntity?.rateCards[0]?.exitFrom,
    exitTo: rateCardValues.exitTo || rateScheduleEntity?.rateCards[0]?.exitTo,
    dayFrom: rateCardValues.dayFrom || rateScheduleEntity?.rateCards[0]?.dayFrom,
    dayTo: rateCardValues.dayTo || rateScheduleEntity?.rateCards[0]?.dayTo,
  });

  const processEntities = async (values) => {
    try {
      const newRates = rates.map(rate => ({
        id: rate.id ? rate.id : null,
        minimumDuration: rate.minimumDuration,
        maximumDuration: rate.maximumDuration,
        rate: rate.rate,
        rateCard: null,
      }));
      values.rateCards[0].entryFrom = converTimeToInt(
        values.rateCards[0].entryFrom
      );
      values.rateCards[0].entryTo = converTimeToInt(
        values.rateCards[0].entryTo
      );
      values.rateCards[0].exitFrom = converTimeToInt(
        values.rateCards[0].exitFrom
      );
      values.rateCards[0].exitTo = converTimeToInt(values.rateCards[0].exitTo);

      const updatedRateCard = {
        ...values.rateCards[0],
        rates: newRates,
        rateSchedule: null,
      };

      const updatedValues = {
        ...values,
        rateCards: [updatedRateCard],
      };

      await dispatch(updateEntity(updatedValues));
    } catch (error) {
      console.error(error);
    }
  };

  const saveEntity = (values) => {
    const entity = {
      ...rateScheduleEntity,
      ...values,
      name: name || rateScheduleEntity.name,
      rateType: rateType || rateScheduleEntity.rateType,
      startInstant: convertDateTimeToServer(localRateSchedule.startInstant || rateScheduleEntity.startInstant),
      endInstant: convertDateTimeToServer(localRateSchedule.endInstant || rateScheduleEntity.endInstant),
      created: rateScheduleEntity.created,
      rateCards: [
        {
          ...getRateCardData(values),
          rates: null,
        },
      ],
    };

    processEntities(entity);
  };

  const handleRateCardChange = (field, value) => {
    setRateCardValues(prevValues => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleRatesChange = (field, index, value) => {
    const updatedRates = rates.map((rate, rateIndex) =>
      rateIndex === index
        ? { ...rate, [field]: parseInt(value, 10) }
        : { ...rate }
    );
    setRates(updatedRates);
  };

  const addRate = () => {
    const lastRate = rates[rates.length - 1];
    const minduration = lastRate.maximumDuration ? lastRate.maximumDuration + 1 : 0;
    setRates(prevRates => [...prevRates, { minimumDuration: minduration }]);
  };

  const removeRate = () => {
    if (rates[rates.length - 1].id) {
      dispatch(deleteRateEntity(rates[rates.length - 1].id));
    }
    setRates(prevRates => prevRates.slice(0, -1));
  };

  const updateStartInstant = (value) => {
    setLocalRateSchedule(prevState => ({
      ...prevState,
      startInstant: value,
    }));
  };

  const updateEndInstant = (value) => {
    setLocalRateSchedule(prevState => ({
      ...prevState,
      endInstant: value,
    }));
  };

  const ratesRows = () => {
    return rates.map((rate, index) => {
      const isLastRate = index === rates.length - 1;
      return (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '20px',
            alignItems: 'center',
            justifyItems: 'center',
          }}
          key={index}
        >
          <ValidatedField
            label="Minimum Duration"
            id={`rateCards[0].rates[${index}].minimumDuration`}
            name={`rateCards[0].rates[${index}].minimumDuration`}
            data-cy="minimumDuration"
            type="number"
            min="0"
            defaultValue={rates && rates.length > 0 ? rates[index]?.minimumDuration : 0}
            onChange={(e) => handleRatesChange('minimumDuration', index, e.target.value)}
            validate={{
              required: {
                value: true,
                message: translate('entity.validation.required'),
              },
              validate: (v) => isNumber(v) || translate('entity.validation.number'),
            }}
          />
          <ValidatedField
            label="Maximum Duration"
            id={`rateCards[0].rates[${index}].maximumDuration`}
            name={`rateCards[0].rates[${index}].maximumDuration`}
            data-cy="maximumDuration"
            type="number"
            min="0"
            defaultValue={rates && rates.length > 0 ? rates[index]?.maximumDuration : 0}
            onChange={(e) => handleRatesChange('maximumDuration', index, e.target.value)}
            validate={{
              required: {
                value: true,
                message: translate('entity.validation.required'),
              },
              validate: (v) => isNumber(v) || translate('entity.validation.number'),
            }}
          />
          <ValidatedField
            label="Rate"
            id={`rateCards[0].rates[${index}].rate`}
            name={`rateCards[0].rates[${index}].rate`}
            data-cy="rate"
            type="number"
            min="0"
            defaultValue={rates && rates.length > 0 ? rates[index]?.rate : 0}
            onChange={(e) => handleRatesChange('rate', index, e.target.value)}
            validate={{
              required: {
                value: true,
                message: translate('entity.validation.required'),
              },
              validate: (v) => isNumber(v) || translate('entity.validation.number'),
            }}
          />
          <div style={{ display: 'flex', gap: '10px' }}>
            {isLastRate && index !== 0 ? (
              <>
                <Button
                  onClick={addRate}
                  replace
                  color="info"
                  style={{ height: '100%', marginTop: '15px' }}
                >
                  <FontAwesomeIcon icon="add" />
                </Button>
                <Button
                  onClick={removeRate}
                  replace
                  color="danger"
                  style={{ height: '100%', marginTop: '15px' }}
                >
                  <FontAwesomeIcon icon="minus" />
                </Button>
              </>
            ) : (
              <>
                {isLastRate && (
                  <Button
                    onClick={addRate}
                    replace
                    color="info"
                    style={{ height: '100%', marginTop: '15px' }}
                  >
                    <FontAwesomeIcon icon="add" />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="ticketlessLiteApp.rateSchedule.home.createOrEditLabel"
            data-cy="RateScheduleCreateUpdateHeading"
          >
            Update Rate Schedule
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={rateScheduleEntity}
              onSubmit={saveEntity}
            >
              <div>
                <ValidatedField
                  label="Rate Schedule Name"
                  id="rate-schedule-name"
                  name="name"
                  data-cy="name"
                  type="text"
                  defaultValue={name || rateScheduleEntity.name}
                  onChange={(e) => setName(e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                  }}
                />
                <ValidatedField
                  label="Rate Type"
                  id="rate-schedule-rateType"
                  name="rateType"
                  data-cy="rateType"
                  type="select"
                  onChange={(e) => setRateType(e.target.value)}
                  defaultValue={rateType || rateScheduleEntity.rateType}
                >
                  {rateTypeValues.map((type) => (
                    <option value={type} key={type}>
                      {type}
                    </option>
                  ))}
                </ValidatedField>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gap: '10px',
                }}
              >
                <ValidatedField
                  label="Entry From"
                  id="rateCards[0].entryFrom"
                  name="rateCards[0].entryFrom"
                  data-cy="rateCards[0].entryFrom"
                  type="time"
                  defaultValue={
                    rates && rates.length > 0
                      ? converIntToTime(rateScheduleEntity.rateCards[0].entryFrom)
                      : ''
                  }
                  onChange={(e) => handleRateCardChange('entryFrom', e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                    validate: (v) => isNumber(v) || translate('entity.validation.number'),
                  }}
                />
                <ValidatedField
                  label="Entry To"
                  id="rate-card-entryTo"
                  name="rateCards[0].entryTo"
                  data-cy="entryTo"
                  type="time"
                  defaultValue={
                    rates && rates.length > 0
                      ? converIntToTime(rateScheduleEntity.rateCards[0].entryTo)
                      : ''
                  }
                  onChange={(e) => handleRateCardChange('entryTo', e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                    validate: (v) => isNumber(v) || translate('entity.validation.number'),
                  }}
                />
                <ValidatedField
                  label="Exit From"
                  id="rateCards[0].exitFrom"
                  name="rateCards[0].exitFrom"
                  data-cy="rateCards[0].exitFrom"
                  type="time"
                  defaultValue={
                    rates && rates.length > 0
                      ? converIntToTime(rateScheduleEntity.rateCards[0].exitFrom)
                      : ''
                  }
                  onChange={(e) => handleRateCardChange('exitFrom', e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                    validate: (v) => isNumber(v) || translate('entity.validation.number'),
                  }}
                />
                <ValidatedField
                  label="Exit To"
                  id="rate-card-exitTo"
                  name="rateCards[0].exitTo"
                  data-cy="exitTo"
                  type="time"
                  defaultValue={
                    rates && rates.length > 0
                      ? converIntToTime(rateScheduleEntity.rateCards[0].exitTo)
                      : ''
                  }
                  onChange={(e) => handleRateCardChange('exitTo', e.target.value)}
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                    validate: (v) => isNumber(v) || translate('entity.validation.number'),
                  }}
                />

                <ValidatedField
                  label="Day From"
                  id="rate-card-dayFrom"
                  name="rateCards[0].dayFrom"
                  data-cy="rateCards[0].dayFrom"
                  type="select"
                  onChange={(e) => handleRateCardChange('dayFrom', e.target.value)}
                >
                  {weekDayValues.map((weekDay) => (
                    <option
                      value={weekDay}
                      key={weekDay}
                      selected={
                        rates && rates.length > 0 ? localRateCards[0].dayFrom === weekDay : false
                      }
                    >
                      {weekDay}
                    </option>
                  ))}
                </ValidatedField>
                <ValidatedField
                  label="Day To"
                  id="rate-card-dayTo"
                  name="rateCards[0].dayTo"
                  data-cy="rateCards[0].dayTo"
                  type="select"
                  onChange={(e) => handleRateCardChange('dayTo', e.target.value)}
                >
                  {weekDayValues.map((weekDay) => (
                    <option
                      value={weekDay}
                      key={weekDay}
                      selected={
                        rates && rates.length > 0 ? localRateCards[0].dayTo === weekDay : false
                      }
                    >
                      {weekDay}
                    </option>
                  ))}
                </ValidatedField>

                <ValidatedField
                  label="Valid From"
                  id="rate-schedule-startInstant"
                  name="startInstant"
                  data-cy="startInstant"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
                  onChange={(e) => updateStartInstant(e.target.value)}
                  value={
                    localRateSchedule.startInstant
                      ? convertDateTimeFromServer(localRateSchedule.startInstant)
                      : convertDateTimeFromServer(rateScheduleEntity.startInstant)
                  }
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                  }}
                />
                <ValidatedField
                  label="Valid To"
                  id="rate-schedule-endInstant"
                  name="endInstant"
                  data-cy="endInstant"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
                  onChange={(e) => updateEndInstant(e.target.value)}
                  value={
                    localRateSchedule.endInstant
                      ? convertDateTimeFromServer(localRateSchedule.endInstant)
                      : convertDateTimeFromServer(rateScheduleEntity.endInstant)
                  }
                  validate={{
                    required: {
                      value: true,
                      message: translate('entity.validation.required'),
                    },
                  }}
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                <h4>Rates</h4>
                {rates && rates.length > 0 && ratesRows()}
              </div>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/pages/ratesSchedules"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
}


export const getCurrentStatus = (startTime, endTime) => {
    const now = new Date();
    const start = new Date(startTime);
    const end = new Date(endTime);

    if (now < start) {
      return { type: 'warning', text: 'Pending' };
    } else if (now >= start && now <= end) {
      return { type: 'success', text: 'Active' };
    } else {
      return { type: 'danger', text: 'Expired' };
    }
  };
  
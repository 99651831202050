// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-account-label {
  text-align: left;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/customer-account/CustomerAccountEditUpdate.css"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF","sourcesContent":[".customer-account-label {\n  text-align: left;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

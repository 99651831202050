import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
  displayDefaultDateTimePlusOneMonth,
} from 'app/shared/util/date-utils';

import './CustomerAccountEditUpdate.css';
import { toast } from 'react-toastify';
import { CRUD } from 'app/components/styledaggrid/StyledGridDataSource';
import { ICustomerAccount } from 'app/shared/model/customer-account.model';
import axios from 'axios';
import { IRateSchedule } from 'app/shared/model/rate-schedule.model';
import makeAnimated from 'react-select/animated';
import Select, { components } from 'react-select';
import Loader from 'app/components/Loader';
import CustomSelect from 'app/components/CustomSelect';
import ReactSelect from 'react-select';
import { showCompany, showAccountType, showLPR, showZones } from './rules';
import { useConfigContext } from 'app/contexts/ConfigContext';
// import CustomMultiSelect from 'app/components/CustomMultiSelect';

export interface ICustomerAccountProps {
  id?: any;
  hidePopup: () => void;
  dataSource: CRUD<ICustomerAccount>;
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export default function CustomerAccountEditUpdate(props: ICustomerAccountProps) {
  const config = useConfigContext();
  const navigate = useNavigate();

  const { id, dataSource, hidePopup } = props;
  const isNew = id === undefined;
  const [isLoading, setIsLoading] = useState(true);

  const [customerAccountEntity, setCustomerAccountEntity] = useState<ICustomerAccount | null>(null);
  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const [noEndTimeChecked, setNoEndTimeChecked] = useState(false);
  const [zones, setZones] = useState<string[]>([]);
  const [rateSchedules, setRateSchedules] = useState<IRateSchedule[]>([]);
  const [selectedZones, setSelectedZones] = useState<string[]>([]);

  const defaultValues = () =>
    isNew
      ? {
        startTime: displayDefaultDateTime(),
        endTime: displayDefaultDateTimePlusOneMonth(),
        rateScheduleId: rateSchedules[0]?.id || 0,
      }
      : {
        ...customerAccountEntity,
        startTime: convertDateTimeFromServer(customerAccountEntity?.startTime || ''),
        endTime: convertDateTimeFromServer(customerAccountEntity?.endTime || ''),
        rateScheduleId: customerAccountEntity?.rateSchedule ? customerAccountEntity.rateSchedule.id : rateSchedules[0]?.id,
      };

  const [formValues, setFormValues] = useState(defaultValues());

  useEffect(() => {
    const fetchZones = async () => {
      if (!showZones(config)) {
        return;
      }
      try {
        const response = await axios.get('/api/lanes');
        const fetchedZones = response.data.map(zone => zone.id);
        setZones(fetchedZones);
      } catch (error) {
        toast.error('Failed to fetch zones');
      }
    };

    const fetchRateSchedules = async () => {
      if (!showAccountType(config)) {
        return;
      }
      try {
        const response = await axios.get('/api/rate-schedules');
        setRateSchedules(response.data);
      } catch (error) {
        toast.error('Failed to fetch rate schedules');
      }
    };

    const fetchData = async () => {
      setIsLoading(true);
      await Promise.all([fetchZones(), fetchRateSchedules()]);
      if (!isNew && dataSource.getOne) {
        const entity = await dataSource.getOne(id);
        setCustomerAccountEntity(entity);
        setSelectedZones(entity.zones ? entity.zones.split(',') : []);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [isNew, id, dataSource]);

  useEffect(() => {
    setNoEndTimeChecked(isNew ? false : customerAccountEntity?.endTime == null);
    setFormValues(defaultValues());
  }, [customerAccountEntity, isNew, rateSchedules]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const handleZoneChange = event => {
    setSelectedZones(event.map(zone => zone.value));
  };

  const handleNoEndTimeChange = event => {
    setNoEndTimeChecked(event.target.checked);
  };

  const handleAccountTypeChange = (selectedRateScheduleId) => {
    const rateScheduleId = parseInt(selectedRateScheduleId, 10);
    const selectedRateSchedule = rateSchedules.find(rateSchedule => rateSchedule.id === rateScheduleId);
    setFormValues({
      ...formValues,
      rateScheduleId: selectedRateSchedule ? selectedRateSchedule.id : rateSchedules[0]?.id,
      rateSchedule: selectedRateSchedule
    });
  };

  const handleClose = () => {
    navigate('/pages/accounts');
    hidePopup();
  };

  const saveEntity = (dataSource: CRUD<ICustomerAccount>) => async (values) => {
    if (values.email.trim() === '') {
      toast.error('Email cannot be empty');
      return;
    }
    if (values.name.trim() === '') {
      toast.error('Name cannot be empty');
      return;
    }
    if (showCompany(config) && values.company.trim() === '') {
      toast.error('Company cannot be empty');
      return;
    }
    if (showLPR(config) && values.lnp?.trim() === '') {
      toast.error('License plate cannot be empty');
      return;
    }
    if (!/^\S+@\S+\.\S+$/.test(values.email.trim())) {
      toast.error('Please enter a valid email address');
      return;
    }

    const currentDateTime = new Date().toISOString();

    if (!noEndTimeChecked) {
      if (values.startTime < currentDateTime || values.endTime < currentDateTime) {
        toast.error('Please enter time greater than current time');
        return;
      }
      if (values.startTime === values.endTime || values.startTime > values.endTime) {
        toast.error('End time should be greater than start time');
        return;
      }
    } else {
      if (values.startTime < currentDateTime) {
        toast.error('Please enter time greater than current time');
        return;
      }
    }
    if (showZones(config) && selectedZones.length === 0) {
      toast.error('Please select at least one zone');
      return;
    }
    values.startTime = convertDateTimeToServer(values.startTime);
    values.endTime = noEndTimeChecked ? '' : convertDateTimeToServer(values.endTime);

    const rateScheduleId = parseInt(values.rateScheduleId, 10);
    const selectedRateSchedule = rateSchedules.find(rateSchedule => rateSchedule.id === rateScheduleId);

    const entity = {
      ...customerAccountEntity,
      ...values,
      isUsed: false,
      amount: 0,
      amountCurrency: 'AUD',
      zones: selectedZones.join(','),
      rateScheduleId,
      rateSchedule: selectedRateSchedule,
    };

    setUpdating(true);
    if (isNew) {
      if (dataSource.create) {
        await dataSource.create(entity);
      }
      toast.success('Created Account: ' + entity.email);
    } else {
      if (dataSource.update) {
        await dataSource.update(entity);
      }
      toast.success('Updated Account: ' + entity.email);
    }
    setUpdating(false);
    setUpdateSuccess(true);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="10">
          <ValidatedForm defaultValues={formValues} onSubmit={saveEntity(dataSource)}>
            <ValidatedField
              label="Email"
              id="customer-account-email"
              name="email"
              data-cy="email"
              type="text"
              className="customer-account-label"
            />
           {showLPR(config) && <ValidatedField
              label="License plate"
              id="customer-account-lnp"
              name="lnp"
              data-cy="lnp"
              type="text"
              className="customer-account-label"
            />}
            <ValidatedField
              label="Name"
              id="customer-account-name"
              name="name"
              data-cy="name"
              type="text"
              className="customer-account-label"
            />
            {showCompany(config) && <ValidatedField
              label="Company"
              id="customer-account-company"
              name="company"
              data-cy="company"
              type="text"
              className="customer-account-label"
            />}
            {showAccountType(config) && <ValidatedField
              label="Account type"
              id="rateScheduleId"
              name="rateScheduleId"
              data-cy="rateScheduleId"
              type="select"
              className="customer-account-label"
              onChange={e => handleAccountTypeChange(e.target.value)}
            >
              {rateSchedules.map(rateSchedule => (
                <option value={rateSchedule.id} key={rateSchedule.id}>
                  {rateSchedule.name}
                </option>
              ))}
            </ValidatedField>
            }

            <ValidatedField
              label="Start Time"
              id="customer-account-startTime"
              name="startTime"
              data-cy="startTime"
              type="datetime-local"
              placeholder="YYYY-MM-DD HH:mm"
              className="customer-account-label"
            />
            <ValidatedField
              label="End Time"
              id="customer-account-endTime"
              name="endTime"
              data-cy="endTime"
              type="datetime-local"
              placeholder="YYYY-MM-DD HH:mm"
              className="customer-account-label"
              disabled={noEndTimeChecked}
            />
            <div style={{ display: 'flex', gap: '10px' }}>
              <label htmlFor="no-end-time">No End Time</label>
              <ValidatedField
                id="no-end-time"
                name="noEndTime"
                data-cy="noEndTime"
                type="checkbox"
                checked={noEndTimeChecked}
                onChange={handleNoEndTimeChange}
              />
            </div>
            {showZones(config) && <><label style={{ display: 'flex', gap: '10px' }} htmlFor="zones">Zones</label>
              <ReactSelect
                options={zones.map(zone => ({ value: zone, label: zone }))}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option }}
                onChange={handleZoneChange}
                value={selectedZones.map(zone => ({ value: zone, label: zone }))}
              />
            </>}
            <div style={{ display: 'flex', padding: '30px', justifyContent: 'center' }}>
              &nbsp;
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                onClick={handleClose}
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </div>
          </ValidatedForm>
        </Col>
      </Row>
    </div>
  );
}

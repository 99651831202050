import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {
  twoDecimalFormatterDevideBy100,
} from 'app/shared/util/numbers';

interface ModifyFeeProps {
  title: string;
  icon?: any;
  onClick: () => void;
}

export default function ModifyFee(props: ModifyFeeProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        justifyItems: 'center',
        flexDirection: 'row',
        height: '100%',
        gap: '5px',
      }}
    >
      <p style={{ margin: '0px' }}>
        {twoDecimalFormatterDevideBy100({ value: props.title })}
      </p>
      <FontAwesomeIcon
        icon={props.icon}
        style={{ cursor: 'pointer' }}
        onClick={props?.onClick}
      />
    </div>
  );
}

import React from 'react';

import SidebarNavSection from './SidebarNavSection';
import { SidebarItemsType, SidebarMenuType } from '../../types/sidebar';

const SidebarNav = ({ items }: { items: SidebarMenuType[] }) => {
  return (
    <ul className="sidebar-nav">
      {items &&
        items.map(item => (
          <SidebarNavSection
            key={item.title}
            pages={item.pages}
            title={item.title}
          />
        ))}
    </ul>
  );
};

export default SidebarNav;

import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CustomerAccount from './customer-account';
import CustomerAccountDetail from './customer-account-detail';
import CustomerAccountUpdate from './customer-account-update';
import CustomerAccountDeleteDialog from './customer-account-delete-dialog';

const CustomerAccountRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CustomerAccount />} />
    <Route path="new" element={<CustomerAccountUpdate />} />
    <Route path=":id">
      <Route index element={<CustomerAccountDetail />} />
      <Route path="edit" element={<CustomerAccountUpdate />} />
      <Route path="delete" element={<CustomerAccountDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CustomerAccountRoutes;

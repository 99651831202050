import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonsContainer from 'app/components/buttonsContainer';
import { Calendar } from 'app/components/calendar/calendar';
import StyledAGGrid from 'app/components/styledaggrid';
import { StyledGridDataSource } from 'app/components/styledaggrid/StyledGridDataSource';
import StyleCard from 'app/components/styledCard';
import TitleContainer from 'app/components/titleContainer';
import { useConfigContext } from 'app/contexts/ConfigContext';
import { createColumnDef, FilterType } from 'app/entities/ColumnDefs';
import { formatDateOnly } from 'app/shared/util/date-utils';
import { calculatePercentageChange } from 'app/shared/util/math';
import axios from 'axios';
import React, { useEffect, useMemo } from 'react';
import { Button } from 'react-bootstrap';

export const TrafficAnalysis = () => {
  const config = useConfigContext();

  const allColumns = {
    'Date': createColumnDef('date', 'Date', FilterType.DATE_EXTERNAL, params => formatDateOnly(params.value)),
    'Casual Entry': createColumnDef('casualEntryCount', 'Casual', FilterType.EQUALS),
    'Account Entry': createColumnDef('accountEntryCount', 'Account', FilterType.EQUALS),
    'Complimentary Entry': createColumnDef('complimentaryEntryCount', 'Complimentary', FilterType.EQUALS),
    'Entry Manual Barrier Raise': createColumnDef('mbrsEntryCount', 'Entry Manual Barrier Raise', FilterType.EQUALS),
    'Casual Exit': createColumnDef('casualExitCount', 'Casual', FilterType.EQUALS),
    'Account Exit': createColumnDef('accountExitCount', 'Account', FilterType.EQUALS),
    'Complimentary Exit': createColumnDef('complimentaryExitCount', 'Complimentary', FilterType.EQUALS),
    'Exit Manual Barrier Raise': createColumnDef('mbrsExitCount', 'Exit Manual Barrier Raise', FilterType.EQUALS)
  };

  const columnGroups = {
    'Total Entry': ['Casual Entry', 'Account Entry', 'Complimentary Entry'],
    'Bypass Override Entry': ['Entry Manual Barrier Raise'],
    'Total Exit': ['Casual Exit', 'Account Exit', 'Complimentary Exit'],
    'Bypass Override Exit': ['Exit Manual Barrier Raise']
  };

  const parseColumnsConfig = (configString) => {
    return configString.split('|').map(group => {
      const [headerName, childrenStr] = group.split(':');
      const children = childrenStr.split(',');
      return { headerName, children };
    });
  };

  const columnsConfig = config.TRAFFIC_ANALYSIS_COLUMNS; // Ensure this is typed as expected.
  console.log('columnsConfig', columnsConfig);

  // Assuming allColumns is defined with a proper index signature, e.g., { [key: string]: ColumnType }
  const buildColumnDefs = (columnsConfig: { [key: string]: string | string[] }) => {
    const columnDefs = [];

    // Iterate over each key in the columnsConfig object
    Object.entries(columnsConfig).forEach(([headerName, columnKeys]) => {
      let children = [];

      // Check if columnKeys is an array and handle accordingly
      if (Array.isArray(columnKeys)) {
        children = columnKeys.map(columnKey => allColumns[columnKey]).filter(Boolean);
      } else if (typeof columnKeys === 'string') { // Confirm that columnKeys is a string before using it as an index
        const column = allColumns[columnKeys];
        if (column) {
          children = [column];
        }
      }

      // Push to columnDefs array if children are found
      if (children.length > 0) {
        columnDefs.push({
          headerName: headerName,
          children: children
        });
      }
    });

    return columnDefs;
  };

  const columnDefs = buildColumnDefs(columnsConfig);
  console.log('columnDefs', columnDefs);


  const dataSource = useMemo(() => {
    return new StyledGridDataSource(
      'date,desc',
      'api/trafficAnalysis',
      true,
      columnDefs
    );
  }, []);
  const handleDateOnlyFilter = range => {
    dataSource.handleDateOnlyFilter('date', range);
  };

  const handleDownload = () => {
    dataSource.downloadAll({
      Date: 'date',
      'Casual Entries': 'casualEntryCount',
      'Account Entries': 'accountEntryCount',
      'Complimentary Entries': 'complimentaryEntryCount',
      'Manual Barrier Raise': 'mbrsEntryCount',
      'Casual Exits': 'casualExitCount',
      'Account Exits': 'accountExitCount',
      'Complimentary Exits': 'complimentaryExitCount',
      'Manual Barrier Raise Exits': 'mbrsExitCount',
    });
  };

  function downloadButton(): React.ReactNode {
    return (
      <Button
        variant="success"
        className="me-1 mb-1 btn btn-success"
        onClick={handleDownload}
        style={{ margin: '0px 0px 2px 0px' }}
      >
        <FontAwesomeIcon
          icon="download"
          style={{ marginRight: '5px', width: '10px', height: '10px' }}
        />
        Download
      </Button>
    );
  }

  interface TrafficStatistics {
    todayCount: number;
    yesterdayCount: number;
    thisWeekCount: number;
    lastWeekCount: number;
    thisMonthCount: number;
    lastMonthCount: number;
    thisWeekDailyAverage: number;
    lastWeekDailyAverage: number;
  }
  const defaultTrafficStatistics: TrafficStatistics = {
    todayCount: 0,
    yesterdayCount: 0,
    thisWeekCount: 0,
    lastWeekCount: 0,
    thisMonthCount: 0,
    lastMonthCount: 0,
    thisWeekDailyAverage: 0,
    lastWeekDailyAverage: 0,
  };
  const [trafficStatistics, setTrafficStatistics] = React.useState(
    defaultTrafficStatistics
  );

  useEffect(() => {
    const requestUrl = `api/traficStatistics`;
    axios.get(requestUrl).then(r => {
      setTrafficStatistics(r.data);
    });
  }, []);
  return (
    <React.Fragment>
      <div>
        <TitleContainer>
          <div>
            <h3>Traffic Analysis</h3>
          </div>

          <ButtonsContainer>
            <Button
              variant="primary"
              onClick={() => dataSource.refresh()}
              className="me-1 mb-1 btn btn-info"
            >
              <FontAwesomeIcon
                icon="sync"
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Refresh
            </Button>
            {downloadButton()}
            <div style={{ marginTop: '0px' }} className="mb-1">
              <Calendar
                applyDateFilter={range => handleDateOnlyFilter(range)}
              />
            </div>
          </ButtonsContainer>
        </TitleContainer>
        <div>
          <StyledAGGrid
            columnDefs={columnDefs}
            fullWidth={true}
            noflex={true}
            paginated={true}
            paginationPageSizeNumber={10}
            dataSource={dataSource}
            rowModelType={'infinite'}
          />

          <div className="row" style={{ marginTop: '30px' }}>
            <StyleCard
              icon="Traffic"
              title={'Week to date'}
              progressTitle={'vs last week'}
              data={trafficStatistics.thisWeekCount}
              progressData={calculatePercentageChange(
                trafficStatistics.thisWeekCount,
                trafficStatistics.lastWeekCount
              )}
            />
            <StyleCard
              icon="Traffic"
              title={'Month to date'}
              progressTitle={'vs last month'}
              data={trafficStatistics.thisMonthCount}
              progressData={calculatePercentageChange(
                trafficStatistics.thisMonthCount,
                trafficStatistics.lastMonthCount
              )}
            />
            <StyleCard
              icon="Traffic"
              title={'Today'}
              progressTitle={'vs yesterday'}
              data={trafficStatistics.todayCount}
              progressData={calculatePercentageChange(
                trafficStatistics.todayCount,
                trafficStatistics.yesterdayCount
              )}
            />
            <StyleCard
              icon="Traffic"
              title={'Average per day'}
              progressTitle={'vs last week'}
              data={trafficStatistics.thisWeekDailyAverage}
              progressData={calculatePercentageChange(
                trafficStatistics.thisWeekDailyAverage,
                trafficStatistics.lastWeekDailyAverage
              )}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import {
  isNumber,
  Translate,
  translate,
  ValidatedField,
  ValidatedForm,
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITransaction } from 'app/shared/model/transaction.model';
import { PaymentType } from 'app/shared/model/enumerations/payment-type.model';
import {
  getEntity,
  updateEntity,
  createEntity,
  reset,
} from './transaction.reducer';

export const TransactionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const transactionEntity = useAppSelector(state => state.transaction.entity);
  const loading = useAppSelector(state => state.transaction.loading);
  const updating = useAppSelector(state => state.transaction.updating);
  const updateSuccess = useAppSelector(
    state => state.transaction.updateSuccess
  );
  const paymentTypeValues = Object.keys(PaymentType);

  const handleClose = () => {
    navigate('/transaction' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.time = convertDateTimeToServer(values.time);

    const entity = {
      ...transactionEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          time: displayDefaultDateTime(),
        }
      : {
          paymentType: 'CARD',
          ...transactionEntity,
          time: convertDateTimeFromServer(transactionEntity.time),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="ticketlessLiteApp.transaction.home.createOrEditLabel"
            data-cy="TransactionCreateUpdateHeading"
          >
            <Translate contentKey="ticketlessLiteApp.transaction.home.createOrEditLabel">
              Create or edit a Transaction
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="transaction-id"
                  label={translate('ticketlessLiteApp.transaction.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label="Session Id"
                id="transaction-sessionId"
                name="sessionId"
                data-cy="sessionId"
                type="text"
              />
              <ValidatedField
                label="Payment Type"
                id="transaction-paymentType"
                name="paymentType"
                data-cy="paymentType"
                type="select"
              >
                {paymentTypeValues.map(paymentType => (
                  <option value={paymentType} key={paymentType}>
                    {paymentType}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label="Payment Method Id"
                id="transaction-paymentMethodId"
                name="paymentMethodId"
                data-cy="paymentMethodId"
                type="text"
              />
              <ValidatedField
                label="Duration In Minutes"
                id="transaction-durationInMinutes"
                name="durationInMinutes"
                data-cy="durationInMinutes"
                type="text"
              />
              <ValidatedField
                label="Gateway Session Id"
                id="transaction-gatewaySessionId"
                name="gatewaySessionId"
                data-cy="gatewaySessionId"
                type="text"
              />
              <ValidatedField
                label="Time"
                id="transaction-time"
                name="time"
                data-cy="time"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label="Amount"
                id="transaction-amount"
                name="amount"
                data-cy="amount"
                type="text"
              />
              <ValidatedField
                label="Amount Currency"
                id="transaction-amountCurrency"
                name="amountCurrency"
                data-cy="amountCurrency"
                type="text"
              />
              <ValidatedField
                label="Card Type"
                id="transaction-cardType"
                name="cardType"
                data-cy="cardType"
                type="text"
              />
              <ValidatedField
                label="Card Token"
                id="transaction-cardToken"
                name="cardToken"
                data-cy="cardToken"
                type="text"
              />
              <ValidatedField
                label="Location"
                id="transaction-location"
                name="location"
                data-cy="location"
                type="text"
              />
              <ValidatedField
                label="Lane Id"
                id="transaction-laneId"
                name="laneId"
                data-cy="laneId"
                type="text"
              />
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/transaction"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TransactionUpdate;

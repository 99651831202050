import React from 'react';
import { Pie } from 'react-chartjs-2';

import { Card, Table } from 'react-bootstrap';

import { faPallet, faSquare, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import usePalette from '../hooks/usePalette';

export interface PieChartData {
  label: string;
  value: number;
  color: string;
  className: string;
}

export interface PieChartProps {
  data: PieChartData[];
  tablesData: PieChartData[];
  title: string;
  editFunction: (arg: boolean) => void;
  showWarning?: boolean;
}
const PieChart = ({ data, tablesData, title, editFunction, showWarning }: PieChartProps) => {
  const options = {
    maintainAspectRatio: false,
    cutout: '70%',
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const palette = usePalette();

  return (
    <Card className="flex-fill" style={{ width: '45%', minWidth: '200px' }}>
      <Card.Header>
        <Card.Title className="mb-0">{title}</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
                <div className="align-self-center w-100">
                  <div className="py-3">
                    <div className="chart chart-xs">
                      <Pie data={pieChartData(data)} options={options} />
                    </div>
                  </div>

                  <Table className="mb-0">
                    <thead>{tableHeader()}</thead>
                    {tableBody(tablesData, editFunction, showWarning)}
                  </Table>
                  <br/>
                  {showWarning ? <div style={{ color: palette.danger }}>WARNING: Occupancy may not be correct.</div> : <></>}
                </div>
      </Card.Body>
    </Card>
  );
};

const pieChartData = input => {
  const palette = usePalette();

  const labels = input.map(item => item.label);
  const data = input.map(item => item.value);
  const backgroundColor = input.map(item => item.color);

  const output = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: backgroundColor,
        borderWidth: 5,
        // assuming that `palette.white` is defined somewhere
        borderColor: palette.white,
      },
    ],
  };

  return output;
};

const tableHeader = () => {
  return (
    <tr>
      <th></th>
      <th className="text-end">Count</th>
      <th className="text-end">%</th>
    </tr>
  );
};

const tableBody = (data, editFunction, showWarning) => {
  const percentages = percentage(data);
  return (
    <tbody>
      {data.map((row, index) => {
        return (
          <tr>
            <td>
              <FontAwesomeIcon icon={faSquare} className={row.className} />{' '}
              {row.label}
            </td>
            <td className="text-end">
              {row.value}{' '}
              {row.label === 'Occupied' && (
                <><FontAwesomeIcon
                  icon="pencil-alt"
                  style={{
                    marginRight: '5px',
                    width: '10px',
                    height: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => editFunction(true)}
                />
                {showWarning && <FontAwesomeIcon icon={faWarning} className={row.className} />}{' '}
                </>
              )}

            </td>
            <td className="text-end text-success">{percentages[index]}%</td>
          </tr>
        );
      })}
    </tbody>
  );
};

const percentage = data => {
  let sum = data.reduce((a, r) => a + r.value, 0);

  let percentages = data.map(r => Math.floor((100 * r.value) / sum));
  let percentageSum = percentages.reduce((a, b) => a + b, 0);

  let roundingError = 100 - percentageSum;

  for (let i = 0; i < percentages.length && roundingError > 0; i++) {
    percentages[i]++;
    roundingError--;
  }
  return percentages;
};

export default PieChart;

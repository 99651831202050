// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
@media only screen and (max-width: 768px) {
  .main-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
}

.hardware-status {
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-container {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .details-container {
    border: none !important;
    align-items: center;
  }
}

.each {
  max-width: 350px !important;
  min-height: 300px !important;
  background-color: #293042 !important;
  padding: 15px !important;
  border-radius: 5px;
}
@media only screen and (max-width: 768px) {
  .each {
    width: 100% !important;
  }
}

@media (min-width: 576px) {
  .validation-style .modal-dialog {
    max-width: 1000px;
  }
}

.popup-container {
  --bs-modal-width: 1160px !important;
}
.popup-container .modal-content {
  background-color: #202634 !important;
}
@media only screen and (max-width: 768px) {
  .popup-container {
    --bs-modal-width: 400px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/StyledModal/style.css"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qCAAA;EACA,SAAA;AACJ;AACI;EALJ;IAMQ,aAAA;IACA,sBAAA;IACA,SAAA;IACA,uBAAA;IACA,mBAAA;EAEN;AACF;;AAGA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,8BAAA;AAAJ;AAEI;EAJJ;IAKQ,uBAAA;IACA,mBAAA;EACN;AACF;;AAEA;EACI,2BAAA;EACA,4BAAA;EACA,oCAAA;EACA,wBAAA;EACA,kBAAA;AACJ;AAEI;EARJ;IASQ,sBAAA;EACN;AACF;;AAGI;EACI;IACI,iBAAA;EAAV;AACF;;AAIA;EACI,mCAAA;AADJ;AAGI;EACI,oCAAA;AADR;AAII;EAPJ;IAQQ,kCAAA;EADN;AACF","sourcesContent":[".main-container {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 20px;\n\n    @media only screen and (max-width: 768px) {\n        display: flex;\n        flex-direction: column;\n        gap: 20px;\n        justify-content: center;\n        align-items: center;\n    }\n}\n\n\n\n.hardware-status {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.details-container {\n    display: flex;\n    justify-content: space-between;\n\n    @media only screen and (max-width: 768px) {\n        border: none !important;\n        align-items: center;\n    }\n}\n\n.each {\n    max-width: 350px !important;\n    min-height: 300px !important;\n    background-color: #293042 !important;\n    padding: 15px !important;\n    border-radius: 5px;\n\n\n    @media only screen and (max-width: 768px) {\n        width: 100% !important;\n    }\n}\n\n.validation-style {\n    @media (min-width: 576px) {\n        .modal-dialog {\n            max-width: 1000px;\n        }\n    }\n}\n\n.popup-container {\n    --bs-modal-width: 1160px !important;\n\n    .modal-content {\n        background-color: #202634 !important;\n    }\n\n    @media only screen and (max-width: 768px) {\n        --bs-modal-width: 400px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import {
  createAsyncThunk,
  isFulfilled,
  isPending
} from '@reduxjs/toolkit';
import { IDailyFinancials, defaultDailyFinancials } from 'app/shared/model/transaction.model';
import {
  EntityState,
  IQueryParams,
  createEntitySlice
} from 'app/shared/reducers/reducer.utils';
import axios from 'axios';

const initialState: EntityState<IDailyFinancials> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultDailyFinancials,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/dailyFinancials';
export const getAll = ({ query, page, size, sort }: IQueryParams) => {
  let queryString = `page=${page}&size=${size}`;

  if (sort) {
    queryString += `&sort=${sort}`;
  }

  if (query) {
    queryString += `&${query}`;
  }

  const requestUrl = `${apiUrl}?${queryString}&cacheBuster=${new Date().getTime()}`;

  return axios.get<IDailyFinancials[]>(requestUrl);
};

export const getEntities = createAsyncThunk(
  'daily-financials/fetch_entity_list',
  async ({ page, size, sort }: IQueryParams) => {
    return getDailyFinacials(page, size, sort);
  }
);

// slice

export const DailyFinancialsSlice = createEntitySlice({
  name: 'daily-financials',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      
      .addMatcher(isPending(getEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      
  },
});

export const { reset } = DailyFinancialsSlice.actions;

// Reducer
export default DailyFinancialsSlice.reducer;
export function getDailyFinacials(  page, size, sort) {
  const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IDailyFinancials[]>(requestUrl);
}


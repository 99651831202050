import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './gate.reducer';

export const GateDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const gateEntity = useAppSelector(state => state.gate.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="gateDetailsHeading">
          <Translate contentKey="ticketlessLiteApp.gate.detail.title">
            Gate
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="ticketlessLiteApp.gate.id">Id</Translate>
            </span>
          </dt>
          <dd>{gateEntity.id}</dd>
          <dt>
            <span id="laneId">
              <Translate contentKey="ticketlessLiteApp.gate.laneId">
                Lane Id
              </Translate>
            </span>
          </dt>
          <dd>{gateEntity.laneId}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="ticketlessLiteApp.gate.status">
                Status
              </Translate>
            </span>
          </dt>
          <dd>{gateEntity.status}</dd>
        </dl>
        <Button
          tag={Link}
          to="/gate"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/gate/${gateEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default GateDetail;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import {
  isNumber,
  translate,
  ValidatedField,
  ValidatedForm,
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IBusinessDetails } from 'app/shared/model/business-details.model';
import {
  getEntity,
  updateEntity,
  createEntity,
} from '../../entities/business-details/business-details.reducer';

interface IBusinessDetailsUpdateProps {
  id?: any;
  hidePopup?: () => void;
}

export const BusinessDetailsUpdate = (props: IBusinessDetailsUpdateProps) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  let id;
  if (props && props.id !== undefined) {
    id = props.id;
  }

  const isNew = props.id === undefined ? true : false;

  const businessDetailsEntity = useAppSelector(
    state => state.businessDetails.entity
  );
  const loading = useAppSelector(state => state.businessDetails.loading);
  const updating = useAppSelector(state => state.businessDetails.updating);
  const updateSuccess = useAppSelector(
    state => state.businessDetails.updateSuccess
  );
  let businessLogoBase64;

  const handleClose = () => {
    navigate('/pages/businessDetails');
    props.hidePopup();
  };

  useEffect(() => {
    if (isNew) {
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      props.hidePopup();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...businessDetailsEntity,
      ...values,
      businessLogo: isNew
        ? businessLogoBase64
        : businessLogoBase64 != undefined
        ? businessLogoBase64
        : businessDetailsEntity.businessLogo,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={businessDetailsEntity}
              onSubmit={saveEntity}
            >
              <ValidatedField
                label="Name"
                id="business-details-name"
                name="name"
                data-cy="name"
                type="text"
                className="customer-account-label"
              />
              <ValidatedField
                label="Business Name"
                id="business-details-abnBusinessName"
                name="abnBusinessName"
                data-cy="abnBusinessName"
                type="text"
                className="customer-account-label"
              />
              <ValidatedField
                label="Site Name"
                id="business-details-siteName"
                name="siteName"
                data-cy="siteName"
                type="text"
                className="customer-account-label"
              />
              <ValidatedField
                label="Address Line 1"
                id="business-details-addressLine1"
                name="addressLine1"
                data-cy="addressLine1"
                type="text"
                className="customer-account-label"
              />
              <ValidatedField
                label="Address Line 2"
                id="business-details-addressLine2"
                name="addressLine2"
                data-cy="addressLine2"
                type="text"
                className="customer-account-label"
              />
              <ValidatedField
                label="Address Lines 3"
                id="business-details-addressLines3"
                name="addressLines3"
                data-cy="addressLines3"
                type="text"
                className="customer-account-label"
              />
              <ValidatedField
                label="Business Logo"
                id="business-details-businessLogo"
                name="businessLogo"
                data-cy="businessLogo"
                type="file"
                accept="image/*"
                className="customer-account-label"
                onChange={e => {
                  const reader = new FileReader();
                  reader.onload = function (e) {
                    const base64String = e.target.result;

                    businessLogoBase64 = base64String;
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }}
              />
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                onClick={handleClose}
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BusinessDetailsUpdate;

import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './rate-card.reducer';

export const RateCard = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(
    overrideSortStateWithQueryParams(
      getSortState(location, 'id'),
      location.search
    )
  );

  const rateCardList = useAppSelector(state => state.rateCard.entities);
  const loading = useAppSelector(state => state.rateCard.loading);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        sort: `${sortState.sort},${sortState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="rate-card-heading" data-cy="RateCardHeading">
        <Translate contentKey="ticketlessLiteApp.rateCard.home.title">
          Rate Cards
        </Translate>
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            color="info"
            onClick={handleSyncList}
            disabled={loading}
          >
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="ticketlessLiteApp.rateCard.home.refreshListLabel">
              Refresh List
            </Translate>
          </Button>
          <Link
            to="/rate-card/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="ticketlessLiteApp.rateCard.home.createLabel">
              Create new Rate Card
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {rateCardList && rateCardList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="ticketlessLiteApp.rateCard.id">
                    Id
                  </Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('entryFrom')}>
                  <Translate contentKey="ticketlessLiteApp.rateCard.entryFrom">
                    Entry From
                  </Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('entryFrom')} />
                </th>
                <th className="hand" onClick={sort('entryTo')}>
                  <Translate contentKey="ticketlessLiteApp.rateCard.entryTo">
                    Entry To
                  </Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('entryTo')} />
                </th>
                <th className="hand" onClick={sort('dayFrom')}>
                  <Translate contentKey="ticketlessLiteApp.rateCard.dayFrom">
                    Day From
                  </Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('dayFrom')} />
                </th>
                <th className="hand" onClick={sort('dayTo')}>
                  <Translate contentKey="ticketlessLiteApp.rateCard.dayTo">
                    Day To
                  </Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('dayTo')} />
                </th>
                <th>
                  <Translate contentKey="ticketlessLiteApp.rateCard.rateSchedule">
                    Rate Schedule
                  </Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {rateCardList.map((rateCard, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button
                      tag={Link}
                      to={`/rate-card/${rateCard.id}`}
                      color="link"
                      size="sm"
                    >
                      {rateCard.id}
                    </Button>
                  </td>
                  <td>{rateCard.entryFrom}</td>
                  <td>{rateCard.entryTo}</td>
                  <td>{rateCard.dayFrom}</td>
                  <td>{rateCard.dayTo}</td>
                  <td>
                    {rateCard.rateSchedule ? (
                      <Link to={`/rate-schedule/${rateCard.rateSchedule.id}`}>
                        {rateCard.rateSchedule.id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`/rate-card/${rateCard.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">
                            View
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/rate-card/${rateCard.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">
                            Edit
                          </Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/rate-card/${rateCard.id}/delete`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">
                            Delete
                          </Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="ticketlessLiteApp.rateCard.home.notFound">
                No Rate Cards found
              </Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default RateCard;

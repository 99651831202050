import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import useSidebar from '../../hooks/useSidebar';
import SidebarFooter from './SidebarFooter';
import SidebarNav from './SidebarNav';

import { SidebarItemsType, SidebarMenuType } from '../../types/sidebar';
import { useConfigContext } from 'app/contexts/ConfigContext';
import dashboardItems from './dashboardItems';

interface SidebarProps {
  authorities: string[];
  // items: SidebarMenuType[];
  open?: boolean;
  showFooter?: boolean;
}

const Sidebar = ({ authorities, showFooter = true }: SidebarProps) => {
  const { isOpen } = useSidebar();
  const config = useConfigContext();
  const items = dashboardItems(authorities, config);
  return (
    <nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <img
              src="content/assets/img/Ticketless+Parking_Logo-1.svg"
              alt="Logo"
              className="sidebar-brand-svg"
              style={{
                width: '180px',
                height: '100px',
                marginLeft: '-50px',
                marginBottom: '-30px',
              }}
            />
          </a>

          <SidebarNav items={items} />
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;

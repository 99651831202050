import { twoDecimalFormatter, twoDecimalFormatterV } from 'app/shared/util/numbers';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AddDataModal = ({ show, onClose, onSubmit, currentValue , surchargePct}) => {
  const [feeChanged, setFeeChanged] = useState(currentValue / 100);
  const [reason, setReason] = useState('');
  const [feeError, setFeeError] = useState('');
  const [error, setError] = useState('');

  const handleChange = event => {
    let newValue = event.target.value;
  
    // Enforcing the two decimal places limit
    if (newValue.includes('.')) {
      const [whole, decimal] = newValue.split('.');
      newValue = decimal.length > 2 ? `${whole}.${decimal.substring(0, 2)}` : newValue;
    }
  
    // Ensure the value is within the range [0, 2000]
    const newFeeChanged = (newValue > 0 && newValue <= 2000) ? newValue : (newValue >= 2000 ? 2000 : 0);
  
    setFeeChanged(newFeeChanged);
    setFeeError('');
  };
  

  const handleReasonFieldChange = event => {
    const newValue = event.target.value;
    setReason(newValue);
    setError('');
  };

  function areDoublesEqual(a, b) {
    return Math.round(a * 100) === Math.round(b * 100);
  }
  const handleSubmit = () => {
    if (!reason) {
      setError('Reason is required');
      return;
    }
    if (areDoublesEqual(feeChanged, currentValue / 100)) {
      setFeeError('New fee amount must be different from the current fee amount.');
      return;
    }
    onSubmit(feeChanged, reason);
    setFeeChanged(null);
    setReason('');
    onClose();
  };

  const handleClose = () => {
    setFeeChanged(null);
    onClose();
  };
  
  const withSurchargeLabel = () => {
    const withSurcharge = parseFloat(''+feeChanged) + ((parseFloat(''+feeChanged) * parseFloat(''+surchargePct))/100);

    if (Number.isNaN(withSurcharge)) {
      return ''
    }
    const withSurchargeLabel = twoDecimalFormatterV(withSurcharge);

    console.log("feeChanged", feeChanged, surchargePct, withSurcharge)
    return `The motorist will pay $${withSurchargeLabel} (includes ${twoDecimalFormatterV(surchargePct)}% surcharge).`;
  }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <Form.Group controlId="formFeeChanged">
          <Form.Label>Enter new fee amount</Form.Label>
          <Form.Control
            type="number"
            value={feeChanged}
            onChange={handleChange}
            max={2000}
            min={0}
            isInvalid={!!feeError}
          />
          <Form.Control.Feedback type="invalid">{feeError}</Form.Control.Feedback>
          <br></br>
          <Form.Label>Reason</Form.Label>
          <Form.Control
            type="text"
            value={reason}
            onChange={handleReasonFieldChange}
            isInvalid={!!error}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          <br></br>
          <>{"Please Note: "}</>
          <br></br>
          <>{withSurchargeLabel()}</>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};



export default AddDataModal;

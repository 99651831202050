export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  DASHBOARD: 'DASHBOARD',
  ACCOUNT: 'ACCOUNT_MANAGEMENT',
  PAYMENT: 'PAYMENT_HISTORY',
  PARKING: 'PARKING_HISTORY',
  TRAFFIC: 'TRAFFIC_ANALYSIS',
  FINANCIALS: 'FINANCIALS',
  VALIDATION: 'VALIDATION',
  RATES: 'RATES',
  BUSINESS_DETAILS: 'BUSINESS_DETAILS',
  OCCUPANCY_REPORT: 'OCCUPANCY_REPORT',
  LENGTH_OF_STAY: 'LENGTH_OF_STAY',
  EXCEPTIONS: 'EXCEPTIONS',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

const parseColumns = (columns) => {
  return columns.map(column => {
    const [alias, original] = column.includes(':') ? column.split(':') : [column, column];
    return { alias: alias.trim(), original: original.trim() };
  });
};

export const getColumnDefsForEnvironment = (columnDefs, envColumns) => {
  console.log('columnDefs:', columnDefs);
  console.log('envColumns:', envColumns);
  const parsedColumns = parseColumns(envColumns);

  // Filter and map the columnDefs based on parsedColumns
  const updatedColumns = columnDefs
    .filter(columnDef => parsedColumns.some(col => col.original === columnDef.headerName))
    .map(columnDef => {
      const columnConfig = parsedColumns.find(col => col.original === columnDef.headerName);
      return {
        ...columnDef,
        headerName: columnConfig.alias
      };
    });

  // Sort the updated columns based on the order of envColumns
  return updatedColumns.sort((a, b) => {
    const aIndex = parsedColumns.findIndex(col => col.original === a.headerName || col.alias === a.headerName);
    const bIndex = parsedColumns.findIndex(col => col.original === b.headerName || col.alias === b.headerName);
    return aIndex - bIndex;
  });
};

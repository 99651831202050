import dayjs from 'dayjs';
import { PaymentType } from 'app/shared/model/enumerations/payment-type.model';

export interface IParkingSession {
  id?: number;
  sessionId?: string | null;
  entryTime?: string | null;
  exitTime?: string | null;
  paymentType?: keyof typeof PaymentType | null;
  paymentMethodId?: string | null;
  location?: string | null;
  durationInMinutes?: number | null;
  entryLaneId?: string | null;
  exitLaneId?: string | null;
  fee?: number | null;
  feeCurrency?: string | null;
  paid?: number | null;
  paidCurrency?: string | null;
  accountId?: string | null;
  receiptId?: string | null;
  surcharge?: number | null;
  plate?: number | null;
}

export const defaultValue: Readonly<IParkingSession> = {};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

const DownloadCSV = ({ filedata, disabled = false }) => {
  const downloadCSV = () => {
    console.log("filedata: ", filedata);
    if (filedata.length === 0) {
      return;
    }
    const data = filedata;
    const csvContent = convertArrayToCSV(fillMissingDataPoints(data));
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertArrayToCSV = data => {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map(obj => Object.values(obj).join(','));
    return `${header}\n${rows.join('\n')}`;
  };
  function parseTime(timeStr) {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  }

  function fillMissingDataPoints(data) {
    // Helper function to convert time to minutes for easy comparison
    function timeToMinutes(time) {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
    }

    // Helper function to convert minutes back to time format
    function minutesToTime(minutes) {
        const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
        const mins = (minutes % 60).toString().padStart(2, '0');
        return `${hours}:${mins}`;
    }

    // Assuming the data is sorted by time, the first payment type is taken from the first entry
    const firstPaymentType = data[0].paymentType;
    let lastEntry = { 
      "time": "04:30",
      "paymentType": firstPaymentType,
      "minValue": 0,
      "actualValue": 0,
      "maxValue": 0 
    };
    const filledData = [];
    let currentTime = 0; // start at 00:00 in minutes

    while (currentTime < 24 * 60) { // go up to 23:30
        const currentTimeStr = minutesToTime(currentTime);
        const currentEntry = data.find(entry => entry.time === currentTimeStr);

        if (currentEntry) {
            filledData.push(currentEntry);
            lastEntry = currentEntry;
        } else {
            filledData.push({
                time: currentTimeStr,
                paymentType: firstPaymentType,
                minValue: lastEntry.minValue,
                actualValue: lastEntry.actualValue,
                maxValue: lastEntry.maxValue
            });
        }
        
        currentTime += 30; // increment current time by 30 minutes
    }

    return filledData;
  }
  return (
    <Button
      variant="success"
      className="me-1 mb-1 btn btn-success"
      onClick={() => downloadCSV()}
      style={{ margin: '0px 0px 2px 0px' }}
      disabled={disabled}
    >
      <FontAwesomeIcon
        icon="download"
        style={{ marginRight: '5px', width: '10px', height: '10px' }}
      />
      Download
    </Button>
  );
};
export default DownloadCSV;

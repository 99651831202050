import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import './style.css';

interface StyledModelProps {
  showModal?: boolean;
  confirmMethod?: any;
  cancelMethod?: any;
  titleText?: string;
  detailedText?: string | React.ReactNode;
  confirmButtonText?: string;
  confirmButtonStyle?: string;
  noButtons?: boolean;
  additionalClass?: string;
}

export default function StyledModal(props: StyledModelProps) {
  return (
    <Modal
      show={props.showModal}
      onHide={() => props.cancelMethod()}
      centered
      className={`${props.additionalClass}`}
    >
      <Modal.Header closeButton>{props.titleText}</Modal.Header>
      <Modal.Body className="text-center m-3">
        <p className="mb-0">{props.detailedText}</p>
      </Modal.Body>
      <Modal.Footer>
        {props.noButtons ? (
          <></>
        ) : (
          <>
            <Button variant="secondary" onClick={() => props.cancelMethod()}>
              Close
            </Button>
            <Button
              className={`me-1 btn  ${props.confirmButtonStyle}`}
              onClick={() => props.confirmMethod()}
            >
              {props.confirmButtonText}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

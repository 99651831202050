import dayjs from 'dayjs';
import { IRateCard } from 'app/shared/model/rate-card.model';
import { RateType } from 'app/shared/model/enumerations/rate-type.model';

export interface IRateSchedule {
  id?: number;
  name?: string;
  rateType?: keyof typeof RateType;
  locationId?: string;
  startInstant?: string;
  endInstant?: string;
  created?: string;
  rateCards?: IRateCard[] | null;
}

export const defaultValue: Readonly<IRateSchedule> = {};

import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Calendar } from 'app/components/calendar/calendar';
import StyledAGGrid from 'app/components/styledaggrid';
import { StyledGridDataSource } from 'app/components/styledaggrid/StyledGridDataSource';
import {
  FilterType,
  createColumnDef,
  createDownloadColumnMapping,
} from 'app/entities/ColumnDefs';
import { formatDate } from 'app/shared/util/date-utils';
import { Button, Card } from 'react-bootstrap';
import ButtonsContainer from 'app/components/buttonsContainer';
import TitleContainer from 'app/components/titleContainer';
import {
  twoDecimalFormatter,
  twoDecimalFormatterDevideBy100,
} from 'app/shared/util/numbers';
import { getColumnDefsForEnvironment } from 'app/config/constants';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios'; // Import axios for making HTTP requests
import { IValidation } from 'app/shared/model/validation.model';
import StyleCard from 'app/components/styledCard';
import StatusBadge from 'app/components/statusBadge';
import { getCurrentStatus } from '../validation/validation-status';
import { useConfigContext } from 'app/contexts/ConfigContext';

export const Parking = () => {
  const config = useConfigContext();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const [validation, setValidation] = useState<IValidation | null>(null);

  // API call to fetch validation data
  useEffect(() => {
    if (queryParams['validationId.equals']) {
      const fetchValidation = async () => {
        try {
          const response = await axios.get(`/api/validations/${queryParams['validationId.equals']}`);
          setValidation(response.data); // Assuming the response has a name field
        } catch (error) {
          console.error('Failed to fetch validation', error);
          setValidation(null);
        }
      };

      fetchValidation();
    }
  }, []);

  const allColumnDefs = [
    createColumnDef(
      'entryTime',
      'Entry Time',
      FilterType.DATE_EXTERNAL,
      params => formatDate(params.value)
    ),
    createColumnDef('exitTime', 'Exit Time', FilterType.NONE, params =>
      formatDate(params.value)
    ),
    createColumnDef('paymentType', 'Payment Type', FilterType.NONE),
    createColumnDef('accountId', 'Account Id', FilterType.EQUALS),
    createColumnDef('validationId', 'Validation Id', FilterType.EQUALS),
    {
      ...createColumnDef('batchId', 'Batch Id', FilterType.EQUALS),
      cellRenderer: (params) => {
        console.log('params', params);
        const url = `/pages/parking?validationId.equals=${params?.data?.validationId}`;
        return <a href={url} target="_blank">{params.value}</a>;
      }
    },
    createColumnDef('validationCounter', 'Validation Counter', FilterType.NONE),
    createColumnDef('paymentAccountId', 'Payment Account Id', FilterType.NONE),
    createColumnDef('entryLaneId', 'Zone', FilterType.EQUALS),
    createColumnDef('entryLaneId', 'Lane Id', FilterType.EQUALS),
    createColumnDef('bayId', 'Bay ID', FilterType.EQUALS),
    createColumnDef('plate', 'LPR', FilterType.EQUALS),
    createColumnDef(
      'durationInMinutes',
      'Duration (Minutes)',
      FilterType.EQUALS
    ),
    createColumnDef(
      'fee',
      'Fee ($)',
      FilterType.NONE,
      twoDecimalFormatterDevideBy100
    ),
    createColumnDef('paid', 'Paid ($)', FilterType.NONE, twoDecimalFormatter),
  ];
  const columnDefs = getColumnDefsForEnvironment(allColumnDefs, config.PARKING_EVENTS_COLUMNS)
  .filter(colDef => {
    if (colDef.field === 'batchId') {
      return !validation;
    }
    return true;
  });
  const apiUrlWithParams = useMemo(() => {
    const baseUrl = 'api/parking-sessions';
    const queryStringified = queryString.stringify(queryParams);
    return queryStringified ? `${baseUrl}?${queryStringified}` : baseUrl;
  }, [queryParams]);

  const dataSource = useMemo(() => {
    return new StyledGridDataSource(
      'entryTime,desc',
      apiUrlWithParams,
      false,
      columnDefs
    );
  }, []);
  const handleDownload = () => {
    dataSource.downloadAll(createDownloadColumnMapping(columnDefs));
  };
  const handleDateFilter = range => {
    dataSource.handleDateFilter('entryTime', range);
  };
  function downloadButton(): React.ReactNode {
    return (
      <Button
        variant="success"
        className="me-1 mb-1 btn btn-success"
        onClick={handleDownload}
        style={{ margin: '0px 0px 2px 0px' }}
      >
        <FontAwesomeIcon
          icon="download"
          style={{ marginRight: '5px', width: '10px', height: '10px' }}
        />
        Download
      </Button>
    );
  }
  return (
    <React.Fragment>
      <div>
        <TitleContainer>
          <div style={{ minWidth: '200px' }}>
            <h3>{`Parking Events`}</h3>
          </div>

          <ButtonsContainer>
            <Button
              variant="primary"
              onClick={() => dataSource.refresh()}
              className="me-1 mb-1 btn btn-info"
            >
              <FontAwesomeIcon
                icon="sync"
                style={{ marginRight: '5px', width: '12px', height: '12px' }}
              />
              Refresh
            </Button>
            {downloadButton()}
            <div style={{ marginTop: '0px' }} className="mb-1">
              <Calendar applyDateFilter={range => handleDateFilter(range)} />
            </div>
          </ButtonsContainer>
        </TitleContainer>
        {validation &&

          <Card className="flex-fill w-100">
            <Card.Header style={{ marginBottom: '-40px' }}>
              <Card.Title> Validation Details</Card.Title>
            </Card.Header>
            <Card.Body>
              <StyledAGGrid
                rowData={[validation]}
                columnDefs={[
                  {
                    field: 'Status',
                    headerName: 'Status',
                    cellRenderer: StatusBadge,
                    sortable: false,
                    filter: false,
                    cellRendererParams: params => {
                      const startTime = params?.data?.startTime;
                      
                      const endTime = params?.data?.endTime;
                      console.log('startTime', startTime);
              
                      if (!startTime || !endTime) {
                        return { type: 'default', text: 'Invalid Time' };
                      }
              
                      return getCurrentStatus(startTime, endTime);
                    },
                  },
                  createColumnDef('email', 'Email', FilterType.NONE),
                  createColumnDef('description', 'Description', FilterType.NONE),
                  createColumnDef('startTime', 'Start Time', FilterType.NONE, params => formatDate(params.value)),
                  createColumnDef('endTime', 'End Time', FilterType.NONE, params => formatDate(params.value)),
                  createColumnDef('batchId', 'Batch ID', FilterType.NONE),
                  createColumnDef('count', 'Issued',  FilterType.NONE),
                  createColumnDef('multipleEntries', 'Usage Type', FilterType.NONE, p => p.value? 'M' : 'S'),
                  createColumnDef('rateSchedule.name', 'Type', FilterType.NONE),
                ]}
                sortable={false}
                filter={false}
                resizable={false}
                showRefreshButton={false}
              />
            </Card.Body>
          </Card>
        }
        <StyledAGGrid
          columnDefs={columnDefs}
          fullWidth={true}
          noflex={true}
          paginated={true}
          paginationPageSizeNumber={10}
          dataSource={dataSource}
          rowModelType={'infinite'}
        />
      </div>
    </React.Fragment>
  );
};

export default Parking;

export function twoDecimalFormatter(params) {
    if (params.value !== null && params.value !== undefined) {
        return params.value.toFixed(2);
    } else {
        return '';
    }
}

export function twoDecimalFormatterV(params) {
    if (params !== null && params !== undefined) {
        console.log('params', params);
        return (Math.ceil(params * 100) / 100).toFixed(2);
    } else {
        return '';
    }
}

export function twoDecimalFormatterDevideBy100(params) {
    if (params.value !== null && params.value !== undefined) {
        return (params.value / 100).toFixed(2);
    } else {
        return '';
    }
}
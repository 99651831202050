import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Users, Settings, User } from 'react-feather';

interface INavbarUserProps {
  accountDetails?: any;
}

const NavbarUser = (props: INavbarUserProps) => {
  const dispatch = useAppDispatch();

  const logOut = () => {
    dispatch(logout());
    window.location.href = '/login';
  };

  const authorities = props.accountDetails.authorities
    ? props.accountDetails.authorities
    : [];
  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src="../../../content/assets/img/avatars/admin-logo.jpeg"
            className="avatar img-fluid rounded-circle me-1"
            alt="Chris Wood"
          />
          <span className="text-dark">{props.accountDetails.login}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        {authorities.includes(AUTHORITIES.ADMIN) && (
          <Dropdown.Item
            onClick={() => (window.location.href = '/admin/user-management')}
          >
            <Users size={18} className="align-middle me-2" />
            User management
          </Dropdown.Item>
        )}
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() => (window.location.href = '/account/password')}
        >
          Update password
        </Dropdown.Item>
        <Dropdown.Item onClick={() => logOut()}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DownloadPaymentReceiptProps {
  url?: string;
}

export default function DownloadPaymentReceipt(
  props: DownloadPaymentReceiptProps
) {
  if (!props.url) {
    return null;
  }

  return (
    <a
      href={props.url}
      target="_blank"
      style={{
        marginRight: '5px',
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <FontAwesomeIcon icon="download" />
    </a>
  );
}

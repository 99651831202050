import React from 'react';
import './CardValidation.css';
import DropdownSelector from 'app/components/DropDownSelector';
import { IRateSchedule } from 'app/shared/model/rate-schedule.model';
import { IParkingSession } from 'app/shared/model/parking-session.model';
interface ColumnDefinition {
  header: string;
  accessor: string;
  isDropdown?: boolean;
  dropdownOptions?: IRateSchedule[];
  dropdownLabel?: string;
  onDropdownClick?: (sessionId:string)=>(selectedOption: IRateSchedule) => void;
}

interface ValidationTableProps {
  tableData: IParkingSession[];
  columns: ColumnDefinition[];
  rowKeyAccessor: string;
}

function ValidationTable({
  tableData,
  columns,
  rowKeyAccessor,
}: ValidationTableProps) {
  return (
    <div className="validation-table ag-theme-alpine-dark">
      <table>
        <thead>
          <tr>
            {columns.map(column => (
              <th key={column.header}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map(row => (
            <tr key={row[rowKeyAccessor]}>
              {columns.map(column => (
                <td key={column.accessor}>
                  {column.isDropdown ? (
                    <DropdownSelector
                      options={column.dropdownOptions}
                      applySelection={column.onDropdownClick(row.sessionId)}
                      getOptionLabel={(rate: IRateSchedule) => rate.name}
                      buttonText="Apply Validation"
                      selectFirstOptionByDefault={true}
                    />
                  ) : (
                    row[column.accessor]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ValidationTable;

import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './customer-account.reducer';

export const CustomerAccountDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const customerAccountEntity = useAppSelector(
    state => state.customerAccount.entity
  );
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="customerAccountDetailsHeading">
          <Translate contentKey="ticketlessLiteApp.customerAccount.detail.title">
            CustomerAccount
          </Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="ticketlessLiteApp.customerAccount.id">
                Id
              </Translate>
            </span>
          </dt>
          <dd>{customerAccountEntity.id}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="ticketlessLiteApp.customerAccount.email">
                Email
              </Translate>
            </span>
          </dt>
          <dd>{customerAccountEntity.email}</dd>
          <dt>
            <span id="startTime">
              <Translate contentKey="ticketlessLiteApp.customerAccount.startTime">
                Start Time
              </Translate>
            </span>
          </dt>
          <dd>
            {customerAccountEntity.startTime ? (
              <TextFormat
                value={customerAccountEntity.startTime}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="endTime">
              <Translate contentKey="ticketlessLiteApp.customerAccount.endTime">
                End Time
              </Translate>
            </span>
          </dt>
          <dd>
            {customerAccountEntity.endTime ? (
              <TextFormat
                value={customerAccountEntity.endTime}
                type="date"
                format={APP_DATE_FORMAT}
              />
            ) : null}
          </dd>
          <dt>
            <span id="amount">
              <Translate contentKey="ticketlessLiteApp.customerAccount.amount">
                Amount
              </Translate>
            </span>
          </dt>
          <dd>{customerAccountEntity.amount}</dd>
          <dt>
            <span id="amountCurrency">
              <Translate contentKey="ticketlessLiteApp.customerAccount.amountCurrency">
                Amount Currency
              </Translate>
            </span>
          </dt>
          <dd>{customerAccountEntity.amountCurrency}</dd>
          <dt>
            <span id="isUsed">
              <Translate contentKey="ticketlessLiteApp.customerAccount.isUsed">
                Is Used
              </Translate>
            </span>
          </dt>
          <dd>{customerAccountEntity.isUsed ? 'true' : 'false'}</dd>
        </dl>
        <Button
          tag={Link}
          to="/customer-account"
          replace
          color="info"
          data-cy="entityDetailsBackButton"
        >
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button
          tag={Link}
          to={`/customer-account/${customerAccountEntity.id}/edit`}
          replace
          color="primary"
        >
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CustomerAccountDetail;

import axios from 'axios';
import {
  createAsyncThunk,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';
import { ASC } from 'app/shared/util/pagination.constants';
import { cleanEntity } from 'app/shared/util/entity-utils';
import {
  IQueryParams,
  createEntitySlice,
  EntityState,
  serializeAxiosError,
} from 'app/shared/reducers/reducer.utils';
import {
  IRateSchedule,
  defaultValue,
} from 'app/shared/model/rate-schedule.model';
import qs from 'qs';

const initialState: EntityState<IRateSchedule> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/rate-schedules';

// Actions
export const getEntities = createAsyncThunk(
  'rateSchedule/fetch_entity_list',
  async ({ query, page, size, sort }: IQueryParams) => {
    const params: { [key: string]: any } = {};
    if (query) {
      const queryObj = qs.parse(query);
      Object.assign(params, queryObj);
    }
    if (page !== undefined) params.page = page;
    if (size !== undefined) params.size = size;
    if (sort) params.sort = sort;

    const queryString = qs.stringify(params, { arrayFormat: 'repeat' });
    const requestUrl = `${apiUrl}?${queryString}&cacheBuster=${new Date().getTime()}`;
    return axios.get<IRateSchedule[]>(requestUrl);
  }
);

export const getEntity = createAsyncThunk(
  'rateSchedule/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IRateSchedule>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'rateSchedule/create_entity',
  async (entity: IRateSchedule, thunkAPI) => {
    const result = await axios.post<IRateSchedule>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'rateSchedule/update_entity',
  async (entity: IRateSchedule, thunkAPI) => {
    const result = await axios.put<IRateSchedule>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'rateSchedule/partial_update_entity',
  async (entity: IRateSchedule, thunkAPI) => {
    const result = await axios.patch<IRateSchedule>(
      `${apiUrl}/${entity.id}`,
      cleanEntity(entity)
    );
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'rateSchedule/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IRateSchedule>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const RateScheduleSlice = createEntitySlice({
  name: 'rateSchedule',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data.sort((a, b) => {
            if (!action.meta?.arg?.sort) {
              return 1;
            }
            const order = action.meta.arg.sort.split(',')[1];
            const predicate = action.meta.arg.sort.split(',')[0];
            return order === ASC
              ? a[predicate] < b[predicate]
                ? -1
                : 1
              : b[predicate] < a[predicate]
              ? -1
              : 1;
          }),
        };
      })
      .addMatcher(
        isFulfilled(createEntity, updateEntity, partialUpdateEntity),
        (state, action) => {
          state.updating = false;
          state.loading = false;
          state.updateSuccess = true;
          state.entity = action.payload.data;
        }
      )
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createEntity,
          updateEntity,
          partialUpdateEntity,
          deleteEntity
        ),
        state => {
          state.errorMessage = null;
          state.updateSuccess = false;
          state.updating = true;
        }
      );
  },
});

export const { reset } = RateScheduleSlice.actions;

// Reducer
export default RateScheduleSlice.reducer;

import React, { ReactNode } from 'react';

interface TitleContainerProps {
  children: ReactNode;
}

export default function TitleContainer({ children }: TitleContainerProps) {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        paddingBottom: '20px',
        alignItems: 'flex-start',
        whiteSpace: 'nowrap',
        flexWrap: 'wrap',
      }}
    >
      {children}
    </div>
  );
}

import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Transaction from './transaction';
import Gate from './gate';
import Lane from './lane';
import Event from './event';
import Rate from './rate';
import RateCard from './rate-card';
import RateSchedule from './rate-schedule';
import CustomerAccount from './customer-account';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="transaction/*" element={<Transaction />} />
        <Route path="gate/*" element={<Gate />} />
        <Route path="lane/*" element={<Lane />} />
        <Route path="event/*" element={<Event />} />
        <Route path="rate/*" element={<Rate />} />
        <Route path="rate-card/*" element={<RateCard />} />
        <Route path="rate-schedule/*" element={<RateSchedule />} />
        <Route path="customer-account/*" element={<CustomerAccount />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};

import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditOccupanyModel = ({ show, onClose, onSubmit }) => {
  const [occupancy, setOccupancy] = useState('');
  const [error, setError] = useState('');

  const handleoccupanyFieldChange = event => {
    const newValue = event.target.value;
    setOccupancy(newValue);
    setError('');
  };

  const handleSubmit = () => {
    if (!occupancy) {
      setError('Occupancy required');
      return;
    }
    onSubmit(occupancy);
    setOccupancy('');
    onClose();
  };

  const handleClose = () => {
    setOccupancy('');
    onClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Body>
        <Form.Group controlId="formFeeChanged">
          <Form.Label>Enter new occupancy</Form.Label>
          <Form.Control
            type="number"
            value={occupancy}
            onChange={handleoccupanyFieldChange}
            isInvalid={!!error}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditOccupanyModel;

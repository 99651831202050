import dayjs from 'dayjs';
import { PaymentType } from 'app/shared/model/enumerations/payment-type.model';

export interface IDailyFinancials {
  date?: string;
  day?: string;
  totalPaid?: number;
}
export interface ITransaction {
  id?: number;
  sessionId?: string | null;
  paymentType?: keyof typeof PaymentType | null;
  paymentMethodId?: string | null;
  durationInMinutes?: number | null;
  gatewaySessionId?: string | null;
  time?: string | null;
  amount?: number | null;
  amountCurrency?: string | null;
  cardType?: string | null;
  cardToken?: string | null;
  location?: string | null;
  laneId?: string | null;
}

export const defaultDailyFinancials: Readonly<IDailyFinancials> = {};
export const defaultValue: Readonly<ITransaction> = {};

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import {
  isNumber,
  Translate,
  translate,
  ValidatedField,
  ValidatedForm,
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
} from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IGate } from 'app/shared/model/gate.model';
import { GateStatus } from 'app/shared/model/enumerations/gate-status.model';
import { getEntity, updateEntity, createEntity, reset } from './gate.reducer';

export const GateUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const gateEntity = useAppSelector(state => state.gate.entity);
  const loading = useAppSelector(state => state.gate.loading);
  const updating = useAppSelector(state => state.gate.updating);
  const updateSuccess = useAppSelector(state => state.gate.updateSuccess);
  const gateStatusValues = Object.keys(GateStatus);

  const handleClose = () => {
    navigate('/gate' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...gateEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          status: 'OPEN',
          ...gateEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="ticketlessLiteApp.gate.home.createOrEditLabel"
            data-cy="GateCreateUpdateHeading"
          >
            <Translate contentKey="ticketlessLiteApp.gate.home.createOrEditLabel">
              Create or edit a Gate
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm
              defaultValues={defaultValues()}
              onSubmit={saveEntity}
            >
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="gate-id"
                  label={translate('ticketlessLiteApp.gate.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label="Lane Id"
                id="gate-laneId"
                name="laneId"
                data-cy="laneId"
                type="text"
              />
              <ValidatedField
                label="Status"
                id="gate-status"
                name="status"
                data-cy="status"
                type="select"
              >
                {gateStatusValues.map(gateStatus => (
                  <option value={gateStatus} key={gateStatus}>
                    {gateStatus}
                  </option>
                ))}
              </ValidatedField>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/gate"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default GateUpdate;

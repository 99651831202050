import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import {
  getPaginationState,
  JhiPagination,
  JhiItemCount,
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import {
  ASC,
  DESC,
  ITEMS_PER_PAGE,
  SORT,
} from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Card } from 'react-bootstrap';

import { getEntities } from '../../entities/business-details/business-details.reducer';
import BusinessDetails from 'app/entities/business-details/business-details';
import BusinessDetailsUpdate from './business-details-update';
import StyledModal from 'app/components/StyledModal';
import { set } from 'lodash';

export const BuisnessDetailsPage = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      getPaginationState(location, ITEMS_PER_PAGE, 'id'),
      location.search
    )
  );

  const businessDetailsList = useAppSelector(
    state => state.businessDetails.entities
  );
  const loading = useAppSelector(state => state.businessDetails.loading);
  const totalItems = useAppSelector(state => state.businessDetails.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const handleClose = () => {
    setShowUpdateModal(false);
  };

  return (
    <div>
      <div style={{ minWidth: '200px' }}>
        <h3>Business Details</h3>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {businessDetailsList && businessDetailsList.length > 0 ? (
          <>
            {businessDetailsList.map((businessDetails, i) => (
              <>
                <Card style={{ width: '400px', padding: '20px' }}>
                  <Card.Body className="d-flex">
                    <dl>
                      <div style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        {' '}
                        Trading Name
                      </div>
                      <dd>{businessDetails.name}</dd>
                      <div style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        <span id="abnBusinessName">ABN</span>
                      </div>
                      <dd>{businessDetails.abnBusinessName}</dd>
                      <div style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        <span id="siteName">Site Name</span>
                      </div>
                      <dd>{businessDetails.siteName}</dd>
                      <div style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        <span id="addressLine1">Address Line 1</span>
                      </div>
                      <dd>{businessDetails.addressLine1}</dd>
                      <div style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        <span id="addressLine2">Address Line 2</span>
                      </div>
                      <dd>{businessDetails.addressLine2}</dd>
                      <div style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        <span id="addressLines3">Address Lines 3</span>
                      </div>
                      <dd>{businessDetails.addressLines3}</dd>
                      <div style={{ fontWeight: 'bold', fontSize: '15px' }}>
                        <span id="businessLogo">Business Logo</span>
                      </div>
                      <div style={{ maxWidth: '100px', maxHeight: '100px' }}>
                        <img
                          src={businessDetails.businessLogo}
                          alt="Business Logo"
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            width: 'auto',
                            height: 'auto',
                          }}
                        />
                      </div>
                    </dl>
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      tag={Link}
                      onClick={() => setShowUpdateModal(true)}
                      color="primary"
                    >
                      Update business details
                    </Button>
                  </Card.Footer>
                </Card>
              </>
            ))}
          </>
        ) : (
          !loading && (
            <Button
              tag={Link}
              onClick={() => setShowUpdateModal(true)}
              color="primary"
            >
              Add business details
            </Button>
          )
        )}
      </div>
      <StyledModal
        showModal={showUpdateModal}
        cancelMethod={handleClose}
        titleText="Update business details"
        detailedText={
          <BusinessDetailsUpdate
            id={businessDetailsList[0]?.id}
            hidePopup={() => setShowUpdateModal(false)}
          />
        }
        confirmMethod={handleClose}
        noButtons={true}
      />
    </div>
  );
};

export default BuisnessDetailsPage;

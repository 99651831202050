import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  displayDefaultDateTime,
  displayDefaultDateTimePlusOneMonth
} from 'app/shared/util/date-utils';
import { getEntities as getRateSchedules } from 'app/entities/rate-schedule/rate-schedule.reducer';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import {
  getEntity,
  updateEntity,
  createEntity,
  reset,
} from '../../entities/validation/validation.reducer';
import { toast } from 'react-toastify';
import { RateType } from 'app/shared/model/enumerations/rate-type.model';

export interface IValidationProps {
  id?: any;
  hidePopup: () => void;
}

export default function ValidationCreateUpdate(props: IValidationProps) {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = props;
  const isNew = id === undefined;

  const validationEntity = useAppSelector(state => state.validation.entity);
  const loading = useAppSelector(state => state.validation.loading);
  const updating = useAppSelector(state => state.validation.updating);
  const updateSuccess = useAppSelector(state => state.validation.updateSuccess);
  const rateSchedules = useAppSelector(state => state.rateSchedule.entities);
  const defaultValues = () =>
    isNew
      ? {
        startTime: displayDefaultDateTime(),
        endTime: displayDefaultDateTimePlusOneMonth(),
        multipleEntries: false, // Explicitly set default value to false
      }
      : {
        ...validationEntity,
        startTime: convertDateTimeFromServer(validationEntity.startTime),
        endTime: convertDateTimeFromServer(validationEntity.endTime),
        rateScheduleId: validationEntity.rateSchedule ? validationEntity.rateSchedule.id : '',
        multipleEntries: validationEntity.multipleEntries || false,
      };
  const [multipleEntries, setMultipleEntries] = useState(defaultValues().multipleEntries);


  const handleClose = () => {
    navigate('/pages/validation');
    props.hidePopup();
  };

  useEffect(() => {
    dispatch(getRateSchedules({ query: `rateType.equals=${RateType.VALIDATION}` }));
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    // if (values.email.trim() === '') {
    //   toast.error('Email cannot be empty');
    //   return;
    // }
    // if (!/^\S+@\S+\.\S+$/.test(values.email.trim())) {
    //   toast.error('Please enter a valid email address');
    //   return;
    // }

    const currentDateTime = new Date().toISOString();
    values.multipleEntries = multipleEntries;
    if (
      values.startTime < currentDateTime ||
      values.endTime < currentDateTime
    ) {
      toast.error('Please enter time greater than current time');
      return;
    }

    if (
      values.startTime === values.endTime ||
      values.startTime > values.endTime
    ) {
      toast.error('End time should be greater than start time');
      return;
    }

    values.startTime = convertDateTimeToServer(values.startTime);
    values.endTime = convertDateTimeToServer(values.endTime);
    const entity = {
      ...validationEntity,
      ...values,
      isUsed: false,
      // amountCurrency: 'AUD',
      rateScheduleId: Number(values.rateScheduleId),
      rateSchedule: rateSchedules.find(
        rateSchedule => rateSchedule.id === Number(values.rateScheduleId)
      ),
    };
    console.log("Entity", entity);
    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };


  console.log("Rate schedules", rateSchedules);
  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
            <ValidatedField
              label="Email"
              id="validation-email"
              name="email"
              data-cy="email"
              type="text"
              className="customer-account-label"
              required={true}
            />
            <ValidatedField
              label="Description"
              id="description"
              name="description"
              data-cy="description"
              type="text"
              className="customer-account-label"
              maxLength={25}
              required={true}
            />
            {/* <ValidatedField
              label="Amount"
              id="validation-amount"
              name="amount"
              data-cy="amount"
              type="number"
              min="0"
              className="customer-account-label"
            /> */}
            <ValidatedField
              label="Start Time"
              id="validation-startTime"
              name="startTime"
              data-cy="startTime"
              type="datetime-local"
              placeholder="YYYY-MM-DD HH:mm"
              className="customer-account-label"
            />
            <ValidatedField
              label="End Time"
              id="validation-endTime"
              name="endTime"
              data-cy="endTime"
              type="datetime-local"
              placeholder="YYYY-MM-DD HH:mm"
              className="customer-account-label"
            />
            <ValidatedField
              label="Count"
              id="validation-count"
              name="count"
              data-cy="count"
              type="number"
              min="0"
              max="1000"
              className="customer-account-label"
            />
            <div style={{ display: 'flex', gap: '10px' }}>
              <label htmlFor="multipleEntries">Allow multiple entries</label>
              <ValidatedField
                id="multipleEntries"
                name="multipleEntries"
                data-cy="multipleEntries"
                type="checkbox"
                checked={multipleEntries}
                onChange={e => setMultipleEntries(e.target.checked)}
              />
            </div>

            <ValidatedField
              label="Rate Schedule"
              id="rateScheduleId"
              name="rateScheduleId"
              data-cy="rateScheduleId"
              type="select"
              className="customer-account-label"
              required
            >
              <option value="" key="0" />
              {rateSchedules.map(rateSchedule => (
                <option value={rateSchedule.id} key={rateSchedule.id}>
                  {rateSchedule.name}
                </option>
              ))}
            </ValidatedField>

            <div
              style={{
                display: 'flex',
                padding: '30px',
                justifyContent: 'center',
              }}
            >
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                onClick={handleClose}
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button
                color="primary"
                id="save-entity"
                data-cy="entityCreateSaveButton"
                type="submit"
                disabled={updating}
              >
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
            </div>
          </ValidatedForm>
        </Col>
      </Row>
    </div>
  );
}
